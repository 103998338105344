/* .card{
  box-shadow: none !important;
}
.card-body .text-muted{
  color: #BCBCBC !important;
  font-size: 0.8rem;
  font-weight: 300;
}

.card-body span.h2{
  margin-right: 10px;
}


.nav.nav-tabs .nav-item{
  font-weight: 300 !important;
}
.relative{
  position: relative;
}
.rifaly-check{
  padding:0.60em;
  background-color: white;
  margin:0.5em;
  border-radius: 0;
} */

.bars {
  display: block;
  flex-direction: column;
  align-items: flex-start;
}

.bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bar {
  display: block;
  height: 8px;
  border-radius: 2px;
  background-color: #007bff; /* Default background color */
}

.percentage-label {
  margin-left: 10px;
  color: #6C757D;
}

