:root {
  --toastify-color-info: #2bc38c;
}

@import "../node_modules/rsuite/dist/rsuite.min.css";
@import 'react-toastify/dist/ReactToastify.css';


.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0;
  position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}
.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}
.ql-clipboard {
  height: 1px;
  left: -100000px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}
.ql-clipboard p {
  margin: 0;
  padding: 0;
}
.ql-editor {
  word-wrap: break-word;
  box-sizing: border-box;
  height: 100%;
  line-height: 1.42;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
}
.ql-editor > * {
  cursor: text;
}
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor ol,
.ql-editor p,
.ql-editor pre,
.ql-editor ul {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
}
.ql-editor ol > li,
.ql-editor ul > li {
  list-style-type: none;
}
.ql-editor ul > li:before {
  content: "\2022";
}
.ql-editor ul[data-checked="false"],
.ql-editor ul[data-checked="true"] {
  pointer-events: none;
}
.ql-editor ul[data-checked="false"] > li *,
.ql-editor ul[data-checked="true"] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked="false"] > li:before,
.ql-editor ul[data-checked="true"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.ql-editor ul[data-checked="true"] > li:before {
  content: "\2611";
}
.ql-editor ul[data-checked="false"] > li:before {
  content: "\2610";
}
.ql-editor li:before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.ql-editor li:not(.ql-direction-rtl):before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor li.ql-direction-rtl:before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.ql-editor ol li {
  counter-increment: list-0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}
.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}
.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}
.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}
.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}
.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}
.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}
.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}
.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}
.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}
.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}
.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}
.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
  background-color: #000;
}
.ql-editor .ql-bg-red {
  background-color: #e60000;
}
.ql-editor .ql-bg-orange {
  background-color: #f90;
}
.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}
.ql-editor .ql-bg-green {
  background-color: #008a00;
}
.ql-editor .ql-bg-blue {
  background-color: #06c;
}
.ql-editor .ql-bg-purple {
  background-color: #93f;
}
.ql-editor .ql-color-white {
  color: #fff;
}
.ql-editor .ql-color-red {
  color: #e60000;
}
.ql-editor .ql-color-orange {
  color: #f90;
}
.ql-editor .ql-color-yellow {
  color: #ff0;
}
.ql-editor .ql-color-green {
  color: #008a00;
}
.ql-editor .ql-color-blue {
  color: #06c;
}
.ql-editor .ql-color-purple {
  color: #93f;
}
.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-editor .ql-size-small {
  font-size: 0.75em;
}
.ql-editor .ql-size-large {
  font-size: 1.5em;
}
.ql-editor .ql-size-huge {
  font-size: 2.5em;
}
.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-editor .ql-align-center {
  text-align: center;
}
.ql-editor .ql-align-justify {
  text-align: justify;
}
.ql-editor .ql-align-right {
  text-align: right;
}
.ql-editor.ql-blank:before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.flatpickr-calendar {
  -webkit-animation: none;
  animation: none;
  background: transparent;
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  direction: ltr;
  display: none;
  font-size: 14px;
  line-height: 24px;
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  touch-action: manipulation;
  visibility: hidden;
  width: 307.875px;
}
.flatpickr-calendar.inline,
.flatpickr-calendar.open {
  max-height: 640px;
  opacity: 1;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  display: block;
  z-index: 999;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasTime .dayContainer,
.flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #e6e6e6;
  height: 40px;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:after,
.flatpickr-calendar:before {
  border: solid transparent;
  content: "";
  display: block;
  height: 0;
  left: 22px;
  pointer-events: none;
  position: absolute;
  width: 0;
}
.flatpickr-calendar.arrowRight:after,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:after,
.flatpickr-calendar.arrowCenter:before {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:after,
.flatpickr-calendar.arrowBottom:before {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}
.flatpickr-months {
  display: flex;
}
.flatpickr-months .flatpickr-month {
  fill: rgba(0, 0, 0, 0.9);
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  flex: 1;
  height: 34px;
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  fill: rgba(0, 0, 0, 0.9);
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  height: 34px;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  top: 0;
  z-index: 3;
}
.flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-next-month i,
.flatpickr-months .flatpickr-prev-month i {
  position: relative;
}
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}
.flatpickr-months .flatpickr-next-month.flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 0;
}
.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
  color: #959ea9;
}
.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
  height: 14px;
  width: 14px;
}
.flatpickr-months .flatpickr-next-month svg path,
.flatpickr-months .flatpickr-prev-month svg path {
  fill: inherit;
  transition: fill 0.1s;
}
.numInputWrapper {
  height: auto;
  position: relative;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-inner-spin-button,
.numInputWrapper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numInputWrapper span {
  border: 1px solid rgba(57, 57, 57, 0.15);
  box-sizing: border-box;
  cursor: pointer;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  padding: 0 4px 0 2px;
  position: absolute;
  right: 0;
  width: 14px;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}
.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}
.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  height: auto;
  width: inherit;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  color: inherit;
  display: inline-block;
  font-size: 135%;
  font-weight: 300;
  height: 34px;
  left: 12.5%;
  line-height: inherit;
  line-height: 1;
  padding: 7.48px 0 0;
  position: absolute;
  text-align: center;
  transform: translateZ(0);
  width: 75%;
}
.flatpickr-current-month span.cur-month {
  color: inherit;
  display: inline-block;
  font-family: inherit;
  font-weight: 700;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  display: inline-block;
  width: 6ch;
  width: 7ch\0;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month input.cur-year {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0 0 0 0.5ch;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  font-size: 100%;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:active,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  align-items: center;
  background: transparent;
  display: flex;
  height: 28px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}
span.flatpickr-weekday {
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  cursor: default;
  display: block;
  flex: 1;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  margin: 0;
  text-align: center;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0;
}
.flatpickr-days {
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  max-width: 307.875px;
  min-width: 307.875px;
  opacity: 1;
  outline: 0;
  padding: 0;
  text-align: left;
  transform: translateZ(0);
  width: 307.875px;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  flex-basis: 14.2857143%;
  font-weight: 400;
  height: 39px;
  justify-content: center;
  line-height: 39px;
  margin: 0;
  max-width: 39px;
  position: relative;
  text-align: center;
  width: 14.2857143%;
}
.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
  background: #e6e6e6;
  border-color: #e6e6e6;
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.today {
  border-color: #959ea9;
}
.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
  background: #959ea9;
  border-color: #959ea9;
  color: #fff;
}
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: #569ff7;
  border-color: #569ff7;
  box-shadow: none;
  color: #fff;
}
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #569ff7;
}
.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.prevMonthDay {
  background: transparent;
  border-color: transparent;
  color: rgba(57, 57, 57, 0.3);
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: rgba(57, 57, 57, 0.1);
  cursor: not-allowed;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 #e6e6e6;
  padding: 0 12px;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  line-height: 28px;
  width: 100%;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  background: transparent;
  border: none;
  color: rgba(57, 57, 57, 0.3);
  cursor: default;
  display: block;
  max-width: none;
  width: 100%;
}
.flatpickr-innerContainer {
  box-sizing: border-box;
  display: block;
  display: flex;
  overflow: hidden;
}
.flatpickr-rContainer {
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
}
.flatpickr-time {
  box-sizing: border-box;
  display: block;
  display: flex;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  outline: 0;
  overflow: hidden;
  text-align: center;
}
.flatpickr-time:after {
  clear: both;
  content: "";
  display: table;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  float: left;
  height: 40px;
  width: 40%;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #393939;
  font-size: 14px;
  height: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}
.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
  align-self: center;
  color: #393939;
  float: left;
  font-weight: 700;
  height: inherit;
  line-height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 2%;
}
.flatpickr-time .flatpickr-am-pm {
  cursor: pointer;
  font-weight: 400;
  outline: 0;
  text-align: center;
  width: 18%;
}
.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time input:hover {
  background: #eee;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fpFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.hljs {
  background: #1e1e1e;
  color: #dcdcdc;
  display: block;
  overflow-x: auto;
  padding: 0.5em;
}
.hljs-keyword,
.hljs-link,
.hljs-literal,
.hljs-name,
.hljs-symbol {
  color: #569cd6;
}
.hljs-link {
  text-decoration: underline;
}
.hljs-built_in,
.hljs-type {
  color: #4ec9b0;
}
.hljs-class,
.hljs-number {
  color: #b8d7a3;
}
.hljs-meta-string,
.hljs-string {
  color: #d69d85;
}
.hljs-regexp,
.hljs-template-tag {
  color: #9a5334;
}
.hljs-formula,
.hljs-function,
.hljs-params,
.hljs-subst,
.hljs-title {
  color: #dcdcdc;
}
.hljs-comment,
.hljs-quote {
  color: #57a64a;
  font-style: italic;
}
.hljs-doctag {
  color: #608b4e;
}
.hljs-meta,
.hljs-meta-keyword,
.hljs-tag {
  color: #9b9b9b;
}
.hljs-template-variable,
.hljs-variable {
  color: #bd63c5;
}
.hljs-attr,
.hljs-attribute,
.hljs-builtin-name {
  color: #9cdcfe;
}
.hljs-section {
  color: gold;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: 700;
}
.hljs-bullet,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-id,
.hljs-selector-pseudo,
.hljs-selector-tag {
  color: #d7ba7d;
}
.hljs-addition {
  background-color: #144212;
}
.hljs-addition,
.hljs-deletion {
  display: inline-block;
  width: 100%;
}
.hljs-deletion {
  background-color: #600;
}
/*# sourceMappingURL=libs.bundle.css.map*/

/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #2c7be5;
  --bs-indigo: #727cf5;
  --bs-purple: #6b5eae;
  --bs-pink: #ff679b;
  --bs-red: #e63757;
  --bs-orange: #fd7e14;
  --bs-yellow: #f6c343;
  --bs-green: #00d97e;
  --bs-teal: #02a8b5;
  --bs-cyan: #39afd1;
  --bs-gray: #95aac9;
  --bs-gray-dark: #3b506c;
  --bs-primary: #00b17a;
  --bs-secondary: #6e84a3;
  --bs-success: #00d97e;
  --bs-info: #39afd1;
  --bs-warning: #f6c343;
  --bs-danger: #e63757;
  --bs-light: #edf2f9;
  --bs-dark: #0c2b3b;
  --bs-white: #fff;
  --bs-font-sans-serif: "Inter", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
}
*,
:after,
:before {
  box-sizing: border-box;
}
body {
  -webkit-tap-highlight-color: rgba(18, 38, 63, 0);
  background-color: #f9fbfd;
  color: #0c2b3b;
  font-family: var(--bs-font-sans-serif);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  -webkit-text-size-adjust: 100%;
}
hr {
  background-color: currentColor;
  border: 0;
  color: #e3ebf6;
  margin: 1rem 0;
  opacity: 1;
}
hr:not([size]) {
  height: 1px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.125rem;
  margin-top: 0;
}
.h1,
h1 {
  font-size: 1.625rem;
}
.h2,
h2 {
  font-size: 1.25rem;
}
.h3,
h3 {
  font-size: 1.0625rem;
}
.h4,
h4 {
  font-size: 0.9375rem;
}
.h5,
h5 {
  font-size: 0.8125rem;
}
.h6,
h6 {
  font-size: 0.625rem;
}
p {
  margin-bottom: 1rem;
  margin-top: 0;
}
abbr[data-bs-original-title],
abbr[title] {
  cursor: help;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 600;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
.small,
small {
  font-size: 0.8125rem;
}
.mark,
mark {
  background-color: #fcf8e3;
  padding: 0.2em;
}
sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #2bc38c;
}
a,
a:hover {
  text-decoration: none;
}
a:hover {
  color: #2bc38c;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  direction: ltr;
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  unicode-bidi: bidi-override;
}
pre {
  display: block;
  font-size: 87.5%;
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}
pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}
code {
  word-wrap: break-word;
  color: #2c7be5;
  font-size: 87.5%;
}
a > code {
  color: inherit;
}
kbd {
  background-color: #283e59;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
}
kbd kbd {
  font-size: 1em;
  font-weight: 600;
  padding: 0;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  border-collapse: collapse;
  caption-side: bottom;
}
caption {
  color: #95aac9;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: transparent !important;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
textarea {
  resize: vertical;
}
fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}
legend {
  float: left;
  font-size: 1.5rem;
  line-height: inherit;
  margin-bottom: 0.5rem;
  padding: 0;
  width: 100%;
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  font: inherit;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  cursor: pointer;
  display: list-item;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.17188rem;
  font-weight: 300;
}
.display-1 {
  font-size: 4rem;
}
.display-1,
.display-2 {
  font-weight: 600;
  line-height: 1.1;
}
.display-2 {
  font-size: 3.25rem;
}
.display-3 {
  font-size: 2.625rem;
}
.display-3,
.display-4 {
  font-weight: 600;
  line-height: 1.1;
}
.display-4 {
  font-size: 2rem;
}
.list-inline,
.list-unstyled {
  list-style: none;
  padding-left: 0;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 6px;
}
.initialism {
  font-size: 0.8125rem;
  text-transform: uppercase;
}
.blockquote {
  font-size: 1.17188rem;
  margin-bottom: 1.5rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  color: #95aac9;
  font-size: 0.8125rem;
  margin-bottom: 1.5rem;
  margin-top: -1.5rem;
}
.blockquote-footer:before {
  content: "\2014\00A0";
}
.img-fluid,
.img-thumbnail {
  height: auto;
  max-width: 100%;
}
.img-thumbnail {
  background-color: #f9fbfd;
  border: 1px solid #e3ebf6;
  border-radius: 0.375rem;
  padding: 0.25rem;
}
.figure {
  display: inline-block;
}
.figure-img {
  line-height: 1;
  margin-bottom: 0.75rem;
}
.figure-caption {
  color: #95aac9;
  font-size: 0.8125rem;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: var(--bs-gutter-x, 0.75rem);
  width: 100%;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) / -2);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-top: calc(var(--bs-gutter-y) * -1);
}
.row > * {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) / 2);
  padding-right: calc(var(--bs-gutter-x) / 2);
  width: 100%;
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.1875rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.1875rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.375rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.375rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 2.25rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 2.25rem;
}
.g-6,
.gx-6 {
  --bs-gutter-x: 4.5rem;
}
.g-6,
.gy-6 {
  --bs-gutter-y: 4.5rem;
}
.g-7,
.gx-7 {
  --bs-gutter-x: 6.75rem;
}
.g-7,
.gy-7 {
  --bs-gutter-y: 6.75rem;
}
.g-8,
.gx-8 {
  --bs-gutter-x: 13.5rem;
}
.g-8,
.gy-8 {
  --bs-gutter-y: 13.5rem;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.1875rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.1875rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.375rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.375rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2.25rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2.25rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 6.75rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 6.75rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 13.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 13.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.1875rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.1875rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.375rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.375rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2.25rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2.25rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 6.75rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 6.75rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 13.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 13.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.1875rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.1875rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.375rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.375rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2.25rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2.25rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 6.75rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 6.75rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 13.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 13.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.1875rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.1875rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2.25rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2.25rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 6.75rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 6.75rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 13.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 13.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.1875rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.1875rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2.25rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2.25rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 6.75rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 6.75rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 13.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 13.5rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #0c2b3b;
  --bs-table-striped-bg: #f9fbfd;
  --bs-table-active-color: #0c2b3b;
  --bs-table-active-bg: #f9fbfd;
  --bs-table-hover-color: #0c2b3b;
  --bs-table-hover-bg: #f9fbfd;
  border-color: #edf2f9;
  color: #0c2b3b;
  margin-bottom: 1.5rem;
  vertical-align: top;
  width: 100%;
}
.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  padding: 1rem;
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 1rem;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-striped > tbody > tr:nth-of-type(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}
.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.table-primary {
  --bs-table-bg: #d5e5fa;
  --bs-table-striped-bg: #ccddf2;
  --bs-table-striped-color: #283e59;
  --bs-table-active-bg: #c4d4ea;
  --bs-table-active-color: #283e59;
  --bs-table-hover-bg: #c8d8ee;
  --bs-table-hover-color: #283e59;
  border-color: #c4d4ea;
  color: #283e59;
}
.table-secondary {
  --bs-table-bg: #e2e6ed;
  --bs-table-striped-bg: #d9dee6;
  --bs-table-striped-color: #283e59;
  --bs-table-active-bg: #cfd5de;
  --bs-table-active-color: #283e59;
  --bs-table-hover-bg: #d4d9e2;
  --bs-table-hover-color: #283e59;
  border-color: #cfd5de;
  color: #283e59;
}
.table-success {
  --bs-table-bg: #ccf7e5;
  --bs-table-striped-bg: #c4eede;
  --bs-table-striped-color: #283e59;
  --bs-table-active-bg: #bce5d7;
  --bs-table-active-color: #283e59;
  --bs-table-hover-bg: #c0e9db;
  --bs-table-hover-color: #283e59;
  border-color: #bce5d7;
  color: #283e59;
}
.table-info {
  --bs-table-bg: #d7eff6;
  --bs-table-striped-bg: #cee6ee;
  --bs-table-striped-color: #283e59;
  --bs-table-active-bg: #c6dde6;
  --bs-table-active-color: #283e59;
  --bs-table-hover-bg: #cae2ea;
  --bs-table-hover-color: #283e59;
  border-color: #c6dde6;
  color: #283e59;
}
.table-warning {
  --bs-table-bg: #fdf3d9;
  --bs-table-striped-bg: #f2ead3;
  --bs-table-striped-color: #283e59;
  --bs-table-active-bg: #e8e1cc;
  --bs-table-active-color: #283e59;
  --bs-table-hover-bg: #ede5cf;
  --bs-table-hover-color: #283e59;
  border-color: #e8e1cc;
  color: #283e59;
}
.table-danger {
  --bs-table-bg: #fad7dd;
  --bs-table-striped-bg: #f0cfd6;
  --bs-table-striped-color: #283e59;
  --bs-table-active-bg: #e5c8d0;
  --bs-table-active-color: #283e59;
  --bs-table-hover-bg: #eaccd3;
  --bs-table-hover-color: #283e59;
  border-color: #e5c8d0;
  color: #283e59;
}
.table-light {
  --bs-table-bg: #edf2f9;
  --bs-table-striped-bg: #e3e9f1;
  --bs-table-striped-color: #283e59;
  --bs-table-active-bg: #d9e0e9;
  --bs-table-active-color: #283e59;
  --bs-table-hover-bg: #dee5ed;
  --bs-table-hover-color: #283e59;
  border-color: #d9e0e9;
  color: #283e59;
}
.table-dark {
  --bs-table-bg: #0c2b3b;
  --bs-table-striped-bg: #1e3149;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #2a3c52;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #24364d;
  --bs-table-hover-color: #fff;
  border-color: #2a3c52;
  color: #fff;
}
.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-title {
  margin-top: 0.5rem;
  margin-bottom: 1.1rem;
  color: #0f1f26;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-label {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: #0f1f26;
  font-weight: 300;
}
.col-form-label {
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: calc(0.5rem + 1px);
  padding-top: calc(0.5rem + 1px);
}
.col-form-label-lg {
  font-size: 0.9375rem;
  padding-bottom: calc(0.75rem + 1px);
  padding-top: calc(0.75rem + 1px);
}
.col-form-label-sm {
  font-size: 0.8125rem;
  padding-bottom: calc(0.125rem + 1px);
  padding-top: calc(0.125rem + 1px);
}
.form-text {
  color: #95aac9;
  font-size: 0.8125rem;
  margin-top: 0;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 10px;
}
.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled,
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #00b17a;
  color: #fff;
}
.rs-calendar-table-cell-in-range:before {
  background-color: #e6fff4;
}
.rs-btn-link {
  color: #bcbcbc;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #bcbcbc;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #00d97e;
}
.rs-btn-primary {
  background-color: #00b17a;
  color: #fff;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm,
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: transparent !important;
  border: 1px solid #e7e7e7;
  border-radius: 0.375rem;
  color: #bcbcbc;
  display: block;
  font-size: 0.8375rem;
  font-weight: 300;
  line-height: 1.5;
  padding: 0.65rem 0.95rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .form-control,
  .rs-picker-default .rs-picker-toggle.rs-btn-sm {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  background-color: transparent;
  border-color: #2bc38c;
  box-shadow: none;
  color: #0c2b3b;
  outline: 0;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #bcbcbc;
  opacity: 1;
}
.form-control::placeholder {
  color: #bcbcbc;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
.form-control::file-selector-button {
  -webkit-margin-end: 0.75rem;
  background-color: #fff;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #0c2b3b;
  margin: -0.5rem -0.75rem;
  margin-inline-end: 0.75rem;
  padding: 0.5rem 0.75rem;
  pointer-events: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}
.form-control::-webkit-file-upload-button {
  -webkit-margin-end: 0.75rem;
  background-color: #fff;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #bcbcbc;
  margin: -0.5rem -0.75rem;
  margin-inline-end: 0.75rem;
  padding: 0.5rem 0.75rem;
  pointer-events: none;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
}
.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #0c2b3b;
  display: block;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.5rem 0;
  width: 100%;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}
.form-control-sm {
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  min-height: calc(1.5em + 0.25rem + 2px);
  padding: 0.125rem 0.5rem;
}
.form-control-sm::file-selector-button {
  -webkit-margin-end: 0.5rem;
  margin: -0.125rem -0.5rem;
  margin-inline-end: 0.5rem;
  padding: 0.125rem 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: 0.5rem;
  margin: -0.125rem -0.5rem;
  margin-inline-end: 0.5rem;
  padding: 0.125rem 0.5rem;
}
.form-control-lg {
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  min-height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1.25rem;
}
.form-control-lg::file-selector-button {
  -webkit-margin-end: 1.25rem;
  margin: -0.75rem -1.25rem;
  margin-inline-end: 1.25rem;
  padding: 0.75rem 1.25rem;
}
.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1.25rem;
  margin: -0.75rem -1.25rem;
  margin-inline-end: 1.25rem;
  padding: 0.75rem 1.25rem;
}
textarea.form-control {
  min-height: calc(1.5em + 1rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.25rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.5rem + 2px);
}
.form-control-color {
  height: auto;
  max-width: 3rem;
  padding: 0.5rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border-radius: 0.375rem;
  height: 1.5em;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
  height: 1.5em;
}
.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 1 3.25 3.25L7.5 1' stroke='%2395AAC9' stroke-width='1.083' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.75em 0.75em;
  border: 1px solid #e7e7e7;
  border-radius: 0.375rem;
  color: #bcbcbc;
  display: block;
  font-size: 0.8375rem;
  font-weight: 300;
  line-height: 1.5;
  padding: 0.65rem 0.95rem;
  display: block;
  padding: 0.65rem 0.95rem;
  width: 100%;
}
.form-select:focus {
  border-color: #2bc38c;
  box-shadow: none;
  outline: 0;
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 0.75rem;
}
.form-select:disabled {
  background-color: #edf2f9;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #0c2b3b;
}
.form-select-sm {
  font-size: 0.8125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.5rem;
  padding-top: 0.125rem;
}
.form-select-lg {
  font-size: 0.9375rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-top: 0.75rem;
}
.form-check {
  display: block;
  margin-bottom: 0.125rem;
  min-height: 1.40625rem;
  padding-left: 1.5rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5rem;
}
.form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-color: #e3ebf6;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: transparent;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  width: 1em;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.375rem;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: none;
}
.form-check-input:focus {
  border-color: transparent;
  box-shadow: none;
  outline: 0;
}
.form-check-input:checked {
  background-color: #2bc38c;
  border-color: #2bc38c;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #2c7be5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  border-color: #2c7be5;
}
.form-check-input:disabled {
  filter: none;
  opacity: 0.5;
  pointer-events: none;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}
.form-check-label {
  cursor: pointer;
}
.form-switch {
  padding-left: 3.5rem;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 3rem;
  margin-left: -3.5rem;
  transition: background-position 0.15s ease-in-out;
  width: 3rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:checked,
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.form-switch .form-check-input:checked {
  background-position: 100%;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  filter: none;
  opacity: 0.65;
  pointer-events: none;
}
.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1.3rem;
  padding: 0;
  width: 100%;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f9fbfd, none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f9fbfd, none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #2c7be5;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c0d7f7;
}
.form-range::-webkit-slider-runnable-track {
  background-color: #e3ebf6;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.form-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #2c7be5;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c0d7f7;
}
.form-range::-moz-range-track {
  background-color: #e3ebf6;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #b1c2d9;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #b1c2d9;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}
.form-floating > label {
  border: 1px solid transparent;
  height: 100%;
  left: 0;
  padding: 1rem 0.75rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-select {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}
.input-group-text {
  align-items: center;
  background-color: transparent;
  border: 1px solid #e7e7e7;
  border-radius: 0.375rem;
  color: #95aac9;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}

.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  padding: 0.75rem 1.25rem;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  padding: 0.125rem 0.5rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2.5rem;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
.valid-feedback {
  color: #00d97e;
  margin-top: 0;
  width: 100%;
}
.valid-feedback,
.valid-tooltip {
  display: none;
  font-size: 0.8125rem;
}
.valid-tooltip {
  background-color: #00d97e;
  border-radius: 0.375rem;
  color: #fff;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #00d97e;
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #00d97e;
  box-shadow: 0 0 0 0.15rem rgba(0, 217, 126, 0.25);
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #00d97e;
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #00d97e;
  box-shadow: 0 0 0 0.15rem rgba(0, 217, 126, 0.25);
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #00d97e;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #00d97e;
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.15rem rgba(0, 217, 126, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #00d97e;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}
.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}
.invalid-feedback {
  color: #e63757;
  display: none;
  font-size: 0.8125rem;
  margin-top: 0;
  width: 100%;
}

.errors {
  color: #e63757;
  font-size: 0.7125rem;
  margin-top: 0.3rem;
  width: 100%;
}

.invalid-tooltip {
  background-color: #e63757;
  border-radius: 0.375rem;
  color: #fff;
  display: none;
  font-size: 0.8125rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #e63757;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #e63757;
  box-shadow: 0 0 0 0.15rem rgba(230, 55, 87, 0.25);
}
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #e63757;
}
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #e63757;
  box-shadow: 0 0 0 0.15rem rgba(230, 55, 87, 0.25);
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #e63757;
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #e63757;
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.15rem rgba(230, 55, 87, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #e63757;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}
.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}
.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  color: #0c2b3b;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #0c2b3b;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
  outline: 0;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.65;
  pointer-events: none;
}
.btn-primary {
  background: linear-gradient(180deg, #00b17a 0%, #27a97a 100%);
  border-color: #00b17a;
  color: #fff;
  border-radius: 6px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 300;
  font-size: 0.9rem;
  min-width: 180px;
}
.btn-small {
  border-radius: 6px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  padding-left: 6px;
  font-weight: 300;
  font-size: 0.7rem;
  min-width: 120px;
}

.btn-round {
  background-color: #2bc38c;
  border-color: #2bc38c;
  color: #fff;
  /* border-radius: 50px; */
}
.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #33ac80;
  border-color: #33ac80;
  color: #fff;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: 0 0 0 0.15rem rgba(87, 187, 124, 0.5);
}

.btn-primary span {
  margin-right: 10px;
  padding-top: 2px;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: #33ac80;
  border-color: #33ac80;
  color: #fff;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(76, 143, 233, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}
.btn-secondary {
  background-color: #6e84a3;
  border-color: #6e84a3;
  color: #fff;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #5e708b;
  border-color: #586a82;
  color: #fff;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.15rem rgba(132, 150, 177, 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #586a82;
  border-color: #53637a;
  color: #fff;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(132, 150, 177, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #6e84a3;
  border-color: #6e84a3;
  color: #fff;
}
.btn-success {
  background-color: #00d97e;
  border-color: #00d97e;
  color: #fff;
}
.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-success:hover {
  background-color: #00b86b;
  border-color: #00ae65;
  color: #fff;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  box-shadow: 0 0 0 0.15rem rgba(38, 223, 145, 0.5);
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  background-color: #00ae65;
  border-color: #00a35f;
  color: #fff;
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(38, 223, 145, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: #00d97e;
  border-color: #00d97e;
  color: #fff;
}
.btn-info {
  background-color: #39afd1;
  border-color: #39afd1;
  color: #fff;
}
.btn-check:focus + .btn-info,
.btn-info:focus,
.btn-info:hover {
  background-color: #3095b2;
  border-color: #2e8ca7;
  color: #fff;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  box-shadow: 0 0 0 0.15rem rgba(87, 187, 216, 0.5);
}
.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  background-color: #2e8ca7;
  border-color: #2b839d;
  color: #fff;
}
.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(87, 187, 216, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  background-color: #39afd1;
  border-color: #39afd1;
  color: #fff;
}
.btn-warning {
  background-color: #f6c343;
  border-color: #f6c343;
  color: #283e59;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus,
.btn-warning:hover {
  background-color: #f7cc5f;
  border-color: #f7c956;
  color: #283e59;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  box-shadow: 0 0 0 0.15rem rgba(215, 175, 70, 0.5);
}
.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  background-color: #f8cf69;
  border-color: #f7c956;
  color: #283e59;
}
.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(215, 175, 70, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #f6c343;
  border-color: #f6c343;
  color: #283e59;
}
.btn-danger {
  background-color: #e63757;
  border-color: #e63757;
  color: #fff;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus,
.btn-danger:hover {
  background-color: #c42f4a;
  border-color: #b82c46;
  color: #fff;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 85, 112, 0.5);
}
.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  background-color: #b82c46;
  border-color: #ad2941;
  color: #fff;
}
.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 85, 112, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #e63757;
  border-color: #e63757;
  color: #fff;
}
.btn-light {
  background-color: #edf2f9;
  border-color: #edf2f9;
  color: #283e59;
}
.btn-check:focus + .btn-light,
.btn-light:focus,
.btn-light:hover {
  background-color: #f0f4fa;
  border-color: #eff3fa;
  color: #283e59;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  box-shadow: 0 0 0 0.15rem rgba(207, 215, 225, 0.5);
}
.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  background-color: #f1f5fa;
  border-color: #eff3fa;
  color: #283e59;
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(207, 215, 225, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  background-color: #edf2f9;
  border-color: #edf2f9;
  color: #283e59;
}
.btn-dark {
  background-color: #0c2b3b;
  border-color: #0c2b3b;
  color: #fff;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-dark:hover {
  background-color: #0f2036;
  border-color: #0e1e32;
  color: #fff;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  box-shadow: 0 0 0 0.15rem rgba(54, 71, 92, 0.5);
}
.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  background-color: #0e1e32;
  border-color: #0e1d2f;
  color: #fff;
}
.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(54, 71, 92, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #0c2b3b;
  border-color: #0c2b3b;
  color: #fff;
}
.btn-check:focus + .btn-white,
.btn-white,
.btn-white:focus,
.btn-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.btn-check:focus + .btn-white,
.btn-white:focus {
  box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
}
.btn-check:active + .btn-white,
.btn-check:checked + .btn-white,
.btn-white.active,
.btn-white:active,
.show > .btn-white.dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.btn-check:active + .btn-white:focus,
.btn-check:checked + .btn-white:focus,
.btn-white.active:focus,
.btn-white:active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
}
.btn-white.disabled,
.btn-white:disabled {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.btn-outline-primary {
  border-color: #2bc38c;
  color: #0f1f26;
  border-radius: 6px !important;
  min-width: 150px;
  font-weight: 300;
  font-size: 0.7rem !important;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  padding-top: 3px !important;
}
.btn-outline-primary:hover {
  background-color: #309973;
  border-color: #309973;
  color: #fff;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.15rem rgba(44, 123, 229, 0.5);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.15rem rgba(44, 123, 229, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background-color: transparent;
  color: #2c7be5;
}
.btn-outline-secondary {
  border-color: #6e84a3;
  color: #6e84a3;
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 300;
  font-size: 0.9rem;
  min-width: 180px;
}
.btn-outline-secondary:hover {
  background-color: #6e84a3;
  border-color: #6e84a3;
  color: #fff;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.15rem rgba(110, 132, 163, 0.5);
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  background-color: #6e84a3;
  border-color: #6e84a3;
  color: #fff;
}
.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.15rem rgba(110, 132, 163, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  background-color: transparent;
  color: #6e84a3;
}
.btn-outline-success {
  border-color: #00d97e;
  color: #00d97e;
}
.btn-outline-success:hover {
  background-color: #00d97e;
  border-color: #00d97e;
  color: #fff;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.15rem rgba(0, 217, 126, 0.5);
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  background-color: #00d97e;
  border-color: #00d97e;
  color: #fff;
}
.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.15rem rgba(0, 217, 126, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  background-color: transparent;
  color: #00d97e;
}
.btn-outline-info {
  border-color: #39afd1;
  color: #39afd1;
}
.btn-outline-info:hover {
  background-color: #39afd1;
  border-color: #39afd1;
  color: #fff;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.15rem rgba(57, 175, 209, 0.5);
}
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  background-color: #39afd1;
  border-color: #39afd1;
  color: #fff;
}
.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.15rem rgba(57, 175, 209, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #39afd1;
}
.btn-outline-warning {
  border-color: #f6c343;
  color: #f6c343;
}
.btn-outline-warning:hover {
  background-color: #f6c343;
  border-color: #f6c343;
  color: #283e59;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.15rem rgba(246, 195, 67, 0.5);
}
.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  background-color: #f6c343;
  border-color: #f6c343;
  color: #283e59;
}
.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.15rem rgba(246, 195, 67, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #f6c343;
}
.btn-outline-danger {
  border-color: #e63757;
  color: #e63757;
}
.btn-outline-danger:hover {
  background-color: #e63757;
  border-color: #e63757;
  color: #fff;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.15rem rgba(230, 55, 87, 0.5);
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  background-color: #e63757;
  border-color: #e63757;
  color: #fff;
}
.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.15rem rgba(230, 55, 87, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #e63757;
}
.btn-outline-light {
  border-color: #edf2f9;
  color: #edf2f9;
}
.btn-outline-light:hover {
  background-color: #edf2f9;
  border-color: #edf2f9;
  color: #283e59;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.15rem rgba(237, 242, 249, 0.5);
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  background-color: #edf2f9;
  border-color: #edf2f9;
  color: #283e59;
}
.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.15rem rgba(237, 242, 249, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  background-color: transparent;
  color: #edf2f9;
}
.btn-outline-dark {
  border-color: #0c2b3b;
  color: #0c2b3b;
}
.btn-outline-dark:hover {
  background-color: #0c2b3b;
  border-color: #0c2b3b;
  color: #fff;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.15rem rgba(18, 38, 63, 0.5);
}
.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  background-color: #0c2b3b;
  border-color: #0c2b3b;
  color: #fff;
}
.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.15rem rgba(18, 38, 63, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  background-color: transparent;
  color: #0c2b3b;
}
.btn-outline-white {
  border-color: #fff;
  color: #fff;
}
.btn-outline-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.btn-check:focus + .btn-outline-white,
.btn-outline-white:focus {
  box-shadow: 0 0 0 0.15rem hsla(0, 0%, 100%, 0.5);
}
.btn-check:active + .btn-outline-white,
.btn-check:checked + .btn-outline-white,
.btn-outline-white.active,
.btn-outline-white.dropdown-toggle.show,
.btn-outline-white:active {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.btn-check:active + .btn-outline-white:focus,
.btn-check:checked + .btn-outline-white:focus,
.btn-outline-white.active:focus,
.btn-outline-white.dropdown-toggle.show:focus,
.btn-outline-white:active:focus {
  box-shadow: 0 0 0 0.15rem hsla(0, 0%, 100%, 0.5);
}
.btn-outline-white.disabled,
.btn-outline-white:disabled {
  background-color: transparent;
  color: #fff;
}
.btn-link {
  color: #2c7be5;
  font-weight: 400;
  text-decoration: none;
}
.btn-link:hover {
  color: #1657af;
}
.btn-link:focus,
.btn-link:hover {
  text-decoration: none;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #b1c2d9;
}
.btn-group-lg > .btn,
.btn-lg {
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  padding: 0.75rem 1.25rem;
}
.btn-group-sm > .btn,
.btn-sm {
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  padding: 0.125rem 0.5rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.25em solid transparent;
  border-right: 0.25em solid transparent;
  border-top: 0.25em solid;
  content: "";
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
}
.dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropdown-menu {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(18, 38, 63, 0.1);
  border-radius: 0.375rem;
  color: #0c2b3b;
  display: none;
  font-size: 0.9375rem;
  list-style: none;
  margin: 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  z-index: 1000;
}
.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0;
  top: 100%;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  bottom: 100%;
  margin-bottom: 0;
  margin-top: 0;
  top: auto;
}
.dropup .dropdown-toggle:after {
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent;
  border-right: 0.25em solid transparent;
  border-top: 0;
  content: "";
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
}
.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  left: 100%;
  margin-left: 0;
  margin-top: 0;
  right: auto;
  top: 0;
}
.dropend .dropdown-toggle:after {
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid;
  border-right: 0;
  border-top: 0.25em solid transparent;
  content: "";
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
}
.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropend .dropdown-toggle:after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  left: auto;
  margin-right: 0;
  margin-top: 0;
  right: 100%;
  top: 0;
}
.dropstart .dropdown-toggle:after {
  content: "";
  display: inline-block;
  display: none;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
}
.dropstart .dropdown-toggle:before {
  border-bottom: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-top: 0.25em solid transparent;
  content: "";
  display: inline-block;
  margin-right: 0.2125em;
  vertical-align: 0.2125em;
}
.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}
.dropdown-divider {
  border-top: 1px solid rgba(18, 38, 63, 0.1);
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
}
.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #6e84a3;
  display: block;
  font-weight: 400;
  padding: 0.375rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
  color: #2bc38c;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: #0c2b3b;
  text-decoration: none;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: transparent;
  color: #b1c2d9;
  pointer-events: none;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  color: inherit;
  display: block;
  font-size: 0.8125rem;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
}
.dropdown-item-text {
  color: #6e84a3;
  display: block;
  padding: 0.375rem 1.5rem;
}
.dropdown-menu-dark {
  background-color: #3b506c;
  border-color: rgba(18, 38, 63, 0.1);
  color: #e3ebf6;
}
.dropdown-menu-dark .dropdown-item {
  color: #e3ebf6;
}
.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  background-color: hsla(0, 0%, 100%, 0.15);
  color: #fff;
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  background-color: transparent;
  color: #0c2b3b;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #b1c2d9;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(18, 38, 63, 0.1);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #e3ebf6;
}
.dropdown-menu-dark .dropdown-header {
  color: #b1c2d9;
}
.btn-group,
.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: 1 1 auto;
  position: relative;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.dropdown-toggle-split {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}
.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.nav-link {
  color: #2c7be5;
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:focus,
.nav-link:hover {
  color: #1657af;
}
.nav-link.disabled {
  color: #95aac9;
  cursor: default;
  pointer-events: none;
}
.nav-tabs {
  border-bottom: 1px solid #e3ebf6;
}
.nav-tabs .nav-link {
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: -1px;
  min-width: 50px;
  text-align: center;
  text-decoration: none;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: #95aac9;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent transparent #2bc38c;
  color: #2bc38c;
}
.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.375rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #2c7be5;
  color: #fff;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  position: relative;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl {
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
}
.navbar-brand {
  font-size: 1.0625rem;
  margin-right: 1rem;
  padding-bottom: 0;
  padding-top: 0;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}
.navbar-toggler {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  font-size: 1.0625rem;
  line-height: 1;
  padding: 0.25rem 0;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
  text-decoration: none;
}
.navbar-toggler-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #0c2b3b;
}
.navbar-light .navbar-nav .nav-link {
  color: #6e84a3;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #0c2b3b;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(18, 38, 63, 0.3);
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #0c2b3b;
}
.navbar-light .navbar-toggler {
  border-color: transparent;
  color: #6e84a3;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%236E84A3' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: #6e84a3;
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: #0c2b3b;
}
.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #6e84a3;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  border-color: transparent;
  color: #6e84a3;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%236E84A3' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: #6e84a3;
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}
.card > hr {
  margin-left: 0;
  margin-right: 0;
}
.card > .list-group {
  border-bottom: inherit;
  border-top: inherit;
}
.card > .list-group:first-child {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-left-radius: calc(0.5rem - 1px);
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}
.card-title {
  margin-bottom: 0.5rem;
}
.card-subtitle {
  margin-top: -0.25rem;
}
.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}
.card-header {
  background-color: transparent;
  border-bottom: 1px solid #edf2f9;
  margin-bottom: 0;
  padding: 1rem 1.5rem;
}
.card-header:first-child {
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}
.card-footer {
  background-color: transparent;
  border-top: 1px solid #edf2f9;
  padding: 1rem 1.5rem;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
}
.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -1rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}
.card-header-pills {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.card-img-overlay {
  border-radius: calc(0.5rem - 1px);
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-left-radius: calc(0.5rem - 1px);
  border-bottom-right-radius: calc(0.5rem - 1px);
}
.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion-button {
  overflow-anchor: none;
  align-items: center;
  background-color: #f9fbfd;
  border: 0;
  border-radius: 0;
  color: #0c2b3b;
  display: flex;
  font-size: 0.9375rem;
  padding: 1rem 1.25rem;
  position: relative;
  text-align: left;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  background-color: #eaf2fc;
  box-shadow: inset 0 -1px 0 rgba(18, 38, 63, 0.125);
  color: #286fce;
}
.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23286fce'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  transform: rotate(-180deg);
}
.accordion-button:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2312263F'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  content: "";
  flex-shrink: 0;
  height: 1.25rem;
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
  width: 1.25rem;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  border-color: #2c7be5;
  box-shadow: none;
  outline: 0;
  z-index: 3;
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: #f9fbfd;
  border: 1px solid rgba(18, 38, 63, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: calc(0.375rem - 1px);
  border-bottom-right-radius: calc(0.375rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.accordion-body {
  padding: 1rem 1.25rem;
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.breadcrumb {
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  text-transform: capitalize;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.35rem;
}
.breadcrumb-item + .breadcrumb-item:before {
  content: var(--bs-breadcrumb-divider, "");
  float: left;
  padding-right: 0.35rem;
}
.breadcrumb-item.active {
  color: #2bc38c;
}
.breadcrumb-item a {
  color: #0f1f26;
  cursor: pointer;
}
.pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
}
.page,
.page-link {
  background-color: #fff;
  border: 1px solid #e3ebf6;
  color: #0c2b3b;
  display: block;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page,
  .page-link {
    transition: none;
  }
}
.page-link:hover,
.page:hover {
  background-color: #f9fbfd;
  border-color: #e3ebf6;
  color: #0c2b3b;
  z-index: 2;
}
.page-link:focus,
.page:focus {
  background-color: #edf2f9;
  box-shadow: none;
  color: #1657af;
  outline: 0;
  z-index: 3;
}
.page-item:not(:first-child) .page,
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page,
.page-item.active .page-link {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
  z-index: 3;
}
.page-item.disabled .page,
.page-item.disabled .page-link {
  background-color: #fff;
  border-color: #e3ebf6;
  color: #95aac9;
  pointer-events: none;
}
.page,
.page-link {
  padding: 0.5rem 0.75rem;
}
.page-item:first-child .page,
.page-item:first-child .page-link {
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}
.page-item:last-child .page,
.page-item:last-child .page-link {
  border-bottom-right-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.pagination-lg .page,
.pagination-lg .page-link {
  font-size: 1.0625rem;
  padding: 0.75rem 1.25rem;
}
.pagination-lg .page-item:first-child .page,
.pagination-lg .page-item:first-child .page-link {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
.pagination-lg .page-item:last-child .page,
.pagination-lg .page-item:last-child .page-link {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.pagination-sm .page,
.pagination-sm .page-link {
  padding: 0.125rem 0.5rem;
}
.pagination-sm .page-item:first-child .page,
.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.pagination-sm .page-item:last-child .page,
.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.badge {
  border-radius: 0.375rem;
  color: #fff;
  display: inline-block;
  font-size: 76%;
  font-weight: 400;
  line-height: 1;
  padding: 0.33em 0.5em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  border: 1px solid transparent;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
  padding: 0.75rem 1.25rem;
  position: relative;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 400;
}
.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  padding: 0.9375rem 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.alert-primary {
  color: #12315c;
}
.alert-primary .alert-link {
  color: #0e274a;
}
.alert-secondary {
  color: #2c3541;
}
.alert-secondary .alert-link {
  color: #232a34;
}
.alert-success {
  color: #005732;
}
.alert-success .alert-link {
  color: #004628;
}
.alert-info {
  color: #174654;
}
.alert-info .alert-link {
  color: #123843;
}
.alert-warning {
  color: #624e1b;
}
.alert-warning .alert-link {
  color: #4e3e16;
}
.alert-danger {
  color: #5c1623;
}
.alert-danger .alert-link {
  color: #4a121c;
}
.alert-light {
  color: #5f6164;
}
.alert-light .alert-link {
  color: #4c4e50;
}
.alert-dark {
  color: #717d8c;
}
.alert-dark .alert-link {
  color: #5a6470;
}
.alert-white {
  color: #666;
}
.alert-white .alert-link {
  color: #525252;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  background-color: #edf2f9;
  border-radius: 200px;
  font-size: 0.70312rem;
  height: 1rem;
}
.progress,
.progress-bar {
  display: flex;
  overflow: hidden;
}
.progress-bar {
  background-color: #2c7be5;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 0.6s ease;
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.list-group {
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}
.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}
.list-group-numbered > li:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  color: #0c2b3b;
  text-align: inherit;
  width: 100%;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: #f9fbfd;
  color: #0c2b3b;
  text-decoration: none;
  z-index: 1;
}
.list-group-item-action:active {
  background-color: #edf2f9;
  color: #0c2b3b;
}
.list-group-item {
  background-color: transparent;
  border: 1px solid #edf2f9;
  color: #283e59;
  display: block;
  padding: 1rem 1.25rem;
  position: relative;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  background-color: transparent;
  color: #95aac9;
  pointer-events: none;
}
.list-group-item.active {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
  z-index: 2;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.375rem;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-left-width: 0;
  border-top-width: 1px;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  background-color: #d5e5fa;
  color: #1a4a89;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  background-color: #c0cee1;
  color: #1a4a89;
}
.list-group-item-primary.list-group-item-action.active {
  background-color: #1a4a89;
  border-color: #1a4a89;
}
.list-group-item-secondary {
  background-color: #e2e6ed;
  color: #424f62;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  background-color: #cbcfd5;
  color: #424f62;
}
.list-group-item-secondary.list-group-item-action.active {
  background-color: #424f62;
  border-color: #424f62;
}
.list-group-item-success {
  background-color: #ccf7e5;
  color: #00824c;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  background-color: #b8dece;
  color: #00824c;
}
.list-group-item-success.list-group-item-action.active {
  background-color: #00824c;
  border-color: #00824c;
}
.list-group-item-info {
  background-color: #d7eff6;
  color: #22697d;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  background-color: #c2d7dd;
  color: #22697d;
}
.list-group-item-info.list-group-item-action.active {
  background-color: #22697d;
  border-color: #22697d;
}
.list-group-item-warning {
  background-color: #fdf3d9;
  color: #947528;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  background-color: #e4dbc3;
  color: #947528;
}
.list-group-item-warning.list-group-item-action.active {
  background-color: #947528;
  border-color: #947528;
}
.list-group-item-danger {
  background-color: #fad7dd;
  color: #8a2134;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  background-color: #e1c2c7;
  color: #8a2134;
}
.list-group-item-danger.list-group-item-action.active {
  background-color: #8a2134;
  border-color: #8a2134;
}
.list-group-item-light {
  background-color: #fbfcfe;
  color: #8e9195;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  background-color: #e2e3e5;
  color: #8e9195;
}
.list-group-item-light.list-group-item-action.active {
  background-color: #8e9195;
  border-color: #8e9195;
}
.list-group-item-dark {
  background-color: #d0d4d9;
  color: #0b1726;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  background-color: #bbbfc3;
  color: #0b1726;
}
.list-group-item-dark.list-group-item-action.active {
  background-color: #0b1726;
  border-color: #0b1726;
}
.list-group-item-white,
.list-group-item-white.list-group-item-action:focus,
.list-group-item-white.list-group-item-action:hover {
  color: #999;
}
.list-group-item-white.list-group-item-action.active {
  background-color: #999;
  border-color: #999;
}
.btn-close {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2312263F'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/0.5em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: content-box;
  color: #0c2b3b;
  height: 0.5em;
  opacity: 0.5;
  padding: 0.5em;
  width: 0.5em;
}
.btn-close:hover {
  color: #0c2b3b;
  opacity: 0.75;
  text-decoration: none;
}
.btn-close:focus {
  box-shadow: none;
  opacity: 1;
  outline: 0;
}
.btn-close.disabled,
.btn-close:disabled {
  opacity: 0.25;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.toast {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e3ebf6;
  border-radius: 0.375rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.1);
  font-size: 0.9375rem;
  max-width: 100%;
  pointer-events: auto;
  width: 300px;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}
.toast-container {
  max-width: 100%;
  pointer-events: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: #fff;
  border-bottom: 1px solid #e3ebf6;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
  color: #0c2b3b;
  display: flex;
  padding: 0.75rem 1rem;
}
.toast-header .btn-close {
  margin-left: 1rem;
  margin-right: -0.5rem;
}
.toast-body {
  word-wrap: break-word;
  padding: 1rem;
}
.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1060;
}
.modal-dialog {
  margin: 0.5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}
.modal.fade .modal-dialog {
  transform: translateY(-50px);
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1rem);
}
.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}
.modal-backdrop {
  background-color: #0c2b3b;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  align-items: center;
  border-bottom: 1px solid #e3ebf6;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}
.modal-header .btn-close {
  margin: -0.5rem -0.75rem -0.5rem auto;
  padding: 0.5rem 0.75rem;
}
.modal-title {
  line-height: 1.5;
  margin-bottom: 0;
}
.modal-body {
  flex: 1 1 auto;
  padding: 1.5rem;
  position: relative;
}
.modal-footer {
  align-items: center;
  border-bottom-left-radius: calc(0.5rem - 1px);
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-top: 1px solid #e3ebf6;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 1.25rem;
}
.modal-footer > * {
  margin: 0.25rem;
}
@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 600px;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: none;
  width: 100vw;
}
.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  word-wrap: break-word;
  display: block;
  font-family: var(--bs-font-sans-serif);
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  margin: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1080;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-arrow {
  display: block;
  height: 0.4rem;
  position: absolute;
  width: 0.8rem;
}
.tooltip .tooltip-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}
.bs-tooltip-auto[data-popper-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #e3ebf6;
  border-width: 0.4rem 0.4rem 0;
  top: -1px;
}
.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  height: 0.8rem;
  left: 0;
  width: 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: #e3ebf6;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  right: -1px;
}
.bs-tooltip-auto[data-popper-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #e3ebf6;
  border-width: 0 0.4rem 0.4rem;
  bottom: -1px;
}
.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  height: 0.8rem;
  right: 0;
  width: 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: #e3ebf6;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  left: -1px;
}
.tooltip-inner {
  background-color: #e3ebf6;
  border-radius: 0.375rem;
  color: #0c2b3b;
  max-width: 200px;
  padding: 0.375rem 0.75rem;
  text-align: center;
}
.popover {
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(18, 38, 63, 0.2);
  border-radius: 0.5rem;
  display: block;
  font-family: var(--bs-font-sans-serif);
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  left: 0;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: 10rem;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  top: 0;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1070;
}
.popover .popover-arrow {
  display: block;
  height: 0.5rem;
  position: absolute;
  width: 1rem;
}
.popover .popover-arrow:after,
.popover .popover-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:before {
  border-top-color: rgba(18, 38, 63, 0.25);
  border-width: 0.5rem 0.5rem 0;
  bottom: 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-top > .popover-arrow:after {
  border-top-color: #fff;
  border-width: 0.5rem 0.5rem 0;
  bottom: 1px;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  height: 1rem;
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:before {
  border-right-color: rgba(18, 38, 63, 0.25);
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-end > .popover-arrow:after {
  border-right-color: #fff;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 1px;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: rgba(18, 38, 63, 0.25);
  border-width: 0 0.5rem 0.5rem;
  top: 0;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:after {
  border-bottom-color: #fff;
  border-width: 0 0.5rem 0.5rem;
  top: 1px;
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  border-bottom: 1px solid #fff;
  content: "";
  display: block;
  left: 50%;
  margin-left: -0.5rem;
  position: absolute;
  top: 0;
  width: 1rem;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  height: 1rem;
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:before {
  border-left-color: rgba(18, 38, 63, 0.25);
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-start > .popover-arrow:after {
  border-left-color: #fff;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 1px;
}
.popover-header {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  font-size: 0.9375rem;
  margin-bottom: 0;
  padding: 0;
}
.popover-header:empty {
  display: none;
}
.popover-body {
  color: #95aac9;
  padding: 0;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}
.carousel-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}
.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transform: none;
  transition-property: opacity;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  opacity: 1;
  z-index: 1;
}
.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  opacity: 0;
  transition: opacity 0s 0.6s;
  z-index: 0;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  background: none;
  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.15s ease;
  width: 15%;
  z-index: 1;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  opacity: 0.9;
  outline: 0;
  text-decoration: none;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}
.carousel-indicators [data-bs-target] {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 1 auto;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.5;
  padding: 0;
  text-indent: -999px;
  transition: opacity 0.6s ease;
  width: 30px;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  bottom: 1.25rem;
  color: #fff;
  left: 15%;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  position: absolute;
  right: 15%;
  text-align: center;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #0c2b3b;
}
.carousel-dark .carousel-caption {
  color: #0c2b3b;
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
.spinner-border {
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  border: 0.25em solid;
  border-radius: 50%;
  border-right: 0.25em solid transparent;
  display: inline-block;
  height: 2rem;
  vertical-align: -0.125em;
  width: 2rem;
}
.spinner-border-sm {
  border-width: 0.2em;
  height: 1rem;
  width: 1rem;
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  opacity: 0;
  vertical-align: -0.125em;
  width: 2rem;
}
.spinner-grow-sm {
  height: 1rem;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  background-clip: padding-box;
  background-color: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  visibility: hidden;
  z-index: 1050;
  min-width: 550px;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}
.offcanvas-header .btn-close {
  margin: -0.75rem -0.75rem -0.75rem auto;
  padding: 0.75rem;
}
.offcanvas-title {
  line-height: 1.5;
  margin-bottom: 0;
}
.offcanvas-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.5rem;
}
.offcanvas-start {
  border-right: 1px solid transparent;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: 350px;
}
.offcanvas-end {
  border-left: 1px solid transparent;
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 350px;
}
.offcanvas-top {
  border-bottom: 1px solid transparent;
  top: 0;
  transform: translateY(-100%);
}
.offcanvas-bottom,
.offcanvas-top {
  height: 30vh;
  left: 0;
  max-height: 100%;
  right: 0;
}
.offcanvas-bottom {
  border-top: 1px solid transparent;
  transform: translateY(100%);
}
.offcanvas.show {
  transform: none;
}
.clearfix:after {
  clear: both;
  content: "";
  display: block;
}
.link-primary {
  color: #2c7be5;
}
.link-primary:focus,
.link-primary:hover {
  color: #2362b7;
}
.link-secondary {
  color: #6e84a3;
}
.link-secondary:focus,
.link-secondary:hover {
  color: #586a82;
}
.link-success {
  color: #00d97e;
}
.link-success:focus,
.link-success:hover {
  color: #00ae65;
}
.link-info {
  color: #39afd1;
}
.link-info:focus,
.link-info:hover {
  color: #2e8ca7;
}
.link-warning {
  color: #f6c343;
}
.link-warning:focus,
.link-warning:hover {
  color: #f8cf69;
}
.link-danger {
  color: #e63757;
}
.link-danger:focus,
.link-danger:hover {
  color: #b82c46;
}
.link-light {
  color: #edf2f9;
}
.link-light:focus,
.link-light:hover {
  color: #f1f5fa;
}
.link-dark {
  color: #0c2b3b;
}
.link-dark:focus,
.link-dark:hover {
  color: #0e1e32;
}
.link-white,
.link-white:focus,
.link-white:hover {
  color: #fff;
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio:before {
  content: "";
  display: block;
  padding-top: var(--bs-aspect-ratio);
}
.ratio > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --bs-aspect-ratio: 42.85714%;
}
.fixed-top {
  top: 0;
}
.fixed-bottom,
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}
.fixed-bottom {
  bottom: 0;
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
.stretched-link:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(18, 38, 63, 0.15) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(18, 38, 63, 0.075) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(18, 38, 63, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: 1px solid #e3ebf6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: 1px solid #e3ebf6 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: 1px solid #e3ebf6 !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: 1px solid #e3ebf6 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: 1px solid #e3ebf6 !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #2c7be5 !important;
}
.border-secondary {
  border-color: #6e84a3 !important;
}
.border-success {
  border-color: #00d97e !important;
}
.border-info {
  border-color: #39afd1 !important;
}
.border-warning {
  border-color: #f6c343 !important;
}
.border-danger {
  border-color: #e63757 !important;
}
.border-light {
  border-color: #edf2f9 !important;
}
.border-dark {
  border-color: #0c2b3b !important;
}
.border-white {
  border-color: #fff !important;
}
.border-body {
  border-color: #f9fbfd !important;
}
.border-card {
  border-color: #fff !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.1875rem !important;
}
.gap-2 {
  gap: 0.375rem !important;
}
.gap-3 {
  gap: 0.75rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 2.25rem !important;
}
.gap-6 {
  gap: 4.5rem !important;
}
.gap-7 {
  gap: 6.75rem !important;
}
.gap-8 {
  gap: 13.5rem !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.1875rem !important;
}
.m-2 {
  margin: 0.375rem !important;
}
.m-3 {
  margin: 0.75rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 2.25rem !important;
}
.m-6 {
  margin: 4.5rem !important;
}
.m-7 {
  margin: 6.75rem !important;
}
.m-8 {
  margin: 13.5rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 0.1875rem !important;
  margin-right: 0.1875rem !important;
}
.mx-2 {
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}
.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-5 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}
.mx-6 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}
.mx-7 {
  margin-left: 6.75rem !important;
  margin-right: 6.75rem !important;
}
.mx-8 {
  margin-left: 13.5rem !important;
  margin-right: 13.5rem !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.my-1 {
  margin-bottom: 0.1875rem !important;
  margin-top: 0.1875rem !important;
}
.my-2 {
  margin-bottom: 0.375rem !important;
  margin-top: 0.375rem !important;
}
.my-3 {
  margin-bottom: 0.75rem !important;
  margin-top: 0.75rem !important;
}
.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}
.my-5 {
  margin-bottom: 2.25rem !important;
  margin-top: 2.25rem !important;
}
.my-6 {
  margin-bottom: 4.5rem !important;
  margin-top: 4.5rem !important;
}
.my-7 {
  margin-bottom: 6.75rem !important;
  margin-top: 6.75rem !important;
}
.my-8 {
  margin-bottom: 13.5rem !important;
  margin-top: 13.5rem !important;
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.1875rem !important;
}
.mt-2 {
  margin-top: 0.375rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 2.25rem !important;
}
.mt-6 {
  margin-top: 4.5rem !important;
}
.mt-7 {
  margin-top: 6.75rem !important;
}
.mt-8 {
  margin-top: 13.5rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.1875rem !important;
}
.me-2 {
  margin-right: 0.375rem !important;
}
.me-3 {
  margin-right: 0.75rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 2.25rem !important;
}
.me-6 {
  margin-right: 4.5rem !important;
}
.me-7 {
  margin-right: 6.75rem !important;
}
.me-8 {
  margin-right: 13.5rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.1875rem !important;
}
.mb-2 {
  margin-bottom: 0.375rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 2.25rem !important;
}
.mb-6 {
  margin-bottom: 4.5rem !important;
}
.mb-7 {
  margin-bottom: 6.75rem !important;
}
.mb-8 {
  margin-bottom: 13.5rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.1875rem !important;
}
.ms-2 {
  margin-left: 0.375rem !important;
}
.ms-3 {
  margin-left: 0.75rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 2.25rem !important;
}
.ms-6 {
  margin-left: 4.5rem !important;
}
.ms-7 {
  margin-left: 6.75rem !important;
}
.ms-8 {
  margin-left: 13.5rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.1875rem !important;
}
.m-n2 {
  margin: -0.375rem !important;
}
.m-n3 {
  margin: -0.75rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.m-n5 {
  margin: -2.25rem !important;
}
.m-n6 {
  margin: -4.5rem !important;
}
.m-n7 {
  margin: -6.75rem !important;
}
.m-n8 {
  margin: -13.5rem !important;
}
.mx-n1 {
  margin-left: -0.1875rem !important;
  margin-right: -0.1875rem !important;
}
.mx-n2 {
  margin-left: -0.375rem !important;
  margin-right: -0.375rem !important;
}
.mx-n3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}
.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.mx-n5 {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}
.mx-n6 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}
.mx-n7 {
  margin-left: -6.75rem !important;
  margin-right: -6.75rem !important;
}
.mx-n8 {
  margin-left: -13.5rem !important;
  margin-right: -13.5rem !important;
}
.my-n1 {
  margin-bottom: -0.1875rem !important;
  margin-top: -0.1875rem !important;
}
.my-n2 {
  margin-bottom: -0.375rem !important;
  margin-top: -0.375rem !important;
}
.my-n3 {
  margin-bottom: -0.75rem !important;
  margin-top: -0.75rem !important;
}
.my-n4 {
  margin-bottom: -1.5rem !important;
  margin-top: -1.5rem !important;
}
.my-n5 {
  margin-bottom: -2.25rem !important;
  margin-top: -2.25rem !important;
}
.my-n6 {
  margin-bottom: -4.5rem !important;
  margin-top: -4.5rem !important;
}
.my-n7 {
  margin-bottom: -6.75rem !important;
  margin-top: -6.75rem !important;
}
.my-n8 {
  margin-bottom: -13.5rem !important;
  margin-top: -13.5rem !important;
}
.mt-n1 {
  margin-top: -0.1875rem !important;
}
.mt-n2 {
  margin-top: -0.375rem !important;
}
.mt-n3 {
  margin-top: -0.75rem !important;
}
.mt-n4 {
  margin-top: -1.5rem !important;
}
.mt-n5 {
  margin-top: -2.25rem !important;
}
.mt-n6 {
  margin-top: -4.5rem !important;
}
.mt-n7 {
  margin-top: -6.75rem !important;
}
.mt-n8 {
  margin-top: -13.5rem !important;
}
.me-n1 {
  margin-right: -0.1875rem !important;
}
.me-n2 {
  margin-right: -0.375rem !important;
}
.me-n3 {
  margin-right: -0.75rem !important;
}
.me-n4 {
  margin-right: -1.5rem !important;
}
.me-n5 {
  margin-right: -2.25rem !important;
}
.me-n6 {
  margin-right: -4.5rem !important;
}
.me-n7 {
  margin-right: -6.75rem !important;
}
.me-n8 {
  margin-right: -13.5rem !important;
}
.mb-n1 {
  margin-bottom: -0.1875rem !important;
}
.mb-n2 {
  margin-bottom: -0.375rem !important;
}
.mb-n3 {
  margin-bottom: -0.75rem !important;
}
.mb-n4 {
  margin-bottom: -1.5rem !important;
}
.mb-n5 {
  margin-bottom: -2.25rem !important;
}
.mb-n6 {
  margin-bottom: -4.5rem !important;
}
.mb-n7 {
  margin-bottom: -6.75rem !important;
}
.mb-n8 {
  margin-bottom: -13.5rem !important;
}
.ms-n1 {
  margin-left: -0.1875rem !important;
}
.ms-n2 {
  margin-left: -0.375rem !important;
}
.ms-n3 {
  margin-left: -0.75rem !important;
}
.ms-n4 {
  margin-left: -1.5rem !important;
}
.ms-n5 {
  margin-left: -2.25rem !important;
}
.ms-n6 {
  margin-left: -4.5rem !important;
}
.ms-n7 {
  margin-left: -6.75rem !important;
}
.ms-n8 {
  margin-left: -13.5rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.1875rem !important;
}
.p-2 {
  padding: 0.375rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 2.25rem !important;
}
.p-6 {
  padding: 4.5rem !important;
}
.p-7 {
  padding: 6.75rem !important;
}
.p-8 {
  padding: 13.5rem !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 0.1875rem !important;
  padding-right: 0.1875rem !important;
}
.px-2 {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-5 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}
.px-6 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}
.px-7 {
  padding-left: 6.75rem !important;
  padding-right: 6.75rem !important;
}
.px-8 {
  padding-left: 13.5rem !important;
  padding-right: 13.5rem !important;
}
.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.py-1 {
  padding-bottom: 0.1875rem !important;
  padding-top: 0.1875rem !important;
}
.py-2 {
  padding-bottom: 0.375rem !important;
  padding-top: 0.375rem !important;
}
.py-3 {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
}
.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}
.py-5 {
  padding-bottom: 2.25rem !important;
  padding-top: 2.25rem !important;
}
.py-6 {
  padding-bottom: 4.5rem !important;
  padding-top: 4.5rem !important;
}
.py-7 {
  padding-bottom: 6.75rem !important;
  padding-top: 6.75rem !important;
}
.py-8 {
  padding-bottom: 13.5rem !important;
  padding-top: 13.5rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.1875rem !important;
}
.pt-2 {
  padding-top: 0.375rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 2.25rem !important;
}
.pt-6 {
  padding-top: 4.5rem !important;
}
.pt-7 {
  padding-top: 6.75rem !important;
}
.pt-8 {
  padding-top: 13.5rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.1875rem !important;
}
.pe-2 {
  padding-right: 0.375rem !important;
}
.pe-3 {
  padding-right: 0.75rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 2.25rem !important;
}
.pe-6 {
  padding-right: 4.5rem !important;
}
.pe-7 {
  padding-right: 6.75rem !important;
}
.pe-8 {
  padding-right: 13.5rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.1875rem !important;
}
.pb-2 {
  padding-bottom: 0.375rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 2.25rem !important;
}
.pb-6 {
  padding-bottom: 4.5rem !important;
}
.pb-7 {
  padding-bottom: 6.75rem !important;
}
.pb-8 {
  padding-bottom: 13.5rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.1875rem !important;
}
.ps-2 {
  padding-left: 0.375rem !important;
}
.ps-3 {
  padding-left: 0.75rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 2.25rem !important;
}
.ps-6 {
  padding-left: 4.5rem !important;
}
.ps-7 {
  padding-left: 6.75rem !important;
}
.ps-8 {
  padding-left: 13.5rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: 1.625rem !important;
}
.fs-2 {
  font-size: 1.25rem !important;
}
.fs-3 {
  font-size: 1.0625rem !important;
}
.fs-4 {
  font-size: 0.9375rem !important;
}
.fs-5 {
  font-size: 0.8125rem !important;
}
.fs-6 {
  font-size: 0.625rem !important;
}
.fs-base {
  font-size: 0.9375rem !important;
}
.fs-lg {
  font-size: 1.0625rem !important;
}
.fs-sm {
  font-size: 0.8125rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 400 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 600 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.75 !important;
}
.lh-base,
.lh-lg {
  line-height: 1.5 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  color: #2c7be5 !important;
}
.text-secondary {
  color: #6e84a3 !important;
}
.text-success {
  color: #00d97e !important;
}
.text-info {
  color: #39afd1 !important;
}
.text-warning {
  color: #f6c343 !important;
}
.text-danger {
  color: #e63757 !important;
}
.text-light {
  color: #edf2f9 !important;
}
.text-dark {
  color: #0c2b3b !important;
}
.text-white {
  color: #fff !important;
}
.text-body {
  color: #0c2b3b !important;
}
.text-muted {
  color: #95aac9 !important;
}
.text-black-50 {
  color: rgba(18, 38, 63, 0.5) !important;
}
.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}
.text-reset {
  color: inherit !important;
}
.bg-primary {
  background-color: #2c7be5 !important;
}
.bg-secondary {
  background-color: #6e84a3 !important;
}
.bg-success {
  background-color: #00d97e !important;
}
.bg-info {
  background-color: #39afd1 !important;
}
.bg-warning {
  background-color: #f6c343 !important;
}
.bg-danger {
  background-color: #e63757 !important;
}
.bg-light {
  background-color: #edf2f9 !important;
}
.bg-dark {
  background-color: #0c2b3b !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-body {
  background-color: #f9fbfd !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-auth {
  background-color: #fff !important;
}
.bg-lighter {
  background-color: #f9fbfd !important;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: 0.375rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: 0.25rem !important;
}
.rounded-2 {
  border-radius: 0.375rem !important;
}
.rounded-3 {
  border-radius: 0.5rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-top {
  border-top-left-radius: 0.375rem !important;
}
.rounded-end,
.rounded-top {
  border-top-right-radius: 0.375rem !important;
}
.rounded-bottom,
.rounded-end {
  border-bottom-right-radius: 0.375rem !important;
}
.rounded-bottom,
.rounded-start {
  border-bottom-left-radius: 0.375rem !important;
}
.rounded-start {
  border-top-left-radius: 0.375rem !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.border-top-2 {
  border-top-width: 2px !important;
}
.border-top-3 {
  border-top-width: 3px !important;
}
.border-top-4 {
  border-top-width: 4px !important;
}
.border-top-5 {
  border-top-width: 5px !important;
}
.border-end-2 {
  border-right-width: 2px !important;
}
.border-end-3 {
  border-right-width: 3px !important;
}
.border-end-4 {
  border-right-width: 4px !important;
}
.border-end-5 {
  border-right-width: 5px !important;
}
.border-bottom-2 {
  border-bottom-width: 2px !important;
}
.border-bottom-3 {
  border-bottom-width: 3px !important;
}
.border-bottom-4 {
  border-bottom-width: 4px !important;
}
.border-bottom-5 {
  border-bottom-width: 5px !important;
}
.border-start-2 {
  border-left-width: 2px !important;
}
.border-start-3 {
  border-left-width: 3px !important;
}
.border-start-4 {
  border-left-width: 4px !important;
}
.border-start-5 {
  border-left-width: 5px !important;
}
.text-gray-100 {
  color: #f9fbfd !important;
}
.text-gray-200 {
  color: #edf2f9 !important;
}
.text-gray-300 {
  color: #e3ebf6 !important;
}
.text-gray-400 {
  color: #d2ddec !important;
}
.text-gray-500 {
  color: #b1c2d9 !important;
}
.text-gray-600 {
  color: #95aac9 !important;
}
.text-gray-700 {
  color: #6e84a3 !important;
}
.text-gray-800 {
  color: #3b506c !important;
}
.text-gray-900 {
  color: #283e59 !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.1875rem !important;
  }
  .gap-sm-2 {
    gap: 0.375rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2.25rem !important;
  }
  .gap-sm-6 {
    gap: 4.5rem !important;
  }
  .gap-sm-7 {
    gap: 6.75rem !important;
  }
  .gap-sm-8 {
    gap: 13.5rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.1875rem !important;
  }
  .m-sm-2 {
    margin: 0.375rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2.25rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6.75rem !important;
  }
  .m-sm-8 {
    margin: 13.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-sm-1 {
    margin-left: 0.1875rem !important;
    margin-right: 0.1875rem !important;
  }
  .mx-sm-2 {
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }
  .mx-sm-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }
  .mx-sm-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-sm-7 {
    margin-left: 6.75rem !important;
    margin-right: 6.75rem !important;
  }
  .mx-sm-8 {
    margin-left: 13.5rem !important;
    margin-right: 13.5rem !important;
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-sm-1 {
    margin-bottom: 0.1875rem !important;
    margin-top: 0.1875rem !important;
  }
  .my-sm-2 {
    margin-bottom: 0.375rem !important;
    margin-top: 0.375rem !important;
  }
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-sm-5 {
    margin-bottom: 2.25rem !important;
    margin-top: 2.25rem !important;
  }
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-sm-7 {
    margin-bottom: 6.75rem !important;
    margin-top: 6.75rem !important;
  }
  .my-sm-8 {
    margin-bottom: 13.5rem !important;
    margin-top: 13.5rem !important;
  }
  .my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.1875rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.375rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.25rem !important;
  }
  .mt-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 6.75rem !important;
  }
  .mt-sm-8 {
    margin-top: 13.5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.1875rem !important;
  }
  .me-sm-2 {
    margin-right: 0.375rem !important;
  }
  .me-sm-3 {
    margin-right: 0.75rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2.25rem !important;
  }
  .me-sm-6 {
    margin-right: 4.5rem !important;
  }
  .me-sm-7 {
    margin-right: 6.75rem !important;
  }
  .me-sm-8 {
    margin-right: 13.5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.1875rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.25rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 6.75rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 13.5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.1875rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.375rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.25rem !important;
  }
  .ms-sm-6 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 6.75rem !important;
  }
  .ms-sm-8 {
    margin-left: 13.5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.1875rem !important;
  }
  .m-sm-n2 {
    margin: -0.375rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2.25rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6.75rem !important;
  }
  .m-sm-n8 {
    margin: -13.5rem !important;
  }
  .mx-sm-n1 {
    margin-left: -0.1875rem !important;
    margin-right: -0.1875rem !important;
  }
  .mx-sm-n2 {
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }
  .mx-sm-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }
  .mx-sm-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-sm-n7 {
    margin-left: -6.75rem !important;
    margin-right: -6.75rem !important;
  }
  .mx-sm-n8 {
    margin-left: -13.5rem !important;
    margin-right: -13.5rem !important;
  }
  .my-sm-n1 {
    margin-bottom: -0.1875rem !important;
    margin-top: -0.1875rem !important;
  }
  .my-sm-n2 {
    margin-bottom: -0.375rem !important;
    margin-top: -0.375rem !important;
  }
  .my-sm-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-bottom: -2.25rem !important;
    margin-top: -2.25rem !important;
  }
  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-sm-n7 {
    margin-bottom: -6.75rem !important;
    margin-top: -6.75rem !important;
  }
  .my-sm-n8 {
    margin-bottom: -13.5rem !important;
    margin-top: -13.5rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.1875rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.375rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2.25rem !important;
  }
  .mt-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -6.75rem !important;
  }
  .mt-sm-n8 {
    margin-top: -13.5rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.1875rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.375rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2.25rem !important;
  }
  .me-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -6.75rem !important;
  }
  .me-sm-n8 {
    margin-right: -13.5rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.1875rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.375rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2.25rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -6.75rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -13.5rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.1875rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.375rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2.25rem !important;
  }
  .ms-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -6.75rem !important;
  }
  .ms-sm-n8 {
    margin-left: -13.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.1875rem !important;
  }
  .p-sm-2 {
    padding: 0.375rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2.25rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6.75rem !important;
  }
  .p-sm-8 {
    padding: 13.5rem !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-sm-1 {
    padding-left: 0.1875rem !important;
    padding-right: 0.1875rem !important;
  }
  .px-sm-2 {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }
  .px-sm-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-sm-5 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
  .px-sm-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-sm-7 {
    padding-left: 6.75rem !important;
    padding-right: 6.75rem !important;
  }
  .px-sm-8 {
    padding-left: 13.5rem !important;
    padding-right: 13.5rem !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-sm-1 {
    padding-bottom: 0.1875rem !important;
    padding-top: 0.1875rem !important;
  }
  .py-sm-2 {
    padding-bottom: 0.375rem !important;
    padding-top: 0.375rem !important;
  }
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-sm-5 {
    padding-bottom: 2.25rem !important;
    padding-top: 2.25rem !important;
  }
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-sm-7 {
    padding-bottom: 6.75rem !important;
    padding-top: 6.75rem !important;
  }
  .py-sm-8 {
    padding-bottom: 13.5rem !important;
    padding-top: 13.5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.1875rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.375rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2.25rem !important;
  }
  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6.75rem !important;
  }
  .pt-sm-8 {
    padding-top: 13.5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.1875rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.375rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2.25rem !important;
  }
  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6.75rem !important;
  }
  .pe-sm-8 {
    padding-right: 13.5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.1875rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.375rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2.25rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6.75rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 13.5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.1875rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.375rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2.25rem !important;
  }
  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6.75rem !important;
  }
  .ps-sm-8 {
    padding-left: 13.5rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.1875rem !important;
  }
  .gap-md-2 {
    gap: 0.375rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2.25rem !important;
  }
  .gap-md-6 {
    gap: 4.5rem !important;
  }
  .gap-md-7 {
    gap: 6.75rem !important;
  }
  .gap-md-8 {
    gap: 13.5rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.1875rem !important;
  }
  .m-md-2 {
    margin: 0.375rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2.25rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6.75rem !important;
  }
  .m-md-8 {
    margin: 13.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-md-1 {
    margin-left: 0.1875rem !important;
    margin-right: 0.1875rem !important;
  }
  .mx-md-2 {
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }
  .mx-md-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-md-5 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }
  .mx-md-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-md-7 {
    margin-left: 6.75rem !important;
    margin-right: 6.75rem !important;
  }
  .mx-md-8 {
    margin-left: 13.5rem !important;
    margin-right: 13.5rem !important;
  }
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-md-1 {
    margin-bottom: 0.1875rem !important;
    margin-top: 0.1875rem !important;
  }
  .my-md-2 {
    margin-bottom: 0.375rem !important;
    margin-top: 0.375rem !important;
  }
  .my-md-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-md-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-md-5 {
    margin-bottom: 2.25rem !important;
    margin-top: 2.25rem !important;
  }
  .my-md-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-md-7 {
    margin-bottom: 6.75rem !important;
    margin-top: 6.75rem !important;
  }
  .my-md-8 {
    margin-bottom: 13.5rem !important;
    margin-top: 13.5rem !important;
  }
  .my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.1875rem !important;
  }
  .mt-md-2 {
    margin-top: 0.375rem !important;
  }
  .mt-md-3 {
    margin-top: 0.75rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2.25rem !important;
  }
  .mt-md-6 {
    margin-top: 4.5rem !important;
  }
  .mt-md-7 {
    margin-top: 6.75rem !important;
  }
  .mt-md-8 {
    margin-top: 13.5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.1875rem !important;
  }
  .me-md-2 {
    margin-right: 0.375rem !important;
  }
  .me-md-3 {
    margin-right: 0.75rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2.25rem !important;
  }
  .me-md-6 {
    margin-right: 4.5rem !important;
  }
  .me-md-7 {
    margin-right: 6.75rem !important;
  }
  .me-md-8 {
    margin-right: 13.5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.1875rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.25rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 6.75rem !important;
  }
  .mb-md-8 {
    margin-bottom: 13.5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.1875rem !important;
  }
  .ms-md-2 {
    margin-left: 0.375rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2.25rem !important;
  }
  .ms-md-6 {
    margin-left: 4.5rem !important;
  }
  .ms-md-7 {
    margin-left: 6.75rem !important;
  }
  .ms-md-8 {
    margin-left: 13.5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.1875rem !important;
  }
  .m-md-n2 {
    margin: -0.375rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2.25rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6.75rem !important;
  }
  .m-md-n8 {
    margin: -13.5rem !important;
  }
  .mx-md-n1 {
    margin-left: -0.1875rem !important;
    margin-right: -0.1875rem !important;
  }
  .mx-md-n2 {
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }
  .mx-md-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }
  .mx-md-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-md-n7 {
    margin-left: -6.75rem !important;
    margin-right: -6.75rem !important;
  }
  .mx-md-n8 {
    margin-left: -13.5rem !important;
    margin-right: -13.5rem !important;
  }
  .my-md-n1 {
    margin-bottom: -0.1875rem !important;
    margin-top: -0.1875rem !important;
  }
  .my-md-n2 {
    margin-bottom: -0.375rem !important;
    margin-top: -0.375rem !important;
  }
  .my-md-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-md-n5 {
    margin-bottom: -2.25rem !important;
    margin-top: -2.25rem !important;
  }
  .my-md-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-md-n7 {
    margin-bottom: -6.75rem !important;
    margin-top: -6.75rem !important;
  }
  .my-md-n8 {
    margin-bottom: -13.5rem !important;
    margin-top: -13.5rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.1875rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.375rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2.25rem !important;
  }
  .mt-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -6.75rem !important;
  }
  .mt-md-n8 {
    margin-top: -13.5rem !important;
  }
  .me-md-n1 {
    margin-right: -0.1875rem !important;
  }
  .me-md-n2 {
    margin-right: -0.375rem !important;
  }
  .me-md-n3 {
    margin-right: -0.75rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2.25rem !important;
  }
  .me-md-n6 {
    margin-right: -4.5rem !important;
  }
  .me-md-n7 {
    margin-right: -6.75rem !important;
  }
  .me-md-n8 {
    margin-right: -13.5rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.1875rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.375rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2.25rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -6.75rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -13.5rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.1875rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.375rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2.25rem !important;
  }
  .ms-md-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -6.75rem !important;
  }
  .ms-md-n8 {
    margin-left: -13.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.1875rem !important;
  }
  .p-md-2 {
    padding: 0.375rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2.25rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6.75rem !important;
  }
  .p-md-8 {
    padding: 13.5rem !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-md-1 {
    padding-left: 0.1875rem !important;
    padding-right: 0.1875rem !important;
  }
  .px-md-2 {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }
  .px-md-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-md-5 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
  .px-md-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-md-7 {
    padding-left: 6.75rem !important;
    padding-right: 6.75rem !important;
  }
  .px-md-8 {
    padding-left: 13.5rem !important;
    padding-right: 13.5rem !important;
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-md-1 {
    padding-bottom: 0.1875rem !important;
    padding-top: 0.1875rem !important;
  }
  .py-md-2 {
    padding-bottom: 0.375rem !important;
    padding-top: 0.375rem !important;
  }
  .py-md-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-md-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-md-5 {
    padding-bottom: 2.25rem !important;
    padding-top: 2.25rem !important;
  }
  .py-md-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-md-7 {
    padding-bottom: 6.75rem !important;
    padding-top: 6.75rem !important;
  }
  .py-md-8 {
    padding-bottom: 13.5rem !important;
    padding-top: 13.5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.1875rem !important;
  }
  .pt-md-2 {
    padding-top: 0.375rem !important;
  }
  .pt-md-3 {
    padding-top: 0.75rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2.25rem !important;
  }
  .pt-md-6 {
    padding-top: 4.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6.75rem !important;
  }
  .pt-md-8 {
    padding-top: 13.5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.1875rem !important;
  }
  .pe-md-2 {
    padding-right: 0.375rem !important;
  }
  .pe-md-3 {
    padding-right: 0.75rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2.25rem !important;
  }
  .pe-md-6 {
    padding-right: 4.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6.75rem !important;
  }
  .pe-md-8 {
    padding-right: 13.5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.1875rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.375rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2.25rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6.75rem !important;
  }
  .pb-md-8 {
    padding-bottom: 13.5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.1875rem !important;
  }
  .ps-md-2 {
    padding-left: 0.375rem !important;
  }
  .ps-md-3 {
    padding-left: 0.75rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2.25rem !important;
  }
  .ps-md-6 {
    padding-left: 4.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6.75rem !important;
  }
  .ps-md-8 {
    padding-left: 13.5rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.1875rem !important;
  }
  .gap-lg-2 {
    gap: 0.375rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2.25rem !important;
  }
  .gap-lg-6 {
    gap: 4.5rem !important;
  }
  .gap-lg-7 {
    gap: 6.75rem !important;
  }
  .gap-lg-8 {
    gap: 13.5rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.1875rem !important;
  }
  .m-lg-2 {
    margin: 0.375rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2.25rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6.75rem !important;
  }
  .m-lg-8 {
    margin: 13.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-lg-1 {
    margin-left: 0.1875rem !important;
    margin-right: 0.1875rem !important;
  }
  .mx-lg-2 {
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }
  .mx-lg-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }
  .mx-lg-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-lg-7 {
    margin-left: 6.75rem !important;
    margin-right: 6.75rem !important;
  }
  .mx-lg-8 {
    margin-left: 13.5rem !important;
    margin-right: 13.5rem !important;
  }
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-lg-1 {
    margin-bottom: 0.1875rem !important;
    margin-top: 0.1875rem !important;
  }
  .my-lg-2 {
    margin-bottom: 0.375rem !important;
    margin-top: 0.375rem !important;
  }
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-lg-5 {
    margin-bottom: 2.25rem !important;
    margin-top: 2.25rem !important;
  }
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-lg-7 {
    margin-bottom: 6.75rem !important;
    margin-top: 6.75rem !important;
  }
  .my-lg-8 {
    margin-bottom: 13.5rem !important;
    margin-top: 13.5rem !important;
  }
  .my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.1875rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.375rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.25rem !important;
  }
  .mt-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 6.75rem !important;
  }
  .mt-lg-8 {
    margin-top: 13.5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.1875rem !important;
  }
  .me-lg-2 {
    margin-right: 0.375rem !important;
  }
  .me-lg-3 {
    margin-right: 0.75rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2.25rem !important;
  }
  .me-lg-6 {
    margin-right: 4.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6.75rem !important;
  }
  .me-lg-8 {
    margin-right: 13.5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.1875rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.25rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6.75rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 13.5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.1875rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.375rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.25rem !important;
  }
  .ms-lg-6 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6.75rem !important;
  }
  .ms-lg-8 {
    margin-left: 13.5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.1875rem !important;
  }
  .m-lg-n2 {
    margin: -0.375rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2.25rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6.75rem !important;
  }
  .m-lg-n8 {
    margin: -13.5rem !important;
  }
  .mx-lg-n1 {
    margin-left: -0.1875rem !important;
    margin-right: -0.1875rem !important;
  }
  .mx-lg-n2 {
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }
  .mx-lg-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }
  .mx-lg-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-lg-n7 {
    margin-left: -6.75rem !important;
    margin-right: -6.75rem !important;
  }
  .mx-lg-n8 {
    margin-left: -13.5rem !important;
    margin-right: -13.5rem !important;
  }
  .my-lg-n1 {
    margin-bottom: -0.1875rem !important;
    margin-top: -0.1875rem !important;
  }
  .my-lg-n2 {
    margin-bottom: -0.375rem !important;
    margin-top: -0.375rem !important;
  }
  .my-lg-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-bottom: -2.25rem !important;
    margin-top: -2.25rem !important;
  }
  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-lg-n7 {
    margin-bottom: -6.75rem !important;
    margin-top: -6.75rem !important;
  }
  .my-lg-n8 {
    margin-bottom: -13.5rem !important;
    margin-top: -13.5rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.1875rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.375rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2.25rem !important;
  }
  .mt-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -6.75rem !important;
  }
  .mt-lg-n8 {
    margin-top: -13.5rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.1875rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.375rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2.25rem !important;
  }
  .me-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -6.75rem !important;
  }
  .me-lg-n8 {
    margin-right: -13.5rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.1875rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.375rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2.25rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -6.75rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -13.5rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.1875rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.375rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2.25rem !important;
  }
  .ms-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -6.75rem !important;
  }
  .ms-lg-n8 {
    margin-left: -13.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.1875rem !important;
  }
  .p-lg-2 {
    padding: 0.375rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2.25rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6.75rem !important;
  }
  .p-lg-8 {
    padding: 13.5rem !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-lg-1 {
    padding-left: 0.1875rem !important;
    padding-right: 0.1875rem !important;
  }
  .px-lg-2 {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }
  .px-lg-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-lg-5 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
  .px-lg-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-lg-7 {
    padding-left: 6.75rem !important;
    padding-right: 6.75rem !important;
  }
  .px-lg-8 {
    padding-left: 13.5rem !important;
    padding-right: 13.5rem !important;
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-lg-1 {
    padding-bottom: 0.1875rem !important;
    padding-top: 0.1875rem !important;
  }
  .py-lg-2 {
    padding-bottom: 0.375rem !important;
    padding-top: 0.375rem !important;
  }
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-lg-5 {
    padding-bottom: 2.25rem !important;
    padding-top: 2.25rem !important;
  }
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-lg-7 {
    padding-bottom: 6.75rem !important;
    padding-top: 6.75rem !important;
  }
  .py-lg-8 {
    padding-bottom: 13.5rem !important;
    padding-top: 13.5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.1875rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.375rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2.25rem !important;
  }
  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6.75rem !important;
  }
  .pt-lg-8 {
    padding-top: 13.5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.1875rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.375rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2.25rem !important;
  }
  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6.75rem !important;
  }
  .pe-lg-8 {
    padding-right: 13.5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.1875rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.375rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2.25rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6.75rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 13.5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.1875rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.375rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2.25rem !important;
  }
  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6.75rem !important;
  }
  .ps-lg-8 {
    padding-left: 13.5rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.1875rem !important;
  }
  .gap-xl-2 {
    gap: 0.375rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2.25rem !important;
  }
  .gap-xl-6 {
    gap: 4.5rem !important;
  }
  .gap-xl-7 {
    gap: 6.75rem !important;
  }
  .gap-xl-8 {
    gap: 13.5rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.1875rem !important;
  }
  .m-xl-2 {
    margin: 0.375rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2.25rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6.75rem !important;
  }
  .m-xl-8 {
    margin: 13.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xl-1 {
    margin-left: 0.1875rem !important;
    margin-right: 0.1875rem !important;
  }
  .mx-xl-2 {
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }
  .mx-xl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }
  .mx-xl-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xl-7 {
    margin-left: 6.75rem !important;
    margin-right: 6.75rem !important;
  }
  .mx-xl-8 {
    margin-left: 13.5rem !important;
    margin-right: 13.5rem !important;
  }
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xl-1 {
    margin-bottom: 0.1875rem !important;
    margin-top: 0.1875rem !important;
  }
  .my-xl-2 {
    margin-bottom: 0.375rem !important;
    margin-top: 0.375rem !important;
  }
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xl-5 {
    margin-bottom: 2.25rem !important;
    margin-top: 2.25rem !important;
  }
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xl-7 {
    margin-bottom: 6.75rem !important;
    margin-top: 6.75rem !important;
  }
  .my-xl-8 {
    margin-bottom: 13.5rem !important;
    margin-top: 13.5rem !important;
  }
  .my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.1875rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.375rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.25rem !important;
  }
  .mt-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 6.75rem !important;
  }
  .mt-xl-8 {
    margin-top: 13.5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.1875rem !important;
  }
  .me-xl-2 {
    margin-right: 0.375rem !important;
  }
  .me-xl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2.25rem !important;
  }
  .me-xl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xl-7 {
    margin-right: 6.75rem !important;
  }
  .me-xl-8 {
    margin-right: 13.5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.1875rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 6.75rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 13.5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.1875rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.375rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.25rem !important;
  }
  .ms-xl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 6.75rem !important;
  }
  .ms-xl-8 {
    margin-left: 13.5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.1875rem !important;
  }
  .m-xl-n2 {
    margin: -0.375rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2.25rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6.75rem !important;
  }
  .m-xl-n8 {
    margin: -13.5rem !important;
  }
  .mx-xl-n1 {
    margin-left: -0.1875rem !important;
    margin-right: -0.1875rem !important;
  }
  .mx-xl-n2 {
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }
  .mx-xl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }
  .mx-xl-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xl-n7 {
    margin-left: -6.75rem !important;
    margin-right: -6.75rem !important;
  }
  .mx-xl-n8 {
    margin-left: -13.5rem !important;
    margin-right: -13.5rem !important;
  }
  .my-xl-n1 {
    margin-bottom: -0.1875rem !important;
    margin-top: -0.1875rem !important;
  }
  .my-xl-n2 {
    margin-bottom: -0.375rem !important;
    margin-top: -0.375rem !important;
  }
  .my-xl-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-bottom: -2.25rem !important;
    margin-top: -2.25rem !important;
  }
  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xl-n7 {
    margin-bottom: -6.75rem !important;
    margin-top: -6.75rem !important;
  }
  .my-xl-n8 {
    margin-bottom: -13.5rem !important;
    margin-top: -13.5rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.1875rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.375rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2.25rem !important;
  }
  .mt-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -6.75rem !important;
  }
  .mt-xl-n8 {
    margin-top: -13.5rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.1875rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.375rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2.25rem !important;
  }
  .me-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -6.75rem !important;
  }
  .me-xl-n8 {
    margin-right: -13.5rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.1875rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -6.75rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -13.5rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.1875rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.375rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2.25rem !important;
  }
  .ms-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -6.75rem !important;
  }
  .ms-xl-n8 {
    margin-left: -13.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.1875rem !important;
  }
  .p-xl-2 {
    padding: 0.375rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2.25rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6.75rem !important;
  }
  .p-xl-8 {
    padding: 13.5rem !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xl-1 {
    padding-left: 0.1875rem !important;
    padding-right: 0.1875rem !important;
  }
  .px-xl-2 {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }
  .px-xl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xl-5 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
  .px-xl-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xl-7 {
    padding-left: 6.75rem !important;
    padding-right: 6.75rem !important;
  }
  .px-xl-8 {
    padding-left: 13.5rem !important;
    padding-right: 13.5rem !important;
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xl-1 {
    padding-bottom: 0.1875rem !important;
    padding-top: 0.1875rem !important;
  }
  .py-xl-2 {
    padding-bottom: 0.375rem !important;
    padding-top: 0.375rem !important;
  }
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xl-5 {
    padding-bottom: 2.25rem !important;
    padding-top: 2.25rem !important;
  }
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xl-7 {
    padding-bottom: 6.75rem !important;
    padding-top: 6.75rem !important;
  }
  .py-xl-8 {
    padding-bottom: 13.5rem !important;
    padding-top: 13.5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.1875rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.375rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2.25rem !important;
  }
  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6.75rem !important;
  }
  .pt-xl-8 {
    padding-top: 13.5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.1875rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.375rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2.25rem !important;
  }
  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6.75rem !important;
  }
  .pe-xl-8 {
    padding-right: 13.5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.1875rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6.75rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 13.5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.1875rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.375rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2.25rem !important;
  }
  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6.75rem !important;
  }
  .ps-xl-8 {
    padding-left: 13.5rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.1875rem !important;
  }
  .gap-xxl-2 {
    gap: 0.375rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2.25rem !important;
  }
  .gap-xxl-6 {
    gap: 4.5rem !important;
  }
  .gap-xxl-7 {
    gap: 6.75rem !important;
  }
  .gap-xxl-8 {
    gap: 13.5rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.1875rem !important;
  }
  .m-xxl-2 {
    margin: 0.375rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2.25rem !important;
  }
  .m-xxl-6 {
    margin: 4.5rem !important;
  }
  .m-xxl-7 {
    margin: 6.75rem !important;
  }
  .m-xxl-8 {
    margin: 13.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xxl-1 {
    margin-left: 0.1875rem !important;
    margin-right: 0.1875rem !important;
  }
  .mx-xxl-2 {
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }
  .mx-xxl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }
  .mx-xxl-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xxl-7 {
    margin-left: 6.75rem !important;
    margin-right: 6.75rem !important;
  }
  .mx-xxl-8 {
    margin-left: 13.5rem !important;
    margin-right: 13.5rem !important;
  }
  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xxl-1 {
    margin-bottom: 0.1875rem !important;
    margin-top: 0.1875rem !important;
  }
  .my-xxl-2 {
    margin-bottom: 0.375rem !important;
    margin-top: 0.375rem !important;
  }
  .my-xxl-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-bottom: 2.25rem !important;
    margin-top: 2.25rem !important;
  }
  .my-xxl-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xxl-7 {
    margin-bottom: 6.75rem !important;
    margin-top: 6.75rem !important;
  }
  .my-xxl-8 {
    margin-bottom: 13.5rem !important;
    margin-top: 13.5rem !important;
  }
  .my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.1875rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.375rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2.25rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 6.75rem !important;
  }
  .mt-xxl-8 {
    margin-top: 13.5rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.1875rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.375rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2.25rem !important;
  }
  .me-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 6.75rem !important;
  }
  .me-xxl-8 {
    margin-right: 13.5rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.1875rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 6.75rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 13.5rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.1875rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.375rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2.25rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 6.75rem !important;
  }
  .ms-xxl-8 {
    margin-left: 13.5rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.1875rem !important;
  }
  .m-xxl-n2 {
    margin: -0.375rem !important;
  }
  .m-xxl-n3 {
    margin: -0.75rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2.25rem !important;
  }
  .m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .m-xxl-n7 {
    margin: -6.75rem !important;
  }
  .m-xxl-n8 {
    margin: -13.5rem !important;
  }
  .mx-xxl-n1 {
    margin-left: -0.1875rem !important;
    margin-right: -0.1875rem !important;
  }
  .mx-xxl-n2 {
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }
  .mx-xxl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }
  .mx-xxl-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xxl-n7 {
    margin-left: -6.75rem !important;
    margin-right: -6.75rem !important;
  }
  .mx-xxl-n8 {
    margin-left: -13.5rem !important;
    margin-right: -13.5rem !important;
  }
  .my-xxl-n1 {
    margin-bottom: -0.1875rem !important;
    margin-top: -0.1875rem !important;
  }
  .my-xxl-n2 {
    margin-bottom: -0.375rem !important;
    margin-top: -0.375rem !important;
  }
  .my-xxl-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-bottom: -2.25rem !important;
    margin-top: -2.25rem !important;
  }
  .my-xxl-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xxl-n7 {
    margin-bottom: -6.75rem !important;
    margin-top: -6.75rem !important;
  }
  .my-xxl-n8 {
    margin-bottom: -13.5rem !important;
    margin-top: -13.5rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.1875rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.375rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2.25rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -6.75rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -13.5rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.1875rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.375rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2.25rem !important;
  }
  .me-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -6.75rem !important;
  }
  .me-xxl-n8 {
    margin-right: -13.5rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.1875rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -6.75rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -13.5rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.1875rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.375rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2.25rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -6.75rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -13.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.1875rem !important;
  }
  .p-xxl-2 {
    padding: 0.375rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2.25rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6.75rem !important;
  }
  .p-xxl-8 {
    padding: 13.5rem !important;
  }
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xxl-1 {
    padding-left: 0.1875rem !important;
    padding-right: 0.1875rem !important;
  }
  .px-xxl-2 {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }
  .px-xxl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
  .px-xxl-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xxl-7 {
    padding-left: 6.75rem !important;
    padding-right: 6.75rem !important;
  }
  .px-xxl-8 {
    padding-left: 13.5rem !important;
    padding-right: 13.5rem !important;
  }
  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xxl-1 {
    padding-bottom: 0.1875rem !important;
    padding-top: 0.1875rem !important;
  }
  .py-xxl-2 {
    padding-bottom: 0.375rem !important;
    padding-top: 0.375rem !important;
  }
  .py-xxl-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-bottom: 2.25rem !important;
    padding-top: 2.25rem !important;
  }
  .py-xxl-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xxl-7 {
    padding-bottom: 6.75rem !important;
    padding-top: 6.75rem !important;
  }
  .py-xxl-8 {
    padding-bottom: 13.5rem !important;
    padding-top: 13.5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.1875rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.375rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2.25rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6.75rem !important;
  }
  .pt-xxl-8 {
    padding-top: 13.5rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.1875rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.375rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2.25rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6.75rem !important;
  }
  .pe-xxl-8 {
    padding-right: 13.5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.1875rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6.75rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 13.5rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.1875rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.375rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2.25rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6.75rem !important;
  }
  .ps-xxl-8 {
    padding-left: 13.5rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.bg-fixed-bottom {
  background-attachment: fixed;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.navbar-vertical ~ .main-content.bg-fixed-bottom {
  background-size: 100%;
}
@media (min-width: 768px) {
  .navbar-vertical ~ .main-content.bg-fixed-bottom {
    background-size: calc(100% - 250px);
  }
}
.bg-cover {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-ellipses.bg-primary {
  background-image: radial-gradient(#2c7be5, #2c7be5 70%, transparent 70.1%);
}
.bg-ellipses.bg-primary,
.bg-ellipses.bg-secondary {
  background-color: transparent !important;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200% 150%;
}
.bg-ellipses.bg-secondary {
  background-image: radial-gradient(#6e84a3, #6e84a3 70%, transparent 70.1%);
}
.bg-ellipses.bg-success {
  background-image: radial-gradient(#00d97e, #00d97e 70%, transparent 70.1%);
}
.bg-ellipses.bg-info,
.bg-ellipses.bg-success {
  background-color: transparent !important;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200% 150%;
}
.bg-ellipses.bg-info {
  background-image: radial-gradient(#39afd1, #39afd1 70%, transparent 70.1%);
}
.bg-ellipses.bg-warning {
  background-image: radial-gradient(#f6c343, #f6c343 70%, transparent 70.1%);
}
.bg-ellipses.bg-danger,
.bg-ellipses.bg-warning {
  background-color: transparent !important;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200% 150%;
}
.bg-ellipses.bg-danger {
  background-image: radial-gradient(#e63757, #e63757 70%, transparent 70.1%);
}
.bg-ellipses.bg-light {
  background-image: radial-gradient(#edf2f9, #edf2f9 70%, transparent 70.1%);
}
.bg-ellipses.bg-dark,
.bg-ellipses.bg-light {
  background-color: transparent !important;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200% 150%;
}
.bg-ellipses.bg-dark {
  background-image: radial-gradient(#0c2b3b, #0c2b3b 70%, transparent 70.1%);
}
.bg-ellipses.bg-white {
  background-color: transparent !important;
  background-image: radial-gradient(#fff, #fff 70%, transparent 70.1%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200% 150%;
}
.bg-hero {
  background-image: linear-gradient(
      180deg,
      rgba(18, 38, 63, 0.85),
      rgba(18, 38, 63, 0.85)
    ),
    url(./assets/img/covers/header-cover.jpg);
  background-position: 50%, 50%;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
}
.bg-primary-soft {
  background-color: #d5e5fa !important;
}
.bg-secondary-soft {
  background-color: #e2e6ed !important;
}
.bg-success-soft {
  background-color: #ccf7e5 !important;
}
.bg-info-soft {
  background-color: #d7eff6 !important;
}
.bg-warning-soft {
  background-color: #fdf3d9 !important;
}
.bg-danger-soft {
  background-color: #fad7dd !important;
}
.bg-light-soft {
  background-color: #fbfcfe !important;
}
.bg-dark-soft {
  background-color: #d0d4d9 !important;
}
.bg-white-soft {
  background-color: #fff !important;
}
.lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}
.lift:focus,
.lift:hover {
  box-shadow: 0 1rem 2.5rem rgba(18, 38, 63, 0.1),
    0 0.5rem 1rem -0.75rem rgba(18, 38, 63, 0.1) !important;
  transform: translate3d(0, -3px, 0);
}
.lift-lg:focus,
.lift-lg:hover {
  box-shadow: 0 2rem 5rem rgba(18, 38, 63, 0.1),
    0 0.5rem 1rem -0.75rem rgba(18, 38, 63, 0.05) !important;
  transform: translate3d(0, -5px, 0);
}
:root {
  --bs-chart-gray-300: #e3ebf6;
  --bs-chart-gray-600: #95aac9;
  --bs-chart-gray-700: #6e84a3;
  --bs-chart-gray-800: #3b506c;
  --bs-chart-gray-900: #283e59;
  --bs-chart-primary-100: #d2ddec;
  --bs-chart-primary-300: #a6c5f7;
  --bs-chart-primary-700: #2c7be5;
  --bs-chart-default-color: #95aac9;
  --bs-chart-default-font-color: #95aac9;
  --bs-chart-arc-border-color: #fff;
  --bs-chart-arc-hover-border-color: #fff;
  --bs-chart-grid-line-color: #e3ebf6;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
}
ol,
ul {
  padding-left: 2.5rem;
}
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.h1,
h1 {
  font-size: 1.5rem;
  margin-bottom: 1.125rem;
}
@media (min-width: 768px) {
  .h1,
  h1 {
    font-size: 1.625rem;
  }
}
.h2,
h2 {
  margin-bottom: 1.125rem;
}
.h3,
h3 {
  margin-bottom: 0.84375rem;
}
.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-bottom: 0.5625rem;
}
.h1 > a,
.h2 > a,
.h3 > a,
.h4 > a,
.h5 > a,
.h6 > a,
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  color: inherit;
}
.display-1,
.display-2,
.display-3,
.display-4,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.02em;
}
.text-uppercase.h6,
h6.text-uppercase {
  letter-spacing: 0.08em;
}
b,
strong {
  font-weight: 600;
}
a:focus,
button:focus {
  outline: none !important;
}
@font-face {
  font-family: Cerebri Sans;
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/cerebrisans/cerebrisans-regular.eot);
  src: url(./assets/fonts/cerebrisans/cerebrisans-regulard41d.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/cerebrisans/cerebrisans-regular.woff) format("woff"),
    url(./assets/fonts/cerebrisans/cerebrisans-regular.ttf) format("truetype");
}
@font-face {
  font-family: Cerebri Sans;
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/cerebrisans/cerebrisans-medium.eot);
  src: url(./assets/fonts/cerebrisans/cerebrisans-mediumd41d.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/cerebrisans/cerebrisans-medium.woff) format("woff"),
    url(./assets/fonts/cerebrisans/cerebrisans-medium.ttf) format("truetype");
}
@font-face {
  font-family: Cerebri Sans;
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/cerebrisans/cerebrisans-semibold.eot);
  src: url(./assets/fonts/cerebrisans/cerebrisans-semiboldd41d.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/cerebrisans/cerebrisans-semibold.woff) format("woff"),
    url(./assets/fonts/cerebrisans/cerebrisans-semibold.ttf) format("truetype");
}
@font-face {
  font-family: Feather;
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/feather/Feather144f.ttf?sdxovp) format("truetype"),
    url(./assets/fonts/feather/Feather144f.woff?sdxovp) format("woff"),
    url(./assets/fonts/feather/Feather144f.svg?sdxovp#Feather) format("svg");
}
.table thead th {
  background-color: #f9fbfd;
  color: #95aac9;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.table thead th,
tbody td,
tbody th {
  vertical-align: middle;
}
.table td,
.table th {
  border-bottom: 0;
  border-top: 1px solid #edf2f9;
}
.table-sm {
  font-size: 0.8125rem;
}
.table-sm thead th {
  font-size: 0.625rem;
}
.table-nowrap td,
.table-nowrap th,
.table [data-sort] {
  white-space: nowrap;
}
.table [data-sort]:after {
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%2395AAC9'/></svg>");
  margin-left: 0.25rem;
}
.table a[class*="text-reset"]:hover {
  color: #2c7be5 !important;
}
.form-text {
  display: block;
  margin-bottom: 0.5rem;
}
.form-control-sm {
  line-height: 1.75;
}
.form-control-lg {
  line-height: 1.5;
}
.form-control-flush {
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 0;
  resize: none;
}
.form-control:not(.form-select) {
  padding-right: 0;
}
.form-control-auto {
  min-height: 0 !important;
  padding-bottom: 0;
  padding-top: 0;
}
.form-control-rounded {
  border-radius: 20rem;
}
.form-control.h1,
.form-control.h2,
.form-control.h3,
.form-control.h4,
.form-control.h5 {
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}
.form-control.h1 {
  font-size: 1.625rem;
}
.form-control.h2 {
  font-size: 1.25rem;
}
.form-control.h3 {
  font-size: 1.0625rem;
}
.form-control.h4 {
  font-size: 0.9375rem;
}
.form-control.h5 {
  font-size: 0.8125rem;
}
.form-check {
  margin-bottom: 0;
}
.form-check + .form-check {
  margin-top: 0.125rem;
}
.form-check-input {
  cursor: pointer;
  height: 1rem;
  margin-top: calc(0.75em - 0.5rem);
  width: 1rem;
}
.form-check-input:focus:not(:checked) {
  background-color: #cbdef9;
}
.form-check:not(.form-switch):not(.form-check-circle)
  .form-check-input:checked {
  background-size: 75% 75%;
}
.form-switch {
  min-height: 1.5rem;
}
.form-switch > .form-check-input {
  height: 1.5rem;
  margin-top: 0;
}
.form-switch > .form-check-label {
  margin-top: calc(0.75rem - 0.75em);
}
.form-check-circle {
  padding-left: 1.875rem;
}
.form-check-circle > .form-check-input {
  background-size: 0.75rem 0.5625rem;
  border-radius: 50%;
  height: 1.375rem;
  margin-left: -1.875rem;
  width: 1.375rem;
}
.form-check-circle > .form-check-input,
.form-check-circle > .form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.333 1 4 8.333.667 5' stroke='%23fff' stroke-width='1.333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.form-check-circle > .form-check-label {
  margin-top: calc(0.6875rem - 0.5em);
}
.input-group-sm > .form-control {
  line-height: 1.75;
}
.input-group-lg > .form-control {
  line-height: 1.5;
}
.input-group-merge > .form-control {
  border-right-width: 0;
  border-left-width: 0;
}
.input-group-merge > .input-group-text {
  border-left-width: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group-merge > .input-group-text.input-group-prepend {
  border-left-width: unset;
  border-right-width: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* .form-control:focus + .input-group-text {
  border-color: #2BC38C !important;
} */
.form-control:focus + .input-group-text.input-group-prepend {
  border-color: #2bc38c !important;
}
.form-control:focus + .input-group-text.input-group-append {
  border-color: #2bc38c;
}
.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: 0;
}
.input-group-merge.input-group-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.input-group-merge.input-group-reverse > .form-control {
  border-left-width: 0;
  border-radius: 0 0.375rem 0.375rem 0;
  border-right-width: 1px;
  padding-left: 0;
  padding-right: 0.75rem;
}
.input-group-merge.input-group-reverse > .input-group-text {
  border-left-width: 1px;
  border-radius: 0.375rem 0 0 0.375rem;
  border-right-width: 0;
}
.input-group-merge.input-group-reverse:not(.input-group-rounded)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-bottom-right-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.input-group-merge.input-group-reverse:not(.input-group-rounded)
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}
.input-group-sm.input-group-merge.input-group-reverse > .form-control {
  border-radius: 0 0.25rem 0.25rem 0;
  padding-right: 0.5rem;
}
.input-group-sm.input-group-merge.input-group-reverse > .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
}
.input-group-sm.input-group-merge.input-group-reverse:not(.input-group-rounded)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.input-group-sm.input-group-merge.input-group-reverse:not(.input-group-rounded)
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.input-group-lg.input-group-merge.input-group-reverse > .form-control {
  border-radius: 0 0.5rem 0.5rem 0;
  padding-right: 1.25rem;
}
.input-group-lg.input-group-merge.input-group-reverse > .input-group-text {
  border-radius: 0.5rem 0 0 0.5rem;
}
.input-group-lg.input-group-merge.input-group-reverse:not(.input-group-rounded)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.input-group-lg.input-group-merge.input-group-reverse:not(.input-group-rounded)
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
.input-group-rounded.input-group-merge > .form-control {
  border-bottom-left-radius: 50rem;
  border-top-left-radius: 50rem;
}
.input-group-rounded.input-group-merge > .input-group-text {
  border-bottom-right-radius: 50rem;
  border-top-right-radius: 50rem;
}
.input-group-rounded.input-group-merge.input-group-reverse > .form-control {
  border-radius: 0 50rem 50rem 0 !important;
}
.input-group-rounded.input-group-merge.input-group-reverse > .input-group-text {
  border-radius: 50rem 0 0 50rem !important;
}
.input-group-flush.input-group-merge > .form-control,
.input-group-flush.input-group-merge > .input-group-text {
  background-color: transparent;
  border-color: transparent;
}
.input-group-flush.input-group-merge > .form-control {
  padding-left: 0;
  padding-right: 0;
}
.input-group-flush.input-group-merge > .form-control:focus + .input-group-text {
  border-color: transparent;
}
.input-group-flush.input-group-merge:not(.input-group-reverse)
  > .input-group-text {
  padding-right: 0;
}
.input-group-flush.input-group-merge.input-group-reverse > .input-group-text {
  padding-left: 0;
}
.input-group-merge > .form-control.is-valid + .input-group-text {
  border-color: #00d97e;
}
.input-group-merge > .form-control.is-invalid + .input-group-text {
  border-color: #e63757;
}
.form-control.is-invalid:focus,
.form-control.is-valid:focus {
  box-shadow: none;
}
.form-group {
  margin-bottom: 1.375rem;
}
.btn-group-lg > .btn,
.btn-lg {
  line-height: 1.5;
}
.btn-group-sm > .btn,
.btn-sm {
  line-height: 1.75;
}
.btn-white {
  border-color: #e3ebf6;
}
.btn-white:focus,
.btn-white:hover {
  background-color: #f9fbfd;
  border-color: #d2ddec;
}
.btn-group-toggle .btn-check:checked + .btn-white,
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active,
.btn-group-toggle .btn-white:not(:disabled):not(.disabled):active {
  background-color: #fff;
  border-color: #2c7be5;
  box-shadow: none;
  color: #2c7be5;
}
.btn-group-toggle .btn-white.focus,
.btn-group-toggle .btn-white:focus {
  box-shadow: none;
}
.btn-white-20 {
  background-color: hsla(0, 0%, 100%, 0.2);
  border-color: transparent;
  color: #fff;
}
.btn-white-20:focus,
.btn-white-20:hover {
  background-color: hsla(0, 0%, 100%, 0.12);
  border-color: transparent;
  color: #fff;
}
.btn-outline-secondary:not(:hover):not([aria-expanded="true"]):not(
    [aria-pressed="true"]
  ) {
  border-color: #d2ddec;
}
.btn-rounded-circle {
  border-radius: 50%;
  padding-left: 0;
  padding-right: 0;
  width: calc(1.5em + 1rem + 2px);
}
.btn-pagination {
  border-radius: 6px;
  padding-left: 0;
  padding-right: 0;
  width: 1.7rem;
  height: 1.7rem;
  /* width: calc(1.5em + 1rem); */
}
.btn-group-lg > .btn-rounded-circle.btn,
.btn-rounded-circle.btn-lg {
  width: calc(1.5em + 1.5rem + 2px);
}
.btn-group-sm > .btn-rounded-circle.btn,
.btn-rounded-circle.btn-sm {
  width: calc(1.75em + 0.25rem + 2px);
}
.dropdown-toggle:after {
  border: none !important;
  /* content: "\e92e"; */
  font-family: Feather;
  height: auto;
  vertical-align: middle;
  width: auto;
  display: none !important;
}
.dropup > .dropdown-toggle:after {
  content: "\e931";
}
.dropend > .dropdown-toggle:after {
  content: "\e930";
}
.dropleft > .dropdown-toggle:before {
  border: none !important;
  content: "\e92f";
  font-family: Feather;
  height: auto;
  vertical-align: middle;
  width: auto;
}
.dropdown-item.dropdown-toggle {
  display: flex;
  justify-content: space-between;
}
.dropdown-menu {
  -webkit-animation: dropdownMenu 0.15s;
  animation: dropdownMenu 0.15s;
}
@-webkit-keyframes dropdownMenu {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes dropdownMenu {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dropdown-ellipses {
  color: #d2ddec;
  font-size: 1.0625rem;
}
.dropdown-ellipses:after {
  display: none;
}
.dropdown-menu-card {
  background-color: #fff;
  border-color: rgba(18, 38, 63, 0.1);
  min-width: 350px;
  padding-bottom: 0;
  padding-top: 0;
}
.dropdown-menu-card .card-header {
  min-height: 3.125rem;
}
.dropdown-menu-card .card-body {
  max-height: 350px;
  overflow-y: auto;
}
.dropdown-menu-sm {
  font-size: 0.8125rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.dropdown-menu-sm .dropdown-item {
  padding: 0.1875rem 0.75rem;
}
.nav-tabs .nav-item {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.nav-tabs .nav-link {
  border-bottom: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  padding: 1rem 0;
}
.nav-tabs .nav-link:not(.active) {
  color: #95aac9;
}
.nav-tabs .nav-link:not(.active):hover {
  color: #6e84a3;
}
.nav-tabs .nav-item:first-child {
  margin-left: 0;
}
.nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.nav-tabs .nav-item.show .nav-link {
  border-color: transparent;
}
.nav-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
}
.nav-overflow::-webkit-scrollbar {
  display: none;
}
.nav-tabs-sm {
  font-size: 0.8125rem;
}
.nav-tabs-sm .nav-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.nav-tabs-sm .nav-link {
  padding-bottom: 1.15385rem;
  padding-top: 1.15385rem;
}
.nav-sm .nav-link {
  font-size: 0.8125rem;
}
.nav.btn-group .btn-white.active {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}
.navbar {
  border-style: solid;
  border-width: 0 0 1px;
}
.navbar > [class*="container"] {
  padding-left: 0;
  padding-right: 0;
}
.navbar-dark {
  background-color: #152e4d;
  border-color: #152e4d;
}
.navbar-dark .navbar-heading {
  color: #6e84a3;
}
.navbar-dark .navbar-divider {
  background-color: #1e3a5c;
}
.navbar-dark .navbar-user {
  border-top-color: #1e3a5c;
}
.navbar-dark .navbar-user-link {
  color: #6e84a3;
}
.navbar-dark .navbar-user-link:focus,
.navbar-dark .navbar-user-link:hover {
  color: #fff;
}
.navbar-dark .navbar-brand {
  filter: none;
}
.navbar-dark .navbar-collapse:before {
  border-top-color: #1e3a5c;
}
.navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item {
  color: #6e84a3;
}
.navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item.active,
.navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item:active,
.navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item:focus,
.navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item:hover,
.navbar-dark.navbar-expand-xs .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item {
  color: #6e84a3;
}
.navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item.active,
.navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item:active,
.navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item:focus,
.navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item:hover,
.navbar-dark.navbar-expand-sm .navbar-nav .show > .nav-link {
  color: #fff;
}
@media (max-width: 575.98px) {
  .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item {
    color: #6e84a3;
  }
  .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item.active,
  .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item:active,
  .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item:focus,
  .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item:hover,
  .navbar-dark.navbar-expand-md .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
@media (max-width: 767.98px) {
  .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item {
    color: #6e84a3;
  }
  .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item.active,
  .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item:active,
  .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item:focus,
  .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item:hover,
  .navbar-dark.navbar-expand-lg .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
@media (max-width: 991.98px) {
  .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item {
    color: #6e84a3;
  }
  .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item.active,
  .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item:active,
  .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item:focus,
  .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item:hover,
  .navbar-dark.navbar-expand-xl .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
@media (max-width: 1199.98px) {
  .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item {
    color: #6e84a3;
  }
  .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item.active,
  .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item:active,
  .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item:focus,
  .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item:hover,
  .navbar-dark.navbar-expand-xxl .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
.navbar-dark.navbar-vibrant {
  background-image: linear-gradient(
      to bottom right,
      rgba(18, 111, 235, 0.9),
      rgba(114, 3, 225, 0.9)
    ),
    url(./assets/img/covers/sidebar-cover.jpg);
  background-origin: border-box;
  background-position: 50%, 50%;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  border-color: transparent;
}
@media (max-width: 575.98px) {
  .navbar-dark.navbar-vibrant {
    background-attachment: fixed, fixed;
  }
}
.navbar-dark.navbar-vibrant .nav-link {
  color: hsla(0, 0%, 100%, 0.7);
}
.navbar-dark.navbar-vibrant .nav-item.active .nav-link,
.navbar-dark.navbar-vibrant .nav-item .nav-link.active,
.navbar-dark.navbar-vibrant .nav-link:focus,
.navbar-dark.navbar-vibrant .nav-link:hover {
  color: #fff;
}
.navbar-dark.navbar-vibrant .navbar-heading {
  color: hsla(0, 0%, 100%, 0.4);
}
.navbar-dark.navbar-vibrant .navbar-divider {
  background-color: hsla(0, 0%, 100%, 0.2);
}
.navbar-dark.navbar-vibrant .navbar-user {
  border-top-color: hsla(0, 0%, 100%, 0.2);
}
.navbar-dark.navbar-vibrant .navbar-user-link {
  color: hsla(0, 0%, 100%, 0.7);
}
.navbar-dark.navbar-vibrant .navbar-user-link:focus,
.navbar-dark.navbar-vibrant .navbar-user-link:hover {
  color: #fff;
}
.navbar-dark.navbar-vibrant .navbar-brand {
  filter: brightness(0) invert(1);
}
.navbar-dark.navbar-vibrant .navbar-collapse:before {
  border-top-color: hsla(0, 0%, 100%, 0.2);
}
.navbar-dark.navbar-vibrant .navbar-nav .nav-link.active:before {
  border-color: #fff;
}
.navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item {
  color: hsla(0, 0%, 100%, 0.7);
}
.navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item.active,
.navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item:active,
.navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item:focus,
.navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item:hover,
.navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item {
  color: hsla(0, 0%, 100%, 0.7);
}
.navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item.active,
.navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item:active,
.navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item:focus,
.navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item:hover,
.navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .show > .nav-link {
  color: #fff;
}
@media (max-width: 575.98px) {
  .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .dropdown-item {
    color: hsla(0, 0%, 100%, 0.7);
  }
  .navbar-dark.navbar-vibrant.navbar-expand-md
    .navbar-nav
    .dropdown-item.active,
  .navbar-dark.navbar-vibrant.navbar-expand-md
    .navbar-nav
    .dropdown-item:active,
  .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .dropdown-item:focus,
  .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .dropdown-item:hover,
  .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
@media (max-width: 767.98px) {
  .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .dropdown-item {
    color: hsla(0, 0%, 100%, 0.7);
  }
  .navbar-dark.navbar-vibrant.navbar-expand-lg
    .navbar-nav
    .dropdown-item.active,
  .navbar-dark.navbar-vibrant.navbar-expand-lg
    .navbar-nav
    .dropdown-item:active,
  .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .dropdown-item:focus,
  .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .dropdown-item:hover,
  .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
@media (max-width: 991.98px) {
  .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .dropdown-item {
    color: hsla(0, 0%, 100%, 0.7);
  }
  .navbar-dark.navbar-vibrant.navbar-expand-xl
    .navbar-nav
    .dropdown-item.active,
  .navbar-dark.navbar-vibrant.navbar-expand-xl
    .navbar-nav
    .dropdown-item:active,
  .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .dropdown-item:focus,
  .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .dropdown-item:hover,
  .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
@media (max-width: 1199.98px) {
  .navbar-dark.navbar-vibrant.navbar-expand-xxl .navbar-nav .dropdown-item {
    color: hsla(0, 0%, 100%, 0.7);
  }
  .navbar-dark.navbar-vibrant.navbar-expand-xxl
    .navbar-nav
    .dropdown-item.active,
  .navbar-dark.navbar-vibrant.navbar-expand-xxl
    .navbar-nav
    .dropdown-item:active,
  .navbar-dark.navbar-vibrant.navbar-expand-xxl
    .navbar-nav
    .dropdown-item:focus,
  .navbar-dark.navbar-vibrant.navbar-expand-xxl
    .navbar-nav
    .dropdown-item:hover,
  .navbar-dark.navbar-vibrant.navbar-expand-xxl .navbar-nav .show > .nav-link {
    color: #fff;
  }
}
.navbar-dark.navbar-vibrant .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light {
  background-color: #fff;
  border-color: #e3ebf6;
}
.navbar-light .navbar-heading {
  color: #95aac9;
}
.navbar-light .navbar-divider {
  background-color: #e3ebf6;
}
.navbar-light .navbar-user {
  border-top-color: #e3ebf6;
}
.navbar-light .navbar-user-link {
  color: #6e84a3;
}
.navbar-light .navbar-user-link:focus,
.navbar-light .navbar-user-link:hover {
  color: #0c2b3b;
}
.navbar-light .navbar-brand {
  filter: none;
}
.navbar-light .navbar-collapse:before {
  border-top-color: #e3ebf6;
}
.navbar-brand {
  margin-right: 0;
}
.navbar-brand-img,
.navbar-brand > img {
  max-height: 1.5rem;
  max-width: 100%;
}
.navbar-collapse {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% + 2rem);
}
.navbar-collapse:before {
  border-top-style: solid;
  border-top-width: 1px;
  content: "";
  display: block;
  margin: 0.75rem -1rem;
}
.navbar-nav {
  margin-left: -1rem;
  margin-right: -1rem;
}
.navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar-nav .nav-link.active {
  position: relative;
}

.navbar-nav .nav-link > .fe {
  font-size: 1.0625rem;
  line-height: 1.40625rem;
  padding-bottom: 0.125em;
}
.navbar-nav .dropdown-menu {
  border: none;
}
.navbar-nav .dropdown-menu .dropdown-menu {
  margin-left: 0.75rem;
}
.navbar-expand-sm .navbar-nav .dropdown-menu,
.navbar-expand-xs .navbar-nav .dropdown-menu {
  background-color: transparent;
}
@media (max-width: 575.98px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    background-color: transparent;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    background-color: transparent;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    background-color: transparent;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    background-color: transparent;
  }
}
.navbar-expand-xs,
.navbar-expand-xs > [class*="container"] {
  padding-left: 0;
  padding-right: 0;
}
.navbar-expand-xs .navbar-collapse {
  flex-basis: auto;
  flex-grow: inherit;
  width: auto;
}
.navbar-expand-xs .navbar-collapse:before {
  display: none;
}
.navbar-expand-xs .navbar-nav {
  margin-left: 0;
  margin-right: 0;
}
.navbar-expand-xs .navbar-nav .nav-link {
  padding: 0.625rem 0.5rem;
}
.navbar-expand-xs .navbar-nav .nav-link.active {
  position: relative;
}

.navbar-expand-xs .navbar-nav .dropdown-menu {
  border: 1px solid rgba(18, 38, 63, 0.1);
}
.navbar-expand-xs .navbar-nav .dropdown-menu .dropdown-menu {
  margin-left: 0;
}
@media (min-width: 576px) {
  .navbar-expand-sm,
  .navbar-expand-sm > [class*="container"] {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    flex-grow: inherit;
    width: auto;
  }
  .navbar-expand-sm .navbar-collapse:before {
    display: none;
  }
  .navbar-expand-sm .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding: 0.625rem 0.5rem;
  }
  .navbar-expand-sm .navbar-nav .nav-link.active {
    position: relative;
  }
  .navbar-expand-sm .navbar-nav .nav-link.active:before {
    border-bottom: 1px solid #2c7be5;
    border-left: 0;
    bottom: calc(-0.75rem - 1px);
    left: 0.5rem;
    right: 0.5rem;
    top: auto;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    border: 1px solid rgba(18, 38, 63, 0.1);
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md,
  .navbar-expand-md > [class*="container"] {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    flex-grow: inherit;
    width: auto;
  }
  .navbar-expand-md .navbar-collapse:before {
    display: none;
  }
  .navbar-expand-md .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0.625rem 0.5rem;
  }
  .navbar-expand-md .navbar-nav .nav-link.active {
    position: relative;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    border: 1px solid rgba(18, 38, 63, 0.1);
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg,
  .navbar-expand-lg > [class*="container"] {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    flex-grow: inherit;
    width: auto;
  }
  .navbar-expand-lg .navbar-collapse:before {
    display: none;
  }
  .navbar-expand-lg .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.625rem 0.5rem;
  }
  .navbar-expand-lg .navbar-nav .nav-link.active {
    position: relative;
  }
  .navbar-expand-lg .navbar-nav .nav-link.active:before {
    border-bottom: 1px solid #2c7be5;
    border-left: 0;
    bottom: calc(-0.75rem - 1px);
    left: 0.5rem;
    right: 0.5rem;
    top: auto;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border: 1px solid rgba(18, 38, 63, 0.1);
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl,
  .navbar-expand-xl > [class*="container"] {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    flex-grow: inherit;
    width: auto;
  }
  .navbar-expand-xl .navbar-collapse:before {
    display: none;
  }
  .navbar-expand-xl .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: 0.625rem 0.5rem;
  }
  .navbar-expand-xl .navbar-nav .nav-link.active {
    position: relative;
  }
  .navbar-expand-xl .navbar-nav .nav-link.active:before {
    border-bottom: 1px solid #2c7be5;
    border-left: 0;
    bottom: calc(-0.75rem - 1px);
    left: 0.5rem;
    right: 0.5rem;
    top: auto;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    border: 1px solid rgba(18, 38, 63, 0.1);
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl,
  .navbar-expand-xxl > [class*="container"] {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    flex-grow: inherit;
    width: auto;
  }
  .navbar-expand-xxl .navbar-collapse:before {
    display: none;
  }
  .navbar-expand-xxl .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding: 0.625rem 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav .nav-link.active {
    position: relative;
  }
  .navbar-expand-xxl .navbar-nav .nav-link.active:before {
    border-bottom: 1px solid #2c7be5;
    border-left: 0;
    bottom: calc(-0.75rem - 1px);
    left: 0.5rem;
    right: 0.5rem;
    top: auto;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    border: 1px solid rgba(18, 38, 63, 0.1);
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0;
  }
}
.navbar[class*="fixed-"] {
  z-index: 1030;
}
.navbar-overflow {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: -0.75rem;
  overflow-x: auto;
}
.navbar-overflow::-webkit-scrollbar {
  display: none;
}
.navbar-overflow .navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.navbar-overflow .navbar-nav .nav-link {
  padding-bottom: 1.25rem;
}
.navbar-overflow .navbar-nav .nav-link.active:before {
  bottom: 0;
}
.navbar-breadcrumb {
  padding: 0.5rem 0;
}
.navbar-user {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.navbar-user .dropdown-toggle:after {
  display: none;
}
.navbar-nav .nav-link > .fe {
  display: inline-block;
  min-width: 1.75rem;
}
.navbar-vertical .navbar-nav .nav-link {
  align-items: center;
  display: flex;
}
.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  content: "\e92e";
  display: block;
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
}
.navbar-vertical
  .navbar-nav
  .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
  transform: rotate(-180deg);
}
.navbar-vertical .navbar-nav .nav .nav-link {
  padding-left: 2.75rem;
}
.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 3.25rem;
}
.navbar-vertical .navbar-nav .nav .nav .nav .nav-link {
  padding-left: 3.75rem;
}
.navbar-vertical .navbar-heading {
  font-size: 0.625rem;
  letter-spacing: 0.04em;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-transform: uppercase;
}
.navbar-vertical.navbar-expand-xs {
  bottom: 0;
  display: block;
  max-width: 250px;
  overflow-y: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
}
.navbar-vertical.navbar-expand-xs > [class*="container"] {
  align-items: stretch;
  flex-direction: column;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-xs > [class*="container"] {
    height: 100%;
    min-height: none;
  }
}
.navbar-vertical.navbar-expand-xs.fixed-start {
  border-width: 0 1px 0 0;
  left: 0;
}
.navbar-vertical.navbar-expand-xs.fixed-end {
  border-width: 0 0 0 1px;
  right: 0;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse {
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  min-width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: auto;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse > * {
  min-width: 100%;
}
.navbar-vertical.navbar-expand-xs .navbar-nav {
  flex-direction: column;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding: 0.5rem 1.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link.active:before {
  border-bottom: 0;
  border-left: 2px solid #2c7be5;
  bottom: 0.5rem;
  left: 0;
  right: auto;
  top: 0.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link {
  padding-left: 3.25rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav .nav-link {
  padding-left: 4rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav .nav .nav-link {
  padding-left: 4.75rem;
}
.navbar-vertical.navbar-expand-xs .navbar-brand {
  display: block;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  text-align: center;
}
.navbar-vertical.navbar-expand-xs .navbar-brand-img {
  max-height: 3rem;
}
.navbar-vertical.navbar-expand-xs .navbar-user {
  border-top-style: solid;
  border-top-width: 1px;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 1.5rem 1.5rem 0.75rem;
}
.navbar-vertical.navbar-expand-xs .navbar-user .dropup .dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    bottom: 0;
    display: block;
    max-width: 250px;
    overflow-y: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .navbar-vertical.navbar-expand-sm > [class*="container"] {
    align-items: stretch;
    flex-direction: column;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 576px) and (-ms-high-contrast: active),
  (min-width: 576px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-sm > [class*="container"] {
    height: 100%;
    min-height: none;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.fixed-start {
    border-width: 0 1px 0 0;
    left: 0;
  }
  .navbar-vertical.navbar-expand-sm.fixed-end {
    border-width: 0 0 0 1px;
    right: 0;
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    min-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
    padding: 0.5rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link.active:before {
    border-bottom: 0;
    border-left: 2px solid #2c7be5;
    bottom: 0.5rem;
    left: 0;
    right: auto;
    top: 0.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav-link {
    padding-left: 3.25rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-brand {
    display: block;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-sm .navbar-brand-img {
    max-height: 3rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-user {
    border-top-style: solid;
    border-top-width: 1px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem 0.75rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    bottom: 0;
    display: block;
    max-width: 250px;
    overflow-y: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .navbar-vertical.navbar-expand-md > [class*="container"] {
    align-items: stretch;
    flex-direction: column;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) and (-ms-high-contrast: active),
  (min-width: 768px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-md > [class*="container"] {
    height: 100%;
    min-height: none;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-start {
    border-width: 0 1px 0 0;
    left: 0;
  }
  .navbar-vertical.navbar-expand-md.fixed-end {
    border-width: 0 0 0 1px;
    right: 0;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    min-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
    padding: 0.5rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
    border-bottom: 0;
    border-left: 2px solid #2c7be5;
    bottom: 0.5rem;
    left: 0;
    right: auto;
    top: 0.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav-link {
    padding-left: 3.25rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-brand {
    display: block;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 3rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-user {
    border-top-style: solid;
    border-top-width: 1px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem 0.75rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    bottom: 0;
    display: block;
    max-width: 250px;
    overflow-y: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .navbar-vertical.navbar-expand-lg > [class*="container"] {
    align-items: stretch;
    flex-direction: column;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 992px) and (-ms-high-contrast: active),
  (min-width: 992px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-lg > [class*="container"] {
    height: 100%;
    min-height: none;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.fixed-start {
    border-width: 0 1px 0 0;
    left: 0;
  }
  .navbar-vertical.navbar-expand-lg.fixed-end {
    border-width: 0 0 0 1px;
    right: 0;
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    min-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.5rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active:before {
    border-bottom: 0;
    border-left: 2px solid #2c7be5;
    bottom: 0.5rem;
    left: 0;
    right: auto;
    top: 0.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link {
    padding-left: 3.25rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-brand {
    display: block;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-lg .navbar-brand-img {
    max-height: 3rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-user {
    border-top-style: solid;
    border-top-width: 1px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem 0.75rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    bottom: 0;
    display: block;
    max-width: 250px;
    overflow-y: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .navbar-vertical.navbar-expand-xl > [class*="container"] {
    align-items: stretch;
    flex-direction: column;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) and (-ms-high-contrast: active),
  (min-width: 1200px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-xl > [class*="container"] {
    height: 100%;
    min-height: none;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.fixed-start {
    border-width: 0 1px 0 0;
    left: 0;
  }
  .navbar-vertical.navbar-expand-xl.fixed-end {
    border-width: 0 0 0 1px;
    right: 0;
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    min-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
    padding: 0.5rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link.active:before {
    border-bottom: 0;
    border-left: 2px solid #2c7be5;
    bottom: 0.5rem;
    left: 0;
    right: auto;
    top: 0.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav-link {
    padding-left: 3.25rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-brand {
    display: block;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-xl .navbar-brand-img {
    max-height: 3rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-user {
    border-top-style: solid;
    border-top-width: 1px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem 0.75rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    bottom: 0;
    display: block;
    max-width: 250px;
    overflow-y: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .navbar-vertical.navbar-expand-xxl > [class*="container"] {
    align-items: stretch;
    flex-direction: column;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1400px) and (-ms-high-contrast: active),
  (min-width: 1400px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-xxl > [class*="container"] {
    height: 100%;
    min-height: none;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.fixed-start {
    border-width: 0 1px 0 0;
    left: 0;
  }
  .navbar-vertical.navbar-expand-xxl.fixed-end {
    border-width: 0 0 0 1px;
    right: 0;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    min-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link {
    padding: 0.5rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link.active:before {
    border-bottom: 0;
    border-left: 2px solid #2c7be5;
    bottom: 0.5rem;
    left: 0;
    right: auto;
    top: 0.5rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav-link {
    padding-left: 3.25rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    display: block;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-brand-img {
    max-height: 3rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-user {
    border-top-style: solid;
    border-top-width: 1px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem 0.75rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
.navbar-vertical-sm.navbar-expand-xs {
  max-width: 66px;
  overflow: visible;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.navbar-vertical-sm.navbar-expand-xs .navbar-brand {
  padding-bottom: 1.20312rem;
  padding-top: 0.45312rem;
}
.navbar-vertical-sm.navbar-expand-xs .navbar-brand-img {
  max-height: 1.5rem;
  width: auto;
}
.navbar-vertical-sm.navbar-expand-xs .navbar-collapse {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.navbar-vertical-sm.navbar-expand-xs .navbar-nav {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.navbar-vertical-sm.navbar-expand-xs .navbar-nav > .nav-item > .nav-link {
  justify-content: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: center;
}
.navbar-vertical-sm.navbar-expand-xs
  .navbar-nav
  > .nav-item
  > .dropdown-toggle:after {
  display: none;
}
.navbar-vertical-sm.navbar-expand-xs .navbar-user {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.navbar-vertical-sm.navbar-expand-xs .navbar-user .dropend .dropdown-menu {
  bottom: 0;
  top: auto;
}
@media (min-width: 576px) {
  .navbar-vertical-sm.navbar-expand-sm {
    max-width: 66px;
    overflow: visible;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-brand {
    padding-bottom: 1.20312rem;
    padding-top: 0.45312rem;
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-brand-img {
    max-height: 1.5rem;
    width: auto;
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-collapse {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-nav {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    text-align: center;
  }
  .navbar-vertical-sm.navbar-expand-sm
    .navbar-nav
    > .nav-item
    > .dropdown-toggle:after {
    display: none;
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-user {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-user .dropend .dropdown-menu {
    bottom: 0;
    top: auto;
  }
}
@media (min-width: 768px) {
  .navbar-vertical-sm.navbar-expand-md {
    max-width: 66px;
    overflow: visible;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-brand {
    padding-bottom: 1.20312rem;
    padding-top: 0.45312rem;
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-brand-img {
    max-height: 1.5rem;
    width: auto;
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-collapse {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-nav {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    text-align: center;
  }
  .navbar-vertical-sm.navbar-expand-md
    .navbar-nav
    > .nav-item
    > .dropdown-toggle:after {
    display: none;
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-user {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-user .dropend .dropdown-menu {
    bottom: 0;
    top: auto;
  }
}
@media (min-width: 992px) {
  .navbar-vertical-sm.navbar-expand-lg {
    max-width: 66px;
    overflow: visible;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-brand {
    padding-bottom: 1.20312rem;
    padding-top: 0.45312rem;
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-brand-img {
    max-height: 1.5rem;
    width: auto;
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-collapse {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-nav {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    text-align: center;
  }
  .navbar-vertical-sm.navbar-expand-lg
    .navbar-nav
    > .nav-item
    > .dropdown-toggle:after {
    display: none;
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-user {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-user .dropend .dropdown-menu {
    bottom: 0;
    top: auto;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical-sm.navbar-expand-xl {
    max-width: 66px;
    overflow: visible;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-brand {
    padding-bottom: 1.20312rem;
    padding-top: 0.45312rem;
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-brand-img {
    max-height: 1.5rem;
    width: auto;
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-collapse {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-nav {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    text-align: center;
  }
  .navbar-vertical-sm.navbar-expand-xl
    .navbar-nav
    > .nav-item
    > .dropdown-toggle:after {
    display: none;
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-user {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-user .dropend .dropdown-menu {
    bottom: 0;
    top: auto;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical-sm.navbar-expand-xxl {
    max-width: 66px;
    overflow: visible;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-brand {
    padding-bottom: 1.20312rem;
    padding-top: 0.45312rem;
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-brand-img {
    max-height: 1.5rem;
    width: auto;
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-collapse {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-nav {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    text-align: center;
  }
  .navbar-vertical-sm.navbar-expand-xxl
    .navbar-nav
    > .nav-item
    > .dropdown-toggle:after {
    display: none;
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-user {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-user .dropend .dropdown-menu {
    bottom: 0;
    top: auto;
  }
}
.card {
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  margin-bottom: 1.5rem;
}
.card > * {
  flex-shrink: 0;
}
.card-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.card-header > :first-child {
  flex: 1;
}
.card-header + .card-footer {
  border-top-width: 0;
}
.card-header-title {
  margin-bottom: 0;
  font-weight: 300;
}
.fl-sm {
  position: relative;
  top: -6px;
  font-style: italic;
}
.card-header-tabs {
  margin: -0.75rem 0;
}
.card-header-tabs .nav-link {
  padding-bottom: calc(30px - 0.75em) !important;
  padding-top: calc(30px - 0.75em) !important;
}
.card-header-tabs .nav-link.active {
  border-bottom-color: #2c7be5;
}
.card-table {
  margin-bottom: 0;
}
.card-table thead th {
  border-top-width: 0;
}
.card-table tbody td:first-child,
.card-table thead th:first-child {
  padding-left: 1.5rem !important;
}
.card-table tbody td:last-child,
.card-table thead th:last-child {
  padding-right: 1.5rem !important;
}
.card
  > .card-table:first-child
  > tbody:first-child
  > tr:first-child
  > td:first-child,
.card
  > .card-table:first-child
  > tbody:first-child
  > tr:first-child
  > th:first-child,
.card
  > .card-table:first-child
  > tfoot:first-child
  > tr:first-child
  > td:first-child,
.card
  > .card-table:first-child
  > tfoot:first-child
  > tr:first-child
  > th:first-child,
.card
  > .card-table:first-child
  > thead:first-child
  > tr:first-child
  > td:first-child,
.card
  > .card-table:first-child
  > thead:first-child
  > tr:first-child
  > th:first-child,
.card
  > .table-responsive:first-child
  > .card-table
  > tbody:first-child
  > tr:first-child
  > td:first-child,
.card
  > .table-responsive:first-child
  > .card-table
  > tbody:first-child
  > tr:first-child
  > th:first-child,
.card
  > .table-responsive:first-child
  > .card-table
  > tfoot:first-child
  > tr:first-child
  > td:first-child,
.card
  > .table-responsive:first-child
  > .card-table
  > tfoot:first-child
  > tr:first-child
  > th:first-child,
.card
  > .table-responsive:first-child
  > .card-table
  > thead:first-child
  > tr:first-child
  > td:first-child,
.card
  > .table-responsive:first-child
  > .card-table
  > thead:first-child
  > tr:first-child
  > th:first-child {
  border-top-left-radius: 0.5rem;
}
.card
  > .card-table:first-child
  > tbody:first-child
  > tr:first-child
  > td:last-child,
.card
  > .card-table:first-child
  > tbody:first-child
  > tr:first-child
  > th:last-child,
.card
  > .card-table:first-child
  > tfoot:first-child
  > tr:first-child
  > td:last-child,
.card
  > .card-table:first-child
  > tfoot:first-child
  > tr:first-child
  > th:last-child,
.card
  > .card-table:first-child
  > thead:first-child
  > tr:first-child
  > td:last-child,
.card
  > .card-table:first-child
  > thead:first-child
  > tr:first-child
  > th:last-child,
.card
  > .table-responsive:first-child
  > .card-table
  > tbody:first-child
  > tr:first-child
  > td:last-child,
.card
  > .table-responsive:first-child
  > .card-table
  > tbody:first-child
  > tr:first-child
  > th:last-child,
.card
  > .table-responsive:first-child
  > .card-table
  > tfoot:first-child
  > tr:first-child
  > td:last-child,
.card
  > .table-responsive:first-child
  > .card-table
  > tfoot:first-child
  > tr:first-child
  > th:last-child,
.card
  > .table-responsive:first-child
  > .card-table
  > thead:first-child
  > tr:first-child
  > td:last-child,
.card
  > .table-responsive:first-child
  > .card-table
  > thead:first-child
  > tr:first-child
  > th:last-child {
  border-top-right-radius: 0.5rem;
}
.card-avatar {
  display: block !important;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.card-avatar-top {
  margin-top: -3rem;
}
.card-avatar-top.avatar-xs {
  margin-top: -2.3125rem;
}
.card-avatar-top.avatar-sm {
  margin-top: -2.75rem;
}
.card-avatar-top.avatar-lg {
  margin-top: -3.5rem;
}
.card-avatar-top.avatar-xl {
  margin-top: -4.0625rem;
}
.card-avatar-top.avatar-xxl {
  margin-top: -5.5rem;
}
.card-dropdown {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}
.card-inactive {
  background-color: transparent;
  border-color: #e3ebf6;
  border-style: dashed;
  box-shadow: none;
}
.card-flush {
  background: none;
  border: none;
  box-shadow: none;
}
.card-sm .card-body,
.card-sm .card-footer-boxed {
  padding: 1rem;
}
.card-header-flush {
  border-bottom: 0;
}
.card-header-flush + .card-body {
  padding-top: 0;
}
.card-fill {
  height: calc(100vh - 45vh);
}
.card-fill .card-body {
  flex-grow: 0;
  margin-bottom: auto;
  margin-top: auto;
}
.card-fill-xs {
  height: calc(100% - 1.5rem);
}
.card-fill-xs .card-body {
  flex-grow: 0;
  margin-bottom: auto;
  margin-top: auto;
}
@media (min-width: 576px) {
  .card-fill-sm {
    height: calc(100% - 1.5rem);
  }
  .card-fill-sm .card-body {
    flex-grow: 0;
    margin-bottom: auto;
    margin-top: auto;
  }
}
@media (min-width: 768px) {
  .card-fill-md {
    height: calc(100% - 1.5rem);
  }
  .card-fill-md .card-body {
    flex-grow: 0;
    margin-bottom: auto;
    margin-top: auto;
  }
}
@media (min-width: 992px) {
  .card-fill-lg {
    height: calc(100% - 1.5rem);
  }
  .card-fill-lg .card-body {
    flex-grow: 0;
    margin-bottom: auto;
    margin-top: auto;
  }
}
@media (min-width: 1200px) {
  .card-fill-xl {
    height: calc(100% - 1.5rem);
  }
  .card-fill-xl .card-body {
    flex-grow: 0;
    margin-bottom: auto;
    margin-top: auto;
  }
}
@media (min-width: 1400px) {
  .card-fill-xxl {
    height: calc(100% - 1.5rem);
  }
  .card-fill-xxl .card-body {
    flex-grow: 0;
    margin-bottom: auto;
    margin-top: auto;
  }
}
.card-adjust {
  height: 0;
  min-height: 100%;
}
.card-adjust .card {
  height: calc(100% - 1.5rem);
}
.card-adjust .card-body {
  height: calc(100% - 60px);
  overflow-y: auto;
}
.card-adjust-xs {
  height: 0;
  min-height: 100%;
}
.card-adjust-xs .card {
  height: calc(100% - 1.5rem);
}
.card-adjust-xs .card-body {
  height: calc(100% - 60px);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .card-adjust-sm {
    height: 0;
    min-height: 100%;
  }
  .card-adjust-sm .card {
    height: calc(100% - 1.5rem);
  }
  .card-adjust-sm .card-body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .card-adjust-md {
    height: 0;
    min-height: 100%;
  }
  .card-adjust-md .card {
    height: calc(100% - 1.5rem);
  }
  .card-adjust-md .card-body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}
@media (min-width: 992px) {
  .card-adjust-lg {
    height: 0;
    min-height: 100%;
  }
  .card-adjust-lg .card {
    height: calc(100% - 1.5rem);
  }
  .card-adjust-lg .card-body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}
@media (min-width: 1200px) {
  .card-adjust-xl {
    height: 0;
    min-height: 100%;
  }
  .card-adjust-xl .card {
    height: calc(100% - 1.5rem);
  }
  .card-adjust-xl .card-body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}
@media (min-width: 1400px) {
  .card-adjust-xxl {
    height: 0;
    min-height: 100%;
  }
  .card-adjust-xxl .card {
    height: calc(100% - 1.5rem);
  }
  .card-adjust-xxl .card-body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}
.card-footer-boxed {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  padding-right: 0;
}
.card-pagination.pagination-tabs {
  border: 0;
  margin-bottom: -1rem;
  margin-top: -1rem;
}
.card-pagination.pagination-tabs .page,
.card-pagination.pagination-tabs .page-link {
  border-top-width: 0 !important;
}
.card-pagination.pagination-tabs .active .page,
.card-pagination.pagination-tabs .active .page-link {
  border-width: 0 0 1px !important;
}
.card-divider {
  border-color: #edf2f9;
}
.breadcrumb-item + .breadcrumb-item:before {
  align-self: center;
  color: #d2ddec;
  content: "\e930";
  font-family: Feather;
  font-size: 0.8rem;
}
.breadcrumb-sm {
  font-size: 0.8125rem;
}
.breadcrumb-overflow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.breadcrumb-overflow::-webkit-scrollbar {
  display: none;
}
.breadcrumb-overflow .breadcrumb-item {
  white-space: nowrap;
}
.pagination .page,
.pagination .page-link {
  font-size: 0.9375rem;
  line-height: 1.25;
}
.pagination-lg .page,
.pagination-lg .page-link {
  font-size: 0.9375rem;
  line-height: 1.5;
}
.pagination-sm .page,
.pagination-sm .page-link {
  font-size: 0.8125rem;
  line-height: 1.75;
}
.pagination-tabs {
  border-radius: 0;
  border-top: 1px solid #e3ebf6;
}
.pagination-tabs .page,
.pagination-tabs .page-link {
  background-color: transparent;
  border-radius: 0 !important;
  border-width: 1px 0 0;
  color: #95aac9;
  margin-top: -1px;
  padding: 1.25rem 0.75rem;
}
.pagination-tabs .page-link:hover,
.pagination-tabs .page:hover {
  color: #0c2b3b;
}
.pagination-tabs .active .page,
.pagination-tabs .active .page-link {
  background-color: transparent;
  border-color: #2c7be5;
  color: #0c2b3b;
}
.pagination-overflow {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.pagination-overflow::-webkit-scrollbar {
  display: none;
}
.pagination-overflow .page,
.pagination-overflow .page-link {
  white-space: nowrap;
}
.badge {
  vertical-align: middle;
}
.btn .badge {
  top: -2px;
}
.badge.rounded-pill {
  padding-left: 0.6em;
  padding-right: 0.6em;
}
.badge.bg-info,
.badge.bg-primary,
.badge.bg-secondary,
.badge.bg-success {
  color: #fff;
}
.badge.bg-warning {
  color: #283e59;
}
.badge.bg-danger {
  color: #fff;
}
.badge.bg-light {
  color: #283e59;
}
.badge.bg-dark {
  color: #fff;
}
.badge.bg-white {
  color: #283e59;
}
.badge.bg-primary-soft {
  color: #2c7be5;
}
.badge.bg-primary-soft[href]:focus,
.badge.bg-primary-soft[href]:hover {
  background-color: #bed7f7 !important;
}
.badge.bg-secondary-soft {
  color: #6e84a3;
}
.badge.bg-secondary-soft[href]:focus,
.badge.bg-secondary-soft[href]:hover {
  background-color: #d2d8e3 !important;
}
.badge.bg-success-soft {
  color: #00d97e;
}
.badge.bg-success-soft[href]:focus,
.badge.bg-success-soft[href]:hover {
  background-color: #b6f4da !important;
}
.badge.bg-info-soft {
  color: #39afd1;
}
.badge.bg-info-soft[href]:focus,
.badge.bg-info-soft[href]:hover {
  background-color: #c2e7f1 !important;
}
.badge.bg-warning-soft {
  color: #f6c343;
}
.badge.bg-warning-soft[href]:focus,
.badge.bg-warning-soft[href]:hover {
  background-color: #fcebc1 !important;
}
.badge.bg-danger-soft {
  color: #e63757;
}
.badge.bg-danger-soft[href]:focus,
.badge.bg-danger-soft[href]:hover {
  background-color: #f7c0ca !important;
}
.badge.bg-light-soft {
  color: #edf2f9;
}
.badge.bg-light-soft[href]:focus,
.badge.bg-light-soft[href]:hover {
  background-color: #e7edf9 !important;
}
.badge.bg-dark-soft {
  color: #0c2b3b;
}
.badge.bg-dark-soft[href]:focus,
.badge.bg-dark-soft[href]:hover {
  background-color: #c2c7ce !important;
}
.badge.bg-white-soft {
  color: #fff;
}
.badge.bg-white-soft[href]:focus,
.badge.bg-white-soft[href]:hover {
  background-color: #f2f2f2 !important;
}
.alert-link {
  text-decoration: underline;
}
.alert-primary {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}
.alert-primary .alert-link {
  color: #ccc;
}
.alert-primary hr {
  background-color: #1b6edc;
}
.alert-primary .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-primary .alert-link {
  color: #fff;
}
.alert-secondary {
  background-color: #6e84a3;
  border-color: #6e84a3;
  color: #fff;
}
.alert-secondary .alert-link {
  color: #ccc;
}
.alert-secondary hr {
  background-color: #607797;
}
.alert-secondary .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-secondary .alert-link {
  color: #fff;
}
.alert-success {
  background-color: #00d97e;
  border-color: #00d97e;
  color: #fff;
}
.alert-success .alert-link {
  color: #ccc;
}
.alert-success hr {
  background-color: #00c06f;
}
.alert-success .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-success .alert-link {
  color: #fff;
}
.alert-info {
  background-color: #39afd1;
  border-color: #39afd1;
  color: #fff;
}
.alert-info .alert-link {
  color: #ccc;
}
.alert-info hr {
  background-color: #2da2c3;
}
.alert-info .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-info .alert-link {
  color: #fff;
}
.alert-warning {
  background-color: #f6c343;
  border-color: #f6c343;
  color: #283e59;
}
.alert-warning .alert-link {
  color: #203247;
}
.alert-warning hr {
  background-color: #f5bb2b;
}
.alert-warning .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23283E59'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-warning .alert-link {
  color: #283e59;
}
.alert-danger {
  background-color: #e63757;
  border-color: #e63757;
  color: #fff;
}
.alert-danger .alert-link {
  color: #ccc;
}
.alert-danger hr {
  background-color: #e32044;
}
.alert-danger .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-danger .alert-link {
  color: #fff;
}
.alert-light {
  background-color: #edf2f9;
  border-color: #edf2f9;
  color: #283e59;
}
.alert-light .alert-link {
  color: #203247;
}
.alert-light hr {
  background-color: #dae4f3;
}
.alert-light .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23283E59'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-light .alert-link {
  color: #283e59;
}
.alert-dark {
  background-color: #0c2b3b;
  border-color: #0c2b3b;
  color: #fff;
}
.alert-dark .alert-link {
  color: #ccc;
}
.alert-dark hr {
  background-color: #0c1a2b;
}
.alert-dark .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-dark .alert-link {
  color: #fff;
}
.alert-white {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.alert-white .alert-link {
  color: #203247;
}
.alert-white hr {
  background-color: #f2f2f2;
}
.alert-white .btn-close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23283E59'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  padding: calc(0.75rem + 0.5em) 1.25rem;
}
.alert-white .alert-link {
  color: #283e59;
}
.progress-bar:first-child {
  border-bottom-left-radius: 200px;
  border-top-left-radius: 200px;
}
.progress-bar:last-child {
  border-bottom-right-radius: 200px;
  border-top-right-radius: 200px;
}
.progress-sm {
  height: 0.25rem;
}
.list-group-item-primary {
  background-color: #2c7be5;
  color: color-yiq(#2c7be5);
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  background-color: #286fce;
  color: color-yiq(#2c7be5);
}
.list-group-item-primary.list-group-item-action.active {
  background-color: color-yiq(#2c7be5);
  border-color: color-yiq(#2c7be5);
  color: #fff;
}
.list-group-item-secondary {
  background-color: #6e84a3;
  color: color-yiq(#6e84a3);
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  background-color: #637793;
  color: color-yiq(#6e84a3);
}
.list-group-item-secondary.list-group-item-action.active {
  background-color: color-yiq(#6e84a3);
  border-color: color-yiq(#6e84a3);
  color: #fff;
}
.list-group-item-success {
  background-color: #00d97e;
  color: color-yiq(#00d97e);
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  background-color: #00c371;
  color: color-yiq(#00d97e);
}
.list-group-item-success.list-group-item-action.active {
  background-color: color-yiq(#00d97e);
  border-color: color-yiq(#00d97e);
  color: #fff;
}
.list-group-item-info {
  background-color: #39afd1;
  color: color-yiq(#39afd1);
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  background-color: #339ebc;
  color: color-yiq(#39afd1);
}
.list-group-item-info.list-group-item-action.active {
  background-color: color-yiq(#39afd1);
  border-color: color-yiq(#39afd1);
  color: #fff;
}
.list-group-item-warning {
  background-color: #f6c343;
  color: color-yiq(#f6c343);
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  background-color: #ddb03c;
  color: color-yiq(#f6c343);
}
.list-group-item-warning.list-group-item-action.active {
  background-color: color-yiq(#f6c343);
  border-color: color-yiq(#f6c343);
  color: #fff;
}
.list-group-item-danger {
  background-color: #e63757;
  color: color-yiq(#e63757);
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  background-color: #cf324e;
  color: color-yiq(#e63757);
}
.list-group-item-danger.list-group-item-action.active {
  background-color: color-yiq(#e63757);
  border-color: color-yiq(#e63757);
  color: #fff;
}
.list-group-item-light {
  background-color: #edf2f9;
  color: color-yiq(#edf2f9);
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  background-color: #d5dae0;
  color: color-yiq(#edf2f9);
}
.list-group-item-light.list-group-item-action.active {
  background-color: color-yiq(#edf2f9);
  border-color: color-yiq(#edf2f9);
  color: #fff;
}
.list-group-item-dark {
  background-color: #0c2b3b;
  color: color-yiq(#0c2b3b);
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  background-color: #102239;
  color: color-yiq(#0c2b3b);
}
.list-group-item-dark.list-group-item-action.active {
  background-color: color-yiq(#0c2b3b);
  border-color: color-yiq(#0c2b3b);
  color: #fff;
}
.list-group-item-white {
  background-color: #fff;
  color: color-yiq(#fff);
}
.list-group-item-white.list-group-item-action:focus,
.list-group-item-white.list-group-item-action:hover {
  background-color: #e6e6e6;
  color: color-yiq(#fff);
}
.list-group-item-white.list-group-item-action.active {
  background-color: color-yiq(#fff);
  border-color: color-yiq(#fff);
  color: #fff;
}
.list-group-lg .list-group-item {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}
.list-group-flush:not(:last-child) > .list-group-item:last-child {
  border-bottom-width: 1px;
}
.list-group-focus .list-group-item:focus .text-focus {
  color: #2c7be5 !important;
}
.list-group-activity .list-group-item {
  border: 0;
}
.list-group-activity .list-group-item:not(:last-child):before {
  border-left: 1px solid #e3ebf6;
  content: "";
  height: 100%;
  left: 1.25rem;
  position: absolute;
  top: 1rem;
}
.btn-close {
  float: right;
}
.modal.fade .modal-dialog {
  transform: translateY(-150px);
}
.modal.show .modal-dialog {
  transform: translate(0);
}
.modal-header .btn-close {
  margin: -1.5rem -1.5rem -1.5rem auto;
}
.modal-card {
  margin-bottom: 0;
}
.modal-card .card-body {
  max-height: 350px;
  overflow-y: auto;
}
.modal-header-tabs {
  margin-bottom: calc(-1rem - 1px);
  margin-top: -1rem;
}
.popover {
  padding: 0.8rem 0.95rem;
}
.popover:hover {
  visibility: visible !important;
}
.popover-header {
  border-bottom: 0;
  margin-bottom: 0.25rem;
}
.popover-body-indicator {
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.25rem;
  width: 0.5rem;
}
.popover-lg {
  max-width: 300px;
}
.popover-dark {
  background-color: #0c2b3b;
  border-color: #0c2b3b;
}
.popover-dark .popover-header {
  background-color: #0c2b3b;
  color: #fff;
  font-weight: 400;
}
.popover-dark.bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow:after,
.popover-dark.bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow:before,
.popover-dark.bs-popover-top .popover-arrow:after,
.popover-dark.bs-popover-top .popover-arrow:before {
  border-top-color: #0c2b3b;
}
.popover-dark.bs-popover-right .popover-arrow:after,
.popover-dark.bs-popover-right .popover-arrow:before {
  border-right-color: #0c2b3b;
}
.popover-dark.bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:after,
.popover-dark.bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:before,
.popover-dark.bs-popover-bottom .popover-arrow:after,
.popover-dark.bs-popover-bottom .popover-arrow:before {
  border-bottom-color: #0c2b3b;
}
.popover-dark.bs-popover-left .popover-arrow:after,
.popover-dark.bs-popover-left .popover-arrow:before {
  border-left-color: #0c2b3b;
}
.offcanvas-header {
  border-bottom: 1px solid #e3ebf6;
  padding: 1rem 1.5rem;
}
.avatar {
  display: inline-block;
  font-size: 1rem;
  height: 3rem;
  position: relative;
  width: 3rem;
}
.avatar:after {
  background-image: url(./assets/img/masks/avatar-status.svg),
    url(./assets/img/masks/avatar-group.svg),
    url(./assets/img/masks/avatar-group-hover.svg),
    url(./assets/img/masks/avatar-group-hover-last.svg);
  content: "";
  height: 0;
  position: absolute;
  width: 0;
}
.avatar-img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-title {
  align-items: center;
  background-color: #b1c2d9;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  line-height: 0;
  width: 100%;
}
.avatar-offline:before,
.avatar-online:before {
  border-radius: 50%;
  bottom: 5%;
  content: "";
  height: 20%;
  position: absolute;
  right: 5%;
  width: 20%;
}
.avatar-offline .avatar-img,
.avatar-online .avatar-img {
  -webkit-mask-image: url(./assets/img/masks/avatar-status.svg);
  mask-image: url(./assets/img/masks/avatar-status.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.avatar-online:before {
  background-color: #00d97e;
}
.avatar-offline:before {
  background-color: #b1c2d9;
}
.avatar-xs {
  font-size: 0.54167rem;
  height: 1.625rem;
  width: 1.625rem;
}
.avatar-sm {
  font-size: 0.83333rem;
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-lg {
  font-size: 1.33333rem;
  height: 4rem;
  width: 4rem;
}
.avatar-xl,
.avatar-xxl {
  font-size: 1.70833rem;
  height: 5.125rem;
  width: 5.125rem;
}
@media (min-width: 768px) {
  .avatar-xxl {
    font-size: 2.66667rem;
    height: 8rem;
    width: 8rem;
  }
}
.avatar.avatar-4by3 {
  width: 4rem;
}
.avatar-xs.avatar-4by3 {
  width: 2.16667rem;
}
.avatar-sm.avatar-4by3 {
  width: 3.33333rem;
}
.avatar-lg.avatar-4by3 {
  width: 5.33333rem;
}
.avatar-xl.avatar-4by3 {
  width: 6.83333rem;
}
.avatar-xxl.avatar-4by3 {
  width: 10.66667rem;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}
.avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -2rem;
}
.avatar-group .avatar:not(:last-child) {
  -webkit-mask-image: url(./assets/img/masks/avatar-group.svg);
  mask-image: url(./assets/img/masks/avatar-group.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.avatar-group .avatar:hover {
  -webkit-mask-image: none;
  mask-image: none;
  z-index: 1;
}
.avatar-group .avatar:hover + .avatar {
  -webkit-mask-image: url(./assets/img/masks/avatar-group-hover.svg);
  mask-image: url(./assets/img/masks/avatar-group-hover.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.avatar-group .avatar:hover + .avatar:last-child {
  -webkit-mask-image: url(./assets/img/masks/avatar-group-hover-last.svg);
  mask-image: url(./assets/img/masks/avatar-group-hover-last.svg);
}
.chart {
  height: 300px;
  position: relative;
}
.chart.chart-appended {
  height: calc(300px - 3.71875rem);
}
.chart-sm {
  height: 225px;
}
.chart-sm.chart-appended {
  height: calc(225px - 3.71875rem);
}
.chart-sparkline {
  height: 35px;
  width: 75px;
}
.chart-legend {
  color: #95aac9;
  display: flex;
  font-size: 0.8125rem;
  justify-content: center;
  margin-top: 2.5rem;
  text-align: center;
}
.chart-legend-item {
  align-items: center;
  display: inline-flex;
}
.chart-legend-item + .chart-legend-item {
  margin-left: 1rem;
}
.chart-legend-indicator {
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.375rem;
  width: 0.5rem;
}
#chart-tooltip {
  z-index: 0;
}
#chart-tooltip .popover-arrow {
  left: 50%;
  top: 100%;
  transform: translateX(-50%) translateX(-0.5rem);
}
.comment {
  margin-bottom: 1rem;
}
.comment-body {
  background-color: #f9fbfd;
  border-radius: 0.5rem;
  display: inline-block;
  padding: 1rem 1.25rem;
}
.comment-time {
  color: #95aac9;
  display: block;
  font-size: 0.625rem;
  margin-bottom: 0.5625rem;
}
.comment-text {
  font-size: 0.8125rem;
}
.comment-text:last-child {
  margin-bottom: 0;
}
.checklist {
  outline: none;
}
.checklist .form-check {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.checklist .form-check + .form-check {
  margin-top: 0.75rem;
}
.checklist .form-check:first-child[style*="display: none"] + .form-check {
  margin-top: 0;
}
.checklist .form-check.draggable-mirror {
  z-index: 1030;
}
.checklist .form-check.draggable-source--is-dragging {
  opacity: 0.2;
}
.checklist .form-check .form-check-input:checked + .form-check-label {
  color: #6e84a3;
  text-decoration: line-through;
}

.header-img-top {
  height: auto;
  width: 100%;
}
.header-body {
  /* border-bottom: 1px solid #e3ebf6; */
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.header.bg-dark .header-body,
.header.bg-hero .header-body {
  border-bottom-color: rgba(227, 235, 246, 0.1);
}
.header-footer {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.header-pretitle {
  color: #6e84a3;
  font-weight: 300 !important;
  font-size: 0.7rem;
  letter-spacing: 0.03em;
  text-transform: capitalize;
}
.header-title {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
}
.header-subtitle {
  color: #95aac9;
  margin-bottom: 0;
  margin-top: 0.375rem;
}
.header-tabs {
  border-bottom-width: 0;
  margin-bottom: -1.5rem;
}
.header-tabs .nav-link {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.icon {
  display: inline-block;
}
.icon > .fe {
  display: block;
  font-size: 1.0625rem;
  min-height: 1.5em;
  min-width: 1.5em;
  text-align: center;
}
.icon.active {
  position: relative;
}
.icon.active > .fe {
  -webkit-mask-image: url(./assets/img/masks/icon-status.svg);
  mask-image: url(./assets/img/masks/icon-status.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.icon.active:after {
  background-color: #2c7be5;
  border-radius: 50%;
  content: "";
  height: 20%;
  position: absolute;
  right: 20%;
  top: 10%;
  width: 20%;
}
.container-fluid.kanban-container,
.kanban-container.container-lg,
.kanban-container.container-md,
.kanban-container.container-sm,
.kanban-container.container-xl {
  min-height: calc(100vh - 129px);
}
.container.kanban-container {
  min-height: calc(100vh - 198px);
}
.kanban-container {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}
.kanban-container > .row {
  flex-wrap: nowrap;
}
.kanban-container > .row > [class*="col"] {
  max-width: 375px;
}
.kanban-category {
  min-height: 1rem;
}
.kanban-item {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.kanban-item.draggable-source--is-dragging {
  opacity: 0.2;
}
.kanban-item.draggable-mirror {
  z-index: 1030;
}
.card-body .kanban-item.draggable-mirror > .card {
  transform: rotate(-3deg);
}
.kanban-item > .card[data-bs-toggle="modal"] {
  cursor: pointer;
}
.kanban-add-form .form-control[data-flatpickr] {
  width: 12ch;
}
@media (min-width: 768px) {
  .navbar-vertical-sm:not([style*="display: none"]) ~ .main-content .container,
  .navbar-vertical-sm:not([style*="display: none"])
    ~ .main-content
    .container-fluid,
  .navbar-vertical-sm:not([style*="display: none"])
    ~ .main-content
    .container-lg,
  .navbar-vertical-sm:not([style*="display: none"])
    ~ .main-content
    .container-md,
  .navbar-vertical-sm:not([style*="display: none"])
    ~ .main-content
    .container-sm,
  .navbar-vertical-sm:not([style*="display: none"])
    ~ .main-content
    .container-xl,
  .navbar-vertical:not([style*="display: none"]) ~ .main-content .container,
  .navbar-vertical:not([style*="display: none"])
    ~ .main-content
    .container-fluid,
  .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-lg,
  .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-md,
  .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-sm,
  .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-xl {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
}
.navbar-vertical.navbar-expand-xs.fixed-start:not([style*="display: none"])
  ~ .main-content {
  margin-left: 250px;
}
.navbar-vertical.navbar-expand-xs.fixed-end:not([style*="display: none"])
  ~ .main-content {
  margin-right: 250px;
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 250px;
  }
  .navbar-vertical.navbar-expand-sm.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 250px;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 250px;
  }
  .navbar-vertical.navbar-expand-md.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 250px;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 250px;
  }
  .navbar-vertical.navbar-expand-lg.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 250px;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 250px;
  }
  .navbar-vertical.navbar-expand-xl.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 250px;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 250px;
  }
  .navbar-vertical.navbar-expand-xxl.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 250px;
  }
}
.navbar-vertical-sm.navbar-expand-xs.fixed-start:not([style*="display: none"])
  ~ .main-content {
  margin-left: 66px;
}
.navbar-vertical-sm.navbar-expand-xs.fixed-end:not([style*="display: none"])
  ~ .main-content {
  margin-right: 66px;
}
@media (min-width: 576px) {
  .navbar-vertical-sm.navbar-expand-sm.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 66px;
  }
  .navbar-vertical-sm.navbar-expand-sm.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 66px;
  }
}
@media (min-width: 768px) {
  .navbar-vertical-sm.navbar-expand-md.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 66px;
  }
  .navbar-vertical-sm.navbar-expand-md.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 66px;
  }
}
@media (min-width: 992px) {
  .navbar-vertical-sm.navbar-expand-lg.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 66px;
  }
  .navbar-vertical-sm.navbar-expand-lg.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 66px;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical-sm.navbar-expand-xl.fixed-start:not([style*="display: none"])
    ~ .main-content {
    margin-left: 66px;
  }
  .navbar-vertical-sm.navbar-expand-xl.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 66px;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical-sm.navbar-expand-xxl.fixed-start:not(
      [style*="display: none"]
    )
    ~ .main-content {
    margin-left: 66px;
  }
  .navbar-vertical-sm.navbar-expand-xxl.fixed-end:not([style*="display: none"])
    ~ .main-content {
    margin-right: 66px;
  }
}
.choices {
  cursor: pointer;
  position: relative;
}
.choices:focus {
  outline: none;
}
.choices:after {
  display: none;
}
.choices > .dropdown-menu {
  width: 100%;
}
.choices > .dropdown-menu .form-control {
  margin: 0.375rem 0.75rem;
  width: calc(100% - 1.5rem);
}
.choices > .dropdown-menu .dropdown-item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.choices > .dropdown-menu .dropdown-item.is-highlighted {
  color: #0c2b3b;
}
.choices > .form-control-sm + .dropdown-menu,
.choices > .form-select-sm + .dropdown-menu {
  min-width: 100%;
  width: auto;
}
.choices > .form-control-sm + .dropdown-menu .dropdown-item,
.choices > .form-select-sm + .dropdown-menu .dropdown-item {
  font-size: 0.8125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.choices > .form-control-sm + .dropdown-menu .form-control,
.choices > .form-select-sm + .dropdown-menu .form-control {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.choices__placeholder {
  color: #b1c2d9;
  opacity: 1;
}
.choices.is-open > .form-control,
.choices.is-open > .form-select {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.choices.is-open > .form-control + .dropdown-menu,
.choices.is-open > .form-select + .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}
.choices__list--multiple + .form-control {
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  display: inline-block;
  font-size: 0.9375rem;
  min-height: 0;
  padding: 0;
  width: auto;
}
.form-control-lg + .choices__list--multiple + .form-control,
.form-select-lg + .choices__list--multiple + .form-control {
  font-size: 0.9375rem;
}
.form-control-sm + .choices__list--multiple + .form-control,
.form-select-sm + .choices__list--multiple + .form-control {
  font-size: 0.8125rem;
}
.choices__list--multiple {
  display: inline;
}
.choices__list--multiple > .choices__item {
  background-color: #edf2f9;
  border-radius: 0.1875rem;
  display: inline-block;
  font-size: 0.8125rem;
  margin-right: 0.2rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.choices__list--multiple > .choices__item > .choices__button {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2312263F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M18 6 6 18M6 6l12 12'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-width: 0;
  margin-left: 0.5rem;
  opacity: 0.5;
  outline: none;
  padding: 0;
  text-indent: -9999px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 0.5rem;
}
.choices__list--multiple > .choices__item:hover > .choices__button {
  opacity: 1;
}
.dropzone {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  border: 1px dashed #bcbcbc;
  box-sizing: border-box;
  margin-bottom: 24px !important;
}
.dz-message {
  background-color: #fff;
  border: 1px dashed #d2ddec;
  border-radius: 0.375rem;
  color: #95aac9;
  cursor: pointer;
  order: -1;
  padding: 5rem 1rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  z-index: 999;
}
.dz-message:hover {
  border-color: #95aac9;
  color: #0c2b3b;
}
.dz-button {
  background: none;
  border: 0;
  color: inherit;
  font-size: inherit;
}
.dz-drag-hover .dz-message {
  border-color: #2c7be5;
  color: #2c7be5;
}
.dropzone-multiple .dz-message {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(18, 38, 63, 0.9);
  color: #fff;
  opacity: 0;
}
.dropzone-single.dz-max-files-reached .dz-message:hover {
  opacity: 1;
}
.dz-preview-cover,
.dz-preview-single {
  border-radius: 0.375rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.dz-preview-img {
  border-radius: 0.375rem;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.dz-preview-multiple .list-group-item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.fe,
[data-dz-size] strong {
  font-weight: 400;
}
.fe {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Feather !important;
  font-style: normal;
  font-variant: normal;
  line-height: inherit;
  text-transform: none;
}
.fe-activity:before {
  content: "\e900";
}
.fe-airplay:before {
  content: "\e901";
}
.fe-alert-circle:before {
  content: "\e902";
}
.fe-alert-octagon:before {
  content: "\e903";
}
.fe-alert-triangle:before {
  content: "\e904";
}
.fe-align-center:before {
  content: "\e905";
}
.fe-align-justify:before {
  content: "\e906";
}
.fe-align-left:before {
  content: "\e907";
}
.fe-align-right:before {
  content: "\e908";
}
.fe-anchor:before {
  content: "\e909";
}
.fe-aperture:before {
  content: "\e90a";
}
.fe-archive:before {
  content: "\e90b";
}
.fe-arrow-down:before {
  content: "\e90c";
}
.fe-arrow-down-circle:before {
  content: "\e90d";
}
.fe-arrow-down-left:before {
  content: "\e90e";
}
.fe-arrow-down-right:before {
  content: "\e90f";
}
.fe-arrow-left:before {
  content: "\e910";
}
.fe-arrow-left-circle:before {
  content: "\e911";
}
.fe-arrow-right:before {
  content: "\e912";
}
.fe-arrow-right-circle:before {
  content: "\e913";
}
.fe-arrow-up:before {
  content: "\e914";
}
.fe-arrow-up-circle:before {
  content: "\e915";
}
.fe-arrow-up-left:before {
  content: "\e916";
}
.fe-arrow-up-right:before {
  content: "\e917";
}
.fe-at-sign:before {
  content: "\e918";
}
.fe-award:before {
  content: "\e919";
}
.fe-bar-chart:before {
  content: "\e91a";
}
.fe-bar-chart-2:before {
  content: "\e91b";
}
.fe-battery:before {
  content: "\e91c";
}
.fe-battery-charging:before {
  content: "\e91d";
}
.fe-bell:before {
  content: "\e91e";
}
.fe-bell-off:before {
  content: "\e91f";
}
.fe-bluetooth:before {
  content: "\e920";
}
.fe-bold:before {
  content: "\e921";
}
.fe-book:before {
  content: "\e922";
}
.fe-book-open:before {
  content: "\e923";
}
.fe-bookmark:before {
  content: "\e924";
}
.fe-box:before {
  content: "\e925";
}
.fe-briefcase:before {
  content: "\e926";
}
.fe-calendar:before {
  content: "\e927";
}
.fe-camera:before {
  content: "\e928";
}
.fe-camera-off:before {
  content: "\e929";
}
.fe-cast:before {
  content: "\e92a";
}
.fe-check:before {
  content: "\e92b";
}
.fe-check-circle:before {
  content: "\e92c";
}
.fe-check-square:before {
  content: "\e92d";
}
.fe-chevron-down:before {
  content: "\e92e";
}
.fe-chevron-left:before {
  content: "\e92f";
}
.fe-chevron-right:before {
  content: "\e930";
}
.fe-chevron-up:before {
  content: "\e931";
}
.fe-chevrons-down:before {
  content: "\e932";
}
.fe-chevrons-left:before {
  content: "\e933";
}
.fe-chevrons-right:before {
  content: "\e934";
}
.fe-chevrons-up:before {
  content: "\e935";
}
.fe-chrome:before {
  content: "\e936";
}
.fe-circle:before {
  content: "\e937";
}
.fe-clipboard:before {
  content: "\e938";
}
.fe-clock:before {
  content: "\e939";
}
.fe-cloud:before {
  content: "\e93a";
}
.fe-cloud-drizzle:before {
  content: "\e93b";
}
.fe-cloud-lightning:before {
  content: "\e93c";
}
.fe-cloud-off:before {
  content: "\e93d";
}
.fe-cloud-rain:before {
  content: "\e93e";
}
.fe-cloud-snow:before {
  content: "\e93f";
}
.fe-code:before {
  content: "\e940";
}
.fe-codepen:before {
  content: "\e941";
}
.fe-command:before {
  content: "\e942";
}
.fe-compass:before {
  content: "\e943";
}
.fe-copy:before {
  content: "\e944";
}
.fe-corner-down-left:before {
  content: "\e945";
}
.fe-corner-down-right:before {
  content: "\e946";
}
.fe-corner-left-down:before {
  content: "\e947";
}
.fe-corner-left-up:before {
  content: "\e948";
}
.fe-corner-right-down:before {
  content: "\e949";
}
.fe-corner-right-up:before {
  content: "\e94a";
}
.fe-corner-up-left:before {
  content: "\e94b";
}
.fe-corner-up-right:before {
  content: "\e94c";
}
.fe-cpu:before {
  content: "\e94d";
}
.fe-credit-card:before {
  content: "\e94e";
}
.fe-crop:before {
  content: "\e94f";
}
.fe-crosshair:before {
  content: "\e950";
}
.fe-database:before {
  content: "\e951";
}
.fe-delete:before {
  content: "\e952";
}
.fe-disc:before {
  content: "\e953";
}
.fe-dollar-sign:before {
  content: "\e954";
}
.fe-download:before {
  content: "\e955";
}
.fe-download-cloud:before {
  content: "\e956";
}
.fe-droplet:before {
  content: "\e957";
}
.fe-edit:before {
  content: "\e958";
}
.fe-edit-2:before {
  content: "\e959";
}
.fe-edit-3:before {
  content: "\e95a";
}
.fe-external-link:before {
  content: "\e95b";
}
.fe-eye:before {
  content: "\e95c";
}
.fe-eye-off:before {
  content: "\e95d";
}
.fe-facebook:before {
  content: "\e95e";
}
.fe-fast-forward:before {
  content: "\e95f";
}
.fe-feather:before {
  content: "\e960";
}
.fe-file:before {
  content: "\e961";
}
.fe-file-minus:before {
  content: "\e962";
}
.fe-file-plus:before {
  content: "\e963";
}
.fe-file-text:before {
  content: "\e964";
}
.fe-film:before {
  content: "\e965";
}
.fe-filter:before {
  content: "\e966";
}
.fe-flag:before {
  content: "\e967";
}
.fe-folder:before {
  content: "\e968";
}
.fe-folder-minus:before {
  content: "\e969";
}
.fe-folder-plus:before {
  content: "\e96a";
}
.fe-gift:before {
  content: "\e96b";
}
.fe-git-branch:before {
  content: "\e96c";
}
.fe-git-commit:before {
  content: "\e96d";
}
.fe-git-merge:before {
  content: "\e96e";
}
.fe-git-pull-request:before {
  content: "\e96f";
}
.fe-github:before {
  content: "\e970";
}
.fe-gitlab:before {
  content: "\e971";
}
.fe-globe:before {
  content: "\e972";
}
.fe-grid:before {
  content: "\e973";
}
.fe-hard-drive:before {
  content: "\e974";
}
.fe-hash:before {
  content: "\e975";
}
.fe-headphones:before {
  content: "\e976";
}
.fe-heart:before {
  content: "\e977";
}
.fe-help-circle:before {
  content: "\e978";
}
.fe-home:before {
  content: "\e979";
}
.fe-image:before {
  content: "\e97a";
}
.fe-inbox:before {
  content: "\e97b";
}
.fe-info:before {
  content: "\e97c";
}
.fe-instagram:before {
  content: "\e97d";
}
.fe-italic:before {
  content: "\e97e";
}
.fe-layers:before {
  content: "\e97f";
}
.fe-layout:before {
  content: "\e980";
}
.fe-life-buoy:before {
  content: "\e981";
}
.fe-link:before {
  content: "\e982";
}
.fe-link-2:before {
  content: "\e983";
}
.fe-linkedin:before {
  content: "\e984";
}
.fe-list:before {
  content: "\e985";
}
.fe-loader:before {
  content: "\e986";
}
.fe-lock:before {
  content: "\e987";
}
.fe-log-in:before {
  content: "\e988";
}
.fe-log-out:before {
  content: "\e989";
}
.fe-mail:before {
  content: "\e98a";
}
.fe-map:before {
  content: "\e98b";
}
.fe-map-pin:before {
  content: "\e98c";
}
.fe-maximize:before {
  content: "\e98d";
}
.fe-maximize-2:before {
  content: "\e98e";
}
.fe-menu:before {
  content: "\e98f";
}
.fe-message-circle:before {
  content: "\e990";
}
.fe-message-square:before {
  content: "\e991";
}
.fe-mic:before {
  content: "\e992";
}
.fe-mic-off:before {
  content: "\e993";
}
.fe-minimize:before {
  content: "\e994";
}
.fe-minimize-2:before {
  content: "\e995";
}
.fe-minus:before {
  content: "\e996";
}
.fe-minus-circle:before {
  content: "\e997";
}
.fe-minus-square:before {
  content: "\e998";
}
.fe-monitor:before {
  content: "\e999";
}
.fe-moon:before {
  content: "\e99a";
}
.fe-more-horizontal:before {
  content: "\e99b";
}
.fe-more-vertical:before {
  content: "\e99c";
}
.fe-move:before {
  content: "\e99d";
}
.fe-music:before {
  content: "\e99e";
}
.fe-navigation:before {
  content: "\e99f";
}
.fe-navigation-2:before {
  content: "\e9a0";
}
.fe-octagon:before {
  content: "\e9a1";
}
.fe-package:before {
  content: "\e9a2";
}
.fe-paperclip:before {
  content: "\e9a3";
}
.fe-pause:before {
  content: "\e9a4";
}
.fe-pause-circle:before {
  content: "\e9a5";
}
.fe-percent:before {
  content: "\e9a6";
}
.fe-phone:before {
  content: "\e9a7";
}
.fe-phone-call:before {
  content: "\e9a8";
}
.fe-phone-forwarded:before {
  content: "\e9a9";
}
.fe-phone-incoming:before {
  content: "\e9aa";
}
.fe-phone-missed:before {
  content: "\e9ab";
}
.fe-phone-off:before {
  content: "\e9ac";
}
.fe-phone-outgoing:before {
  content: "\e9ad";
}
.fe-pie-chart:before {
  content: "\e9ae";
}
.fe-play:before {
  content: "\e9af";
}
.fe-play-circle:before {
  content: "\e9b0";
}
.fe-plus:before {
  content: "\e9b1";
}
.fe-plus-circle:before {
  content: "\e9b2";
}
.fe-plus-square:before {
  content: "\e9b3";
}
.fe-pocket:before {
  content: "\e9b4";
}
.fe-power:before {
  content: "\e9b5";
}
.fe-printer:before {
  content: "\e9b6";
}
.fe-radio:before {
  content: "\e9b7";
}
.fe-refresh-ccw:before {
  content: "\e9b8";
}
.fe-refresh-cw:before {
  content: "\e9b9";
}
.fe-repeat:before {
  content: "\e9ba";
}
.fe-rewind:before {
  content: "\e9bb";
}
.fe-rotate-ccw:before {
  content: "\e9bc";
}
.fe-rotate-cw:before {
  content: "\e9bd";
}
.fe-rss:before {
  content: "\e9be";
}
.fe-save:before {
  content: "\e9bf";
}
.fe-scissors:before {
  content: "\e9c0";
}
.fe-search:before {
  content: "\e9c1";
}
.fe-send:before {
  content: "\e9c2";
}
.fe-server:before {
  content: "\e9c3";
}
.fe-settings:before {
  content: "\e9c4";
}
.fe-share:before {
  content: "\e9c5";
}
.fe-share-2:before {
  content: "\e9c6";
}
.fe-shield:before {
  content: "\e9c7";
}
.fe-shield-off:before {
  content: "\e9c8";
}
.fe-shopping-bag:before {
  content: "\e9c9";
}
.fe-shopping-cart:before {
  content: "\e9ca";
}
.fe-shuffle:before {
  content: "\e9cb";
}
.fe-sidebar:before {
  content: "\e9cc";
}
.fe-skip-back:before {
  content: "\e9cd";
}
.fe-skip-forward:before {
  content: "\e9ce";
}
.fe-slack:before {
  content: "\e9cf";
}
.fe-slash:before {
  content: "\e9d0";
}
.fe-sliders:before {
  content: "\e9d1";
}
.fe-smartphone:before {
  content: "\e9d2";
}
.fe-speaker:before {
  content: "\e9d3";
}
.fe-square:before {
  content: "\e9d4";
}
.fe-star:before {
  content: "\e9d5";
}
.fe-stop-circle:before {
  content: "\e9d6";
}
.fe-sun:before {
  content: "\e9d7";
}
.fe-sunrise:before {
  content: "\e9d8";
}
.fe-sunset:before {
  content: "\e9d9";
}
.fe-tablet:before {
  content: "\e9da";
}
.fe-tag:before {
  content: "\e9db";
}
.fe-target:before {
  content: "\e9dc";
}
.fe-terminal:before {
  content: "\e9dd";
}
.fe-thermometer:before {
  content: "\e9de";
}
.fe-thumbs-down:before {
  content: "\e9df";
}
.fe-thumbs-up:before {
  content: "\e9e0";
}
.fe-toggle-left:before {
  content: "\e9e1";
}
.fe-toggle-right:before {
  content: "\e9e2";
}
.fe-trash:before {
  content: "\e9e3";
}
.fe-trash-2:before {
  content: "\e9e4";
}
.fe-trending-down:before {
  content: "\e9e5";
}
.fe-trending-up:before {
  content: "\e9e6";
}
.fe-triangle:before {
  content: "\e9e7";
}
.fe-truck:before {
  content: "\e9e8";
}
.fe-tv:before {
  content: "\e9e9";
}
.fe-twitter:before {
  content: "\e9ea";
}
.fe-type:before {
  content: "\e9eb";
}
.fe-umbrella:before {
  content: "\e9ec";
}
.fe-underline:before {
  content: "\e9ed";
}
.fe-unlock:before {
  content: "\e9ee";
}
.fe-upload:before {
  content: "\e9ef";
}
.fe-upload-cloud:before {
  content: "\e9f0";
}
.fe-user:before {
  content: "\e9f1";
}
.fe-user-check:before {
  content: "\e9f2";
}
.fe-user-minus:before {
  content: "\e9f3";
}
.fe-user-plus:before {
  content: "\e9f4";
}
.fe-user-x:before {
  content: "\e9f5";
}
.fe-users:before {
  content: "\e9f6";
}
.fe-video:before {
  content: "\e9f7";
}
.fe-video-off:before {
  content: "\e9f8";
}
.fe-voicemail:before {
  content: "\e9f9";
}
.fe-volume:before {
  content: "\e9fa";
}
.fe-volume-1:before {
  content: "\e9fb";
}
.fe-volume-2:before {
  content: "\e9fc";
}
.fe-volume-x:before {
  content: "\e9fd";
}
.fe-watch:before {
  content: "\e9fe";
}
.fe-wifi:before {
  content: "\e9ff";
}
.fe-wifi-off:before {
  content: "\ea00";
}
.fe-wind:before {
  content: "\ea01";
}
.fe-x:before {
  content: "\ea02";
}
.fe-x-circle:before {
  content: "\ea03";
}
.fe-x-square:before {
  content: "\ea04";
}
.fe-youtube:before {
  content: "\ea05";
}
.fe-zap:before {
  content: "\ea06";
}
.fe-zap-off:before {
  content: "\ea07";
}
.fe-zoom-in:before {
  content: "\ea08";
}
.fe-zoom-out:before {
  content: "\ea09";
}
.flatpickr-calendar {
  background-color: #fff;
  border: 1px solid #d2ddec;
  box-shadow: none;
  color: #0c2b3b;
}
.flatpickr-calendar * {
  fill: currentColor !important;
  color: inherit !important;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #d2ddec;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar .flatpickr-months {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}
.flatpickr-calendar .flatpickr-next-month,
.flatpickr-calendar .flatpickr-prev-month {
  top: 0.625rem;
}
.flatpickr-calendar .flatpickr-current-month {
  font-size: 115%;
}
.flatpickr-calendar .flatpickr-day {
  border-radius: 0.375rem;
}
.flatpickr-calendar .flatpickr-day:hover {
  background-color: #edf2f9;
  border-color: #d2ddec;
}
.flatpickr-calendar .flatpickr-day.prevMonthDay {
  color: #95aac9 !important;
}
.flatpickr-calendar .flatpickr-day.today {
  border-color: #e3ebf6;
}
.flatpickr-calendar .flatpickr-day.selected {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff !important;
}
.flatpickr-calendar .flatpickr-day.inRange {
  background-color: #edf2f9;
  border: none;
  border-radius: 0;
  box-shadow: -5px 0 0 #edf2f9, 5px 0 0 #edf2f9;
}
.hljs {
  padding: 0;
}
.ql-container {
  font-family: var(--bs-font-sans-serif);
}
.ql-toolbar {
  background-color: #fff;
  border: 1px solid #d2ddec;
  border-radius: 0.375rem 0.375rem 0 0;
  color: #0c2b3b;
  padding: 0.5rem 0.75rem;
  position: relative;
}
.ql-toolbar + .ql-container {
  margin-top: -1px;
}
.ql-toolbar + .ql-container .ql-editor {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ql-editor {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
  color: #0c2b3b;
  display: block;
  font-size: 0.9375rem;
  line-height: 1.5;
  min-height: 5.625rem;
  padding: 0.5rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .ql-editor {
    transition: none;
  }
}
.ql-editor::-ms-expand {
  background-color: transparent;
  border: 0;
}
.ql-editor:focus {
  border-color: #2c7be5;
}
.ql-hidden {
  position: absolute;
  transform: scale(0);
}
.ql-editor.ql-blank:before {
  color: #b1c2d9;
  font-style: normal;
  left: 0.75rem;
  top: 0.5rem;
}
.ql-editor:focus:before {
  display: none;
}
.ql-formats {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.ql-formats:first-child {
  padding-left: 0;
}
.ql-formats:last-child {
  padding-right: 0;
}
.ql-toolbar button {
  background: none;
  border: none;
  color: #0c2b3b;
  cursor: pointer;
  padding: 0 0.25rem;
  transition: all 0.2s ease-in-out;
}
.ql-toolbar button:hover {
  color: #2c7be5;
}
.ql-toolbar button:first-child {
  margin-left: -0.25rem;
}
.ql-toolbar .ql-active {
  color: #2c7be5;
}
.ql-toolbar button svg {
  height: 1.0625rem;
  width: 1.0625rem;
}
.ql-toolbar .ql-stroke {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
.ql-toolbar .ql-thin {
  stroke-width: 1;
}
.ql-toolbar .ql-fill {
  fill: currentColor;
}
.ql-toolbar input.ql-image {
  position: absolute;
  transform: scale(0);
}
.ql-tooltip {
  background-color: #fff;
  border: 1px solid rgba(18, 38, 63, 0.2);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(18, 38, 63, 0.03);
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.6rem;
  padding: 0.5rem 0.75rem;
  position: absolute;
  width: 18.5rem;
  z-index: 1080;
}
.ql-tooltip:after,
.ql-tooltip:before {
  bottom: 100%;
  content: "";
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.ql-tooltip:before {
  border-bottom: 0.6rem solid rgba(18, 38, 63, 0.2);
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
}
.ql-tooltip:after {
  border-bottom: 0.5rem solid #fff;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
}
.ql-container .ql-tooltip:hover {
  display: flex !important;
}
.ql-tooltip .ql-preview {
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  padding: 0.125rem 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .ql-tooltip .ql-preview {
    transition: none;
  }
}
.ql-tooltip.ql-editing .ql-preview {
  display: none;
}
.ql-tooltip input {
  background-color: transparent;
  border: none;
  color: #0c2b3b;
  display: none;
  font-size: 0.8125rem;
  line-height: 1.75;
  padding-bottom: calc(0.125rem + 1px);
  padding-top: calc(0.125rem + 1px);
  width: 100%;
}
.ql-tooltip input:focus {
  outline: none;
}
.ql-tooltip.ql-editing input {
  display: block;
}
.ql-tooltip .ql-action,
.ql-tooltip .ql-remove {
  margin-left: 0.25rem;
}
.ql-tooltip .ql-action:before,
.ql-tooltip .ql-remove:before {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 0.125rem 0.5rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .ql-tooltip .ql-action:before,
  .ql-tooltip .ql-remove:before {
    transition: none;
  }
}
.ql-tooltip .ql-action:before:focus,
.ql-tooltip .ql-action:before:hover,
.ql-tooltip .ql-remove:before:focus,
.ql-tooltip .ql-remove:before:hover {
  text-decoration: none;
}
.ql-tooltip .ql-action:before.focus,
.ql-tooltip .ql-action:before:focus,
.ql-tooltip .ql-remove:before.focus,
.ql-tooltip .ql-remove:before:focus {
  box-shadow: none;
  outline: 0;
}
.ql-tooltip .ql-action:before,
.ql-tooltip.ql-editing .ql-action:before {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}
.ql-tooltip .ql-action:before:hover,
.ql-tooltip.ql-editing .ql-action:before:hover {
  background-color: #2569c3;
  border-color: #2362b7;
  color: #fff;
}
.btn-check:focus + .ql-tooltip .ql-action:before,
.btn-check:focus + .ql-tooltip.ql-editing .ql-action:before,
.ql-tooltip .ql-action:before:focus,
.ql-tooltip.ql-editing .ql-action:before:focus {
  background-color: #2569c3;
  border-color: #2362b7;
  box-shadow: 0 0 0 0.15rem rgba(76, 143, 233, 0.5);
  color: #fff;
}
.btn-check:active + .ql-tooltip .ql-action:before,
.btn-check:active + .ql-tooltip.ql-editing .ql-action:before,
.btn-check:checked + .ql-tooltip .ql-action:before,
.btn-check:checked + .ql-tooltip.ql-editing .ql-action:before,
.ql-tooltip .ql-action:before.active,
.ql-tooltip .ql-action:before:active,
.ql-tooltip.ql-editing .ql-action:before.active,
.ql-tooltip.ql-editing .ql-action:before:active,
.show > .ql-tooltip .ql-action:before.dropdown-toggle,
.show > .ql-tooltip.ql-editing .ql-action:before.dropdown-toggle {
  background-color: #2362b7;
  border-color: #215cac;
  color: #fff;
}
.btn-check:active + .ql-tooltip .ql-action:before:focus,
.btn-check:active + .ql-tooltip.ql-editing .ql-action:before:focus,
.btn-check:checked + .ql-tooltip .ql-action:before:focus,
.btn-check:checked + .ql-tooltip.ql-editing .ql-action:before:focus,
.ql-tooltip .ql-action:before.active:focus,
.ql-tooltip .ql-action:before:active:focus,
.ql-tooltip.ql-editing .ql-action:before.active:focus,
.ql-tooltip.ql-editing .ql-action:before:active:focus,
.show > .ql-tooltip .ql-action:before.dropdown-toggle:focus,
.show > .ql-tooltip.ql-editing .ql-action:before.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(76, 143, 233, 0.5);
}
.ql-tooltip .ql-action:before.disabled,
.ql-tooltip .ql-action:before:disabled,
.ql-tooltip.ql-editing .ql-action:before.disabled,
.ql-tooltip.ql-editing .ql-action:before:disabled {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}
.ql-tooltip .ql-action:before {
  content: "Edit";
}
.ql-tooltip.ql-editing .ql-action:before {
  content: "Save";
}
.ql-tooltip .ql-remove:before {
  background-color: #fff;
  border-color: #e3ebf6;
  color: #283e59;
  content: "Remove";
}
.ql-tooltip .ql-remove:before:hover {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.btn-check:focus + .ql-tooltip .ql-remove:before,
.ql-tooltip .ql-remove:before:focus {
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
  color: #283e59;
}
.btn-check:active + .ql-tooltip .ql-remove:before,
.btn-check:checked + .ql-tooltip .ql-remove:before,
.ql-tooltip .ql-remove:before.active,
.ql-tooltip .ql-remove:before:active,
.show > .ql-tooltip .ql-remove:before.dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.btn-check:active + .ql-tooltip .ql-remove:before:focus,
.btn-check:checked + .ql-tooltip .ql-remove:before:focus,
.ql-tooltip .ql-remove:before.active:focus,
.ql-tooltip .ql-remove:before:active:focus,
.show > .ql-tooltip .ql-remove:before.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
}
.ql-tooltip .ql-remove:before.disabled,
.ql-tooltip .ql-remove:before:disabled {
  background-color: #fff;
  border-color: #fff;
  color: #283e59;
}
.ql-tooltip.ql-editing .ql-remove:before {
  display: none;
}
.ql-editor blockquote {
  font-size: 1.17188rem;
  margin-bottom: 1.5rem;
}
.ql-editor img {
  height: auto;
  max-width: 100%;
}
.list-pagination > li + li {
  margin-left: -1px;
}
.list-alert {
  bottom: 1.5rem;
  left: 50%;
  margin-bottom: 0;
  min-width: 350px;
  position: fixed;
  transform: translateX(-50%);
  z-index: 1030;
}
.list-alert:not(.show) {
  pointer-events: none;
}
@media (min-width: 768px) {
  .navbar-vertical:not(.navbar-vertical-sm):not([style*="display: none"])
    ~ .main-content
    .list-alert {
    left: calc(50% + 125px);
  }
}
.list-alert .btn-close {
  top: 50%;
  transform: translateY(-50%);
}
/*# sourceMappingURL=theme.bundle.css.map*/

.table-stat {
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;
  color: #bcbcbc;
  font-weight: 300;
}
.hidden {
  display: none;
}
.select__control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
  color: #bcbcbc;
  display: block;
  font-size: 0.7375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.15rem 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  border-radius: 20rem !important;
  margin: 5px 0px;
}

.state {
  font-size: 0.8rem;
  color: #1f2d3d;
  font-weight: 300;
}
.state::before {
  content: " ";
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #46e7ad;
  margin-right: 5px;
}

.btn-link {
  font-size: 0.9rem;
  color: var(--bs-primary);
}
.btn-link:hover {
  color: #2bc38c;
}
.btn-link .fe {
  font-size: 0.7rem;
}
.btn-link span {
  display: inline-block;
  margin-left: 5px;
}

/* detail */
.detail {
  margin-bottom: 12px;
  text-transform: capitalize;
  padding-left: 10px;
}
.detail .detail-title {
  color: #0f1f26;
  margin-bottom: 0px;
  font-size: 0.9rem;
}
.detail .detail-data {
  font-size: 0.8rem;
  color: #bcbcbc;
}
.status {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.status::first-letter {
  text-transform: uppercase !important;
}
.status.inactive {
  color: #bcbcbc;
}

.dot {
  display: inline-block;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #46e7ad;
  margin-right: 0.5rem;
  text-transform: lowercase !important;
}
.dot.inactive {
  background-color: #bcbcbc;
}
.dot.deleted {
  background-color: #ff4000;
}
/*  detail */

.detail-section {
  padding: 18px;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
  font-weight: 300;
}
.detail-contents .col-md-4 {
  margin-bottom: 1.5rem !important;
}
.detail-title {
  margin-top: 7px;
  margin-bottom: 12px;
}
.detail-title h3 {
  font-size: 0.9rem;
  color: #0f1f26;
  font-weight: 500;
}
.dc-title {
  font-size: 0.8rem;
  color: #0f1f26;
  margin-bottom: 3px;
}
.dc-body {
  font-size: 0.8rem;
  color: #bcbcbc;
}
.upload-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #bcbcbc;
  font-size: 0.9rem;
  font-weight: 300;
}
.upload-editcontent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: #bcbcbc;
  font-size: 0.9rem;
  font-weight: 300;
}

.upload-content .fe {
  color: #2bc38c;
  font-size: 2rem;
  margin-bottom: 12px;
}
.upload-editcontent .fe {
  color: #2bc38c;
  font-size: 2rem;
  margin-bottom: 12px;
}
.upload-emailcontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  color: #bcbcbc;
  font-size: 0.7rem;
  font-weight: 300;
}
.upload-emailcontent .fe {
  color: #000000;
  font-size: 2rem;
  margin-bottom: 12px;
}
.bold-black {
  font-weight: bold;
  color: black;
}

.h-full {
  min-height: 100vh;
}
.page-empty .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  height: 100%;
}

.icon-dropdown {
  cursor: pointer;
  min-width: 100px;
  text-align: center;
}
.icon-dropdown .fe {
  color: #858585;
  font-size: 18px;
}
.edit-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.edit-btns .btn + .btn {
  margin: 12px;
}
.form-search {
  display: flex;
  align-items: center;
  position: relative;
  top: 16px;
}
.form-search .form-group {
  margin-right: 12px;
  min-width: 200px;
}

.offcanvas-end {
  top: 66px;
}
.offline {
  background-color: #f34e4e;
  color: #fff;
  text-align: center;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 0.5rem;
  padding: 2px;
}
.pop {
  display: inline-flex;
  height: 15px;
  width: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #c5c2c2;
  color: #fff;
  font-size: 0.7rem;
  margin-left: 10px;
}
.freq_pop {
  min-width: 400px;
  font-size: 0.7rem;
}
.freq_pop .table {
  font-size: 0.6rem;
}

/* .body-content{
  background-color: red; */
/* height: ; */
/* } */

.reader {
  height: auto;
  min-height: calc(100vh - 100px);
  overflow-y: hidden;
}
.reader #viewer-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: auto;
}
.reader #viewer-container #viewer {
  width: 100%;
  height: 100%;
  padding-top: 50px;
  background-color: #f1f3f7;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.reader #viewer-container #viewer .pdfViewer {
  height: 100%;
}
.reader #viewer-container #viewer .pdfViewer .toolbox-container {
  height: auto;
}
.loader-holder {
  height: calc(100vh - 550px);
}
.loader-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-icon {
}
.loader-text {
  height: 100%;
  flex: 1;
}

nav.breadcrumb .breadcrumb-item {
  cursor: pointer;
}
.breadcrumb-item {
  cursor: pointer !important;
}
.react-pdf__Document {
  padding: 18px;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  /* position: relative; */
  overflow: hidden;
}
.page-btns {
  /* position: absolute; */
  position: absolute;
  bottom: 22%;
  left: 50%;
  background: white;
  opacity: 1;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
}
.react-pdf__Page {
  /* max-width: 400px; */
  /* width: 90vw; */
  position: relative;
}
.react-pdf__Page__canvas {
  /* height: 400px !important; */
  /* width: 500px !important; */
  display: block;
  background-color: white;
  overflow: hidden;
  width: 400px;
  height: 565.813px;
  user-select: none;
}
.react-pdf__Page__svg {
  display: block;
  background-color: white;
  overflow: hidden;
  width: 400px;
  height: 565.813px;
  user-select: none;
}
.PhoneInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 0.375rem;
  color: #bcbcbc;
  /* display: block; */
  font-size: 0.8375rem;
  font-weight: 300;
  line-height: 1.5;
  padding: 0.15rem 0.95rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
.PhoneInput .PhoneInputInput {
  height: 100%;
  border: none;
  /* min-height: 39px; */
  padding: 0.65rem 0.95rem;
}
.PhoneInput .PhoneInputInput:focus {
  border: none;
  outline: none;
}

.delete-link {
  color: #e63757;
  cursor: pointer;
}

.update-link {
  cursor: pointer;
}

.delete-link:hover,
.delete-link:active {
  color: #e63757;
}

.modal-90w {
  min-width: 400px;
}
.close-btn {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  margin-bottom: 24px;
}
.modal-image-holder svg {
  height: 90px;
}
.modal-details {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;
}
.modal-details h2 {
  font-weight: bold;
}
.modal-details p {
  width: 80%;
  margin: 0 auto;
  color: #bcbcbc;
  font-weight: 400;
}

.bottom-space {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.acha-right {
  margin-right: 10px !important;
}
.file-preview {
  /* height: 40px; */
  padding: 0;
  margin: 20px 0;
}
.file-preview .file-preview-item {
  /* background-color: #2BC38C; */
  /* height: 45px; */
  color: #fff;
  font-weight: 300;
  font-size: 0.9rem;
  border-radius: 5px;
  display: flex;
  /* align-items: center; */
  padding: 0 2px;
}
/* .file-preview .file-preview-item div{
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #2BC38C;
  border-radius: 50%;
  margin-right: 12px;
} */

.audio-file-name {
  text-transform: capitalize;
  font-style: italic;
  font-weight: 300;
  font-size: 0.8rem;
}

.overlay-down {
  position: relative;
}

.overlay-up {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.overlay-up div {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-preview ul {
  padding: 0;
  margin: 0;
}
.file-preview li {
  min-height: 50px;
  border-radius: 4px;
  /* background-color: #2bc38c; */
  color: #fff;
  display: flex;
  padding-left: 12px;
  /* justify-content: center; */
  align-items: center;
  font-weight: 300;
  font-size: 0.8rem;
  font-style: italic;
}

.dc-body_file {
  display: flex;
  padding-top: 12px;
}
.dc-body_file .file-thumb {
  margin-right: 12px;
  /* min-height: 80px; */
}
.dc-body_file .file-thumb img {
  height: 50px;
}
.dc-body_file .dc-file-details-title {
  color: #1f2d3d;
  font-weight: bold;
  position: relative;
}
.dc-body_file .dc-file-details-size {
  color: #bcbcbc;
  font-weight: normal;
  position: relative;
  top: -10px;
}
.no-pad {
  padding: 0;
  margin: 0;
}
.capitalize {
  text-transform: lowercase;
}
.capitalize::first-line {
  text-transform: capitalize;
}
.image-holder {
  min-height: 250px;
  overflow: hidden;
}
.image-holder img {
  height: 100%;
}
.img-place {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 250px;
}
.img-cover {
  position: absolute;
  z-index: 100;
}
.banner-cover {
  position: absolute;
  z-index: 100;
}
.banner-cover img {
  height: 250px;
  width: 250px;
  object-fit: contain;
}
.img-cover img {
  height: 250px;
  width: 250px;
  object-fit: cover;
}
.img-change {
  min-width: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
}
.status-toast {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}
.file-viewer {
  width: 100%;
  padding: 34px;
}
.pointer {
  cursor: pointer;
}

.legend label {
  margin-left: 2px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 5px;
}

.text-right {
  text-align: right !important;
}
.webviewer {
  height: 100vh;
  /* background-color: red; */
}
.viewer {
  object-fit: contain;
  height: 540px;
}
.viewer-mini {
  object-fit: contain;
  height: 270px;
}
.progress-container {
  text-align: center;
  border: 1px solid #2bc38c;
  border-bottom: 1px solid #2bc38c;
  border-radius: 5px;
  padding-top: 0.5em;
  padding-left: 0.05em;
  padding-right: 0.05em;
  padding-bottom: 0 !important;
  width: calc(36% - 0.1em);
  color: #2bc38c;
  font-size: small;
}
.progress-container > div > label {
  color: #2bc38c;
  font-size: small;
}
.progress-container > div > span.progress-close {
  color: #c9c9c9;
  font-size: medium;
  cursor: pointer;
  padding: 0.15em;
}
.progress-container > progress {
  margin-bottom: -7px !important;
  height: 0.25em;
  width: 100%;
  /* margin:.5em 1em; */
  /* color: #2bc38c; */
  background: #ffffff00;
  /* border: 1px solid #2bc38c;
  border-bottom: 3px solid #2bc38c;
  border-radius: 10px;
  padding-top:2em; */
  -ms-progress-appearance: bar;
}
progress::-webkit-progress-bar {
  background: #ffffff00;
  /* border: 1px solid #2bc38c; */
  color: #2bc38c;
  height: 0.15em;
}

/* RSSFEED INPUT */
.RSSor{
  padding: 10px 30px;
}

/* Form submit button */
.urlinput_BTN {
  overflow: visible;
  position: relative;
  top:0;
  float: right;
  border: 0;
  padding: 0;
  margin: 20%;
  cursor: pointer;
  height: 40px;
  min-width: 100px;
  color: #fff;
  background: #2BC38C;
  border-radius: 7px;
  text-shadow: 0 -1px 0 rgba(0, 0 ,0, .3);
}  
.urlinput_BTN:hover{    
    background: #0C2B3B;
    color: #fff;
}  
.urlinput_BTN:active,
.urlinput_BTN:focus{  
  background: #0C2B3B;
  color: #fff;
  outline: 0;  
}
.urlinput_BTN:focus:before,
.urlinput_BTN:active:before{
      border-right-color: #BCBCBC;
} 
.error-message{
  color: red;
  text-align: center;
}

/* Share Feature */
.share-body{
  padding: 1.5rem;
  background-color: #0C2B3B;
  border-radius: 10px;
  background-image: url('./assets/img/shareBackground.svg');
  background-position: center;
}
.share-pretitle {
  color: #dfdfdf;
  font-weight: 300 !important;
  font-size: 0.7rem;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  margin-bottom: 0;
}
.share-title {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 300;
  color: #dfdfdf;
  line-height: 26px;
}
.bold{
  font-weight: 500;
  color: #FFFFFF;
}
.shareIcon{
  background-color: #f9f9f9;
  border-radius: 50%;
}
.shareIcon:hover{
  background-color: #e9f9f3;
  border-radius:  50%;
}
.share-input-icon {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #95aac9;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}