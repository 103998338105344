.logo-holder{
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  position: relative;
  color: #fff;
}
.bg-cover{
  background-image: url('../../assets/img/covers/cover.png');
  position: relative;
  top: 0;
}
.inner-content{
  width: 70%;
  margin: 0 auto;
}
.page-bg{
  height: 100%;
  width: 100%;
  background-color: #0F1F26;
  opacity: 0.95; 
  position: absolute; 
  top: 0;
  left: 0;
}