.search-place{
  display: block;
  margin-right: 12px;
  padding-left: 12px;
  position: relative;
  border-left: 1px solid #E7E7E7;
}

.form-control ~ .input-group-text.input-group-prepend {
  border-color: #2BC38C !important;
}

/* .form-control ~ .input-group-text.input-group-append {
  font-size: 0.8rem;
}
.input-group-text + .form-control{
  border-color: #2BC38C !important;
} 

.form-control:focus + .input-group-text, 
.form-control:focus + .input-group-text.input-group-append, 
.form-control:focus +  .input-group-text.input-group-prepend{
  border-color: #2BC38C !important;
} */

.user-details-image{
  display: flex;
  justify-content: center;
}
.udi-avatar{
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 7px;
  border: 1px solid #DFE0EB;
}
.udi-avatar img{
  height: 100%;
}
.ud div{
  font-size: 0.8rem;
  font-weight: 300;
  margin-right: 10px;
}
.ud-p:first-child{
  font-weight: 600;
  font-size: 1.3rem;
}
.ud-p{
  font-size: 0.8rem;
  margin-bottom: 6px;
  font-weight: 300;
  text-transform: capitalize;
}
.usd-p{
  font-size: 0.8rem;
  margin-bottom: 6px;
  font-weight: 300;
  text-transform: capitalize;
}
.usd{
  display: flex;
  justify-content: center;
}
.usd-unit{
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: capitalize;
}
.unit{
  font-size: 0.7rem;
}

.badge-app {
  height: 30px;
  background: linear-gradient(180deg, #2BC38C 0%, #27A97A 100%);
  color: #fff;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview{
  object-fit: cover;
  height:200px;
  margin: auto
}