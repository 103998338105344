/* ToggleSwitch.css */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch label:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-switch input:checked + label {
  background-color: #00b17a; /* Change the background color when checked */
}

.toggle-switch input:checked + label:before {
  transform: translateX(26px);
}

/* Custom styling for label text */
.toggle-switch label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  color: white; /* Change the text color */
  font-weight: bold;
}
