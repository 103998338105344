#topnav.navbar{
  background-color: #fff;
  border-bottom: none !important;
  padding-left: 130px;
  height: 76px;
  z-index: 1050;
}
#topnav ul.navbar-nav{
  display: inline-flex;
}
#topnav .nav-link{
  color: #000000;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.icon i{
  color: #E7E7E7;
}
.user-avatar{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1.5px solid #DFE0EB;
}
.nav-user-holder{
  display: flex;
  align-items: center;
}
.user-title{
  margin-right: 10px;
  text-align: right;
}
.user-title .ut-title{
  text-transform: capitalize;
  color: #A5A5A5;
  font-weight: 400;
  font-size: 0.7rem;
  margin: 0px;
}

.user-title .ut-name{
  text-transform: capitalize;
  color: #313131;
  font-size: 0.9rem;
  margin: 0px;
}
.pointer{
  cursor: pointer;
}