.card{
  box-shadow: none !important;
}
.card-body .text-muted{
  color: #BCBCBC !important;
  font-size: 0.8rem;
  font-weight: 300;
}

.card-body span.h2{
  margin-right: 10px;
}


.nav.nav-tabs .nav-item{
  font-weight: 300 !important;
}
.relative{
  position: relative;
}
.cover-image{
  max-width:300px;
  object-fit: cover;
}